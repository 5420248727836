import { Component, Inject, ViewChild, forwardRef } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CompositePartComponent, MetricDetailComponent, PropertyComponent } from "../component";
import { ColumnComponentDisplayMode } from "../list-widget-v2/list-widget-v2.components";

@Component({
    selector: 'edit-table-columns-dialog',
    template: require('./edit-table-columns-dialog.component.html'),
    styles: [require('./edit-table-columns-dialog.component.css')]
})
export class EditTableColumnsDialogComponent {

    @ViewChild('form') form: NgForm;

    private localStorageId: string;

    columns: { name: string, isVisible: boolean }[] = [];
    columnComponentLabelMap: { [columnName: string]: string } = {};

    constructor(
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<EditTableColumnsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        this.localStorageId = data.localStorageId;
        const columnComponents = data.columnComponent;
        this.initMap(columnComponents);
    }

    private initMap(columnComponents: (MetricDetailComponent | CompositePartComponent | PropertyComponent)[]): void {
        const localStorageVisibilityMap: { [columnName: string]: boolean } = this.localStorageId && localStorage.getItem(this.localStorageId) ? JSON.parse(localStorage.getItem(this.localStorageId)) : null;
        columnComponents.filter(component => component.displayMode != ColumnComponentDisplayMode.ALWAYS_HIDDEN).forEach(component => {
            if (!this.columnComponentLabelMap[component.getColumnName()]) {
                this.columnComponentLabelMap[component.getColumnName()] = component.label || component.name;
                this.columns.push({
                    name: component.getColumnName(),
                    isVisible: localStorageVisibilityMap ? localStorageVisibilityMap[component.getColumnName()] : (component.displayMode == ColumnComponentDisplayMode.VISIBLE)
                });
            }
        });
    }

    save(): void {
        let localStorageVisibilityMap: { [columnName: string]: boolean } = {};
        this.columns.forEach(c => {
            localStorageVisibilityMap[c.name] = c.isVisible;
        });
        localStorage.setItem(this.localStorageId, JSON.stringify(localStorageVisibilityMap));
        this.dialogRef.close(true);
    }

    restoreDefaults(): void {
        localStorage.removeItem(this.localStorageId);
        this.dialogRef.close(true);
    }

    isSaveEnabled(): boolean {
        return this.columns?.some(c => c.isVisible);
    }

}