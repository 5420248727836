import { forwardRef, Inject, Injectable, NgZone } from '@angular/core';
import { Customer, Location, Partner, ServiceLevel, StoreOrderItem } from '../model';
import { LocalizationPipe } from '../shared/pipe';
import { AppService } from './app.service';
import { AuthenticationService } from './authentication.service';
import { ContextService } from './context.service';
import { DateRangeService } from './date-range.service';
import { ExportContextService } from './export-context.service';
import { ExternalApiClientService } from './external-api-client.service';
import { ExternalCatalogService } from './external-catalog.service';
import { ExternalComponentUtilsService, PeriodFieldV2Settings } from './external-component-utils.service';
import { FilterConfig, FilterService } from './filter.service';
import { NavigationService } from './navigation.service';
import { QuickHistoryDialogService } from './quick-history-dialog.service';
import { StoreCartService } from './store-cart.service';
import { ThingContextService } from './thing-context.service';
import { WidgetRegistrationService } from './widget-registration.service';
import { DataUtility } from '../utility/data-utility';

@Injectable()
export class ExternalAppUtilsService {

    constructor(
        @Inject(forwardRef(() => NgZone)) private zone: NgZone,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => WidgetRegistrationService)) private widgetRegistrationService: WidgetRegistrationService,
        @Inject(forwardRef(() => AppService)) private appService: AppService,
        @Inject(forwardRef(() => DateRangeService)) private dateRangeService: DateRangeService,
        @Inject(forwardRef(() => QuickHistoryDialogService)) private quickHistoryDialogService: QuickHistoryDialogService,
        @Inject(forwardRef(() => ThingContextService)) private thingContextService: ThingContextService,
        @Inject(forwardRef(() => ContextService)) private contextService: ContextService,
        @Inject(forwardRef(() => ExportContextService)) private exportContextService: ExportContextService,
        @Inject(forwardRef(() => FilterService)) private filterService: FilterService,
        @Inject(forwardRef(() => StoreCartService)) private storeCartService: StoreCartService,
        @Inject(forwardRef(() => ExternalComponentUtilsService)) private externalComponentUtilsService: ExternalComponentUtilsService,
        @Inject(forwardRef(() => ExternalApiClientService)) private apiClientService: ExternalApiClientService,
        @Inject(forwardRef(() => ExternalCatalogService)) private catalogService: ExternalCatalogService
    ) { }

    init(): void {
        window['appUtils'] = {
            navigateTo: (url) => this.zone.run(() => this.navigationService.publicNavigate(url)),
            localize: (label) => this.localizationPipe.transform(label),
            refreshCart: () => this.refreshCarts(),
            getTenant: () => this.zone.run(() => this.authenticationService.getTenant()),
            getUser: () => this.zone.run(() => this.authenticationService.getUser()),
            isMobile: () => this.zone.run(() => this.appService.isMobile()),
            isTablet: () => this.zone.run(() => this.appService.isTablet()),
            registerWidget: (widget) => this.zone.run(() => this.widgetRegistrationService.registerWidget(widget)),
            getPeriod: (start, end) => this.zone.run(() => this.dateRangeService.getPeriod(start, end)),
            showHistoricalData: (quickHistoryData) => this.zone.run(() => this.quickHistoryDialogService.showQuickHistory(quickHistoryData)),
            getThing: () => this.zone.run(() => this.thingContextService.getCurrentThing()),
            getThingDefinition: () => this.zone.run(() => this.thingContextService.getCurrentThingDefinition()),
            getCustomer: () => this.zone.run(() => this.handleGetCustomer()),
            getLocation: () => this.zone.run(() => this.handleGetLocation()),
            getPartner: () => this.zone.run(() => this.handleGetPartner()),
            getServiceLevel: () => this.zone.run(() => this.handleGetServiceLevel()),
            getTestSession: () => this.zone.run(() => this.thingContextService.getCurrentThingTestSession()),
            getTestSessionStartDate: () => this.zone.run(() => this.handleGetTestSessionStartDate()),
            getTestSessionStopDate: () => this.zone.run(() => this.handleGetTestSessionStopDate()),
            getUserCustomer: () => this.zone.run(() => this.authenticationService.getUserCustomer()),
            getCustomerServiceLevel: () => this.zone.run(() => this.handleGetCustomerServiceLevel()),
            getWorkSession: () => this.zone.run(() => this.thingContextService.getCurrentWorkSession()),
            getTags: () => this.zone.run(() => this.contextService.getTags()),
            getCustomerTags: () => this.zone.run(() => this.contextService.getCustomerTags()),
            getLocationTags: () => this.zone.run(() => this.contextService.getLocationTags()),
            getPartnerTags: () => this.zone.run(() => this.contextService.getPartnerTags()),
            getCurrentTimestamp: () => this.zone.run(() => new Date().getTime()),
            getAlert: () => this.zone.run(() => this.thingContextService.getCurrentAlert()),
            resolveExportFileNamePlaceholders: (name: string, startDate: number, endDate: number) => this.zone.run(() => this.exportContextService.resolveExportFileNamePlaceholders(name, startDate, endDate)),
            registerFilter: (config: FilterConfig) => this.filterService.registerFilter(config),
            createFilter: (name: string) => this.filterService.createFilter(name),
            addItemToCart: (item: StoreOrderItem) => this.addItemToStoreCart(item),
            createPeriodPicker: (element: Element, config: PeriodFieldV2Settings, context: any) => this.externalComponentUtilsService.createPeriodPicker(element, config, context),
            getApiClient: () => this.apiClientService.getApiClient(),
            getCatalog: () => this.catalogService.getCatalog(),
            downsampleData: (data: any[], limit: number, timeField: string, valueField: string) => DataUtility.downsampleData(data, limit, timeField, valueField)
        };
    }

    initForPublicPages(): void {
        window['appUtils'] = {
            navigateTo: (url) => this.zone.run(() => this.navigationService.publicNavigate(url))
        };
    }

    private handleGetCustomer(): Customer {
        let user = this.authenticationService.getUser();
        return user && user.customer ? user.customer : this.contextService.getCurrentCustomer();
    }

    private handleGetLocation(): Location {
        let user = this.authenticationService.getUser();
        return user && user.location ? user.location : this.contextService.getCurrentLocation();
    }

    private handleGetPartner(): Partner {
        let user = this.authenticationService.getUser();
        let currentPartner = this.contextService.getCurrentPartner();
        return currentPartner || user.partner;
    }

    private handleGetServiceLevel(): ServiceLevel {
        let thing = this.thingContextService.getCurrentThing();
        if (thing) {
            return thing.serviceLevel;
        }
        return null;
    }

    private handleGetTestSessionStartDate(): number {
        let thingTestSession = this.thingContextService.getCurrentThingTestSession()
        return thingTestSession ? thingTestSession.startedAt : null;
    }

    private handleGetTestSessionStopDate(): number {
        let thingTestSession = this.thingContextService.getCurrentThingTestSession()
        return thingTestSession ? thingTestSession.stoppedAt : null;
    }

    private handleGetCustomerServiceLevel(): ServiceLevel {
        let customer = this.handleGetCustomer();
        return customer ? customer.serviceLevel : null;
    }

    private refreshCarts(): void {
        this.zone.run(() => {
            this.authenticationService.refreshShoppingCart();
            this.storeCartService.refreshStoreCart();
        });
    }

    private addItemToStoreCart(item: StoreOrderItem): void {
        item.quantity = (item.quantity == null || item.quantity < 1) ? 1 : item.quantity;
        this.storeCartService.addItemToCart(item).then(() => {
            this.refreshCarts();
        }).catch(e => console.error(e));
    }
}