import { HttpParams } from '@angular/common/http';
import { Component, ContentChildren, forwardRef, Inject, Input, NgZone, OnInit, QueryList } from '@angular/core';
import * as _ from 'lodash';
import * as moment_tz from 'moment-timezone';
import { BehaviorSubject } from 'rxjs';
import { LOCALE_TIMEZONE } from '../../../common/config';
import { SOCKET_TOPIC_DATA_VALUES, SOCKET_TOPIC_LIST_VALUES } from '../../../common/endpoints';
import { isEmpty } from '../../../common/helper';
import { AlertWorkSession, Customer, DataItem, Location, Partner, Thing, ThingDataItem, ThingEventType, Value } from '../../../model/index';
import { AuthenticationService } from '../../../service/authentication.service';
import { CustomPropertyService, CustomPropertyType } from '../../../service/custom-property.service';
import { CustomerTreeService } from '../../../service/customer-tree.service';
import { DataService } from '../../../service/data.service';
import { MetricService } from '../../../service/metric.service';
import { NetworkDataService } from '../../../service/network-data.service';
import { PropertyService } from '../../../service/property.service';
import { SocketService } from '../../../service/socket.service';
import { ThingService } from '../../../service/thing.service';
import { ColumnComponentDisplayMode } from '../../list-widget-v2/list-widget-v2.components';
import { COMPONENT_DEFINITION_REF } from "../../utility/component-definition-token";
import { MetricAggregationType, MetricDetailComponent } from '../metric/metric-detail.component';
import { PropertyComponent } from '../property/property.component';

export interface CompositePartValue {
    [key: string]: string;
};

export enum CompositePartMode {
    DETAIL,
    LIST
};

@Component({
    selector: 'composite-part',
    template: '',
    providers: [{ provide: COMPONENT_DEFINITION_REF, useExisting: forwardRef(() => CompositePartComponent) }]
})
export class CompositePartComponent implements OnInit {

    @Input() name: string;

    @Input() label: string;

    @Input() filter: string;

    @Input() x: any;

    @Input() y: any;

    @Input() showLabel: boolean = true;

    @Input() detailedValue: boolean;

    @Input() sorting: string;

    @Input() sortingCriteria: string;

    @Input() resettable: boolean;

    @Input() useDefaultNullValue: boolean;

    @Input() description: string;

    @Input() showHeader: boolean = true;

    @Input() columnClass: string;

    @Input() displayMode: ColumnComponentDisplayMode = ColumnComponentDisplayMode.VISIBLE;

    @ContentChildren(MetricDetailComponent) metrics: QueryList<MetricDetailComponent>;

    @ContentChildren(PropertyComponent) properties: QueryList<PropertyComponent>;

    private lastCompositePartValueMap: Map<string, CompositePartValue>;
    private socketSubscriptionMap: Map<string, number[]>;
    private privateMetrics: string[] = [];
    private thingSubject: { id: string, subscription: BehaviorSubject<ThingDataItem[]> };
    private timezone: string;
    private columnName: string;

    constructor(
        @Inject(forwardRef(() => DataService)) private dataService: DataService,
        @Inject(forwardRef(() => SocketService)) private socketService: SocketService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone,
        @Inject(forwardRef(() => CustomPropertyService)) private customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => PropertyService)) private propertyService: PropertyService,
        @Inject(forwardRef(() => NetworkDataService)) private networkDataService: NetworkDataService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) { }

    ngOnInit(): void {
        this.lastCompositePartValueMap = new Map();
        this.socketSubscriptionMap = new Map();
        this.timezone = this.authenticationService.getUser()?.timezone;
    }

    ngOnDestroy(): void {
        this.closeSocket();
        if (this.thingSubject) {
            this.propertyService.usubscribeFromThingProperties(this.thingSubject.id);
        }
    }

    get(object: Customer | Location | Thing | Partner | AlertWorkSession, mode: CompositePartMode, openSubscription: boolean = true, params?: HttpParams, isLocationMetric?: boolean, subject?: BehaviorSubject<Value | CompositePartValue>): BehaviorSubject<Value | CompositePartValue> {
        const id = object.id;
        if (!subject) {
            subject = new BehaviorSubject(null);
        }

        if (mode === CompositePartMode.DETAIL) {
            const metricNames = this.getMetricNames();
            const requests = metricNames.map(name => this.getRequest(object, name, this.setAggregationParams(params, name), isLocationMetric));
            Promise.all(requests).then(results => {
                // build first composite part value
                const lastCompositePartValue = this.buildCompositePartValue(metricNames, results, object, mode);
                this.lastCompositePartValueMap.set(id, lastCompositePartValue);
                const value: Value = {
                    timestamp: this.getLastTimestamp(results),
                    value: lastCompositePartValue,
                    unspecifiedChange: false
                };
                subject.next(value);
            }).catch(() => { }).then(() => {
                if (!isLocationMetric) {
                    const filteredMetricNames = metricNames.filter(name => !this.privateMetrics.includes(name) && this.metrics.find(m => m.name == name && (!m.aggregation || m.aggregation == MetricAggregationType.LAST_VALUE)));
                    this.openSubscriptionToSocket(filteredMetricNames, object, subject, mode, params)
                }
            });
        } else if (mode === CompositePartMode.LIST) {
            // build first composite part value
            const metricNames = this.getMetricNames();
            const lastCompositePartValue = this.buildCompositePartValue(metricNames, null, object, mode);
            this.lastCompositePartValueMap.set(id, lastCompositePartValue);
            subject.next(lastCompositePartValue);
            if (openSubscription && !isLocationMetric) {
                const filteredMetricNames = metricNames.filter(name => !this.privateMetrics.includes(name) && this.metrics.find(m => m.name == name && (!m.aggregation || m.aggregation == MetricAggregationType.LAST_VALUE)))
                this.openSubscriptionToSocket(filteredMetricNames, object, subject, mode, params);
            } else {
                metricNames.map(metricName => this.getRequest(object, metricName, this.setAggregationParams(params, metricName))
                    .then(newValue => this.updateLastCompositePartValue(subject, id, newValue, metricName, mode))
                    .catch(() => {/* TODO */ }))
            }
        } else {
            throw new Error('CompositePart: mode not defined');
        }
        return subject;
    }

    private openSubscriptionToSocket(metricNames: string[], object: Customer | Location | Thing | Partner | AlertWorkSession, subject: BehaviorSubject<CompositePartValue | Value>, mode: CompositePartMode, params?: HttpParams) {
        const socketSubscriptionIds = metricNames
            .map(metricName => this.getTopic(object, metricName))
            .map((topic, i) => {
                let wait = false;
                let requestQueued = false;
                return this.socketService.subscribe({
                    topic, callback: (message) => {
                        if (!wait) {
                            wait = true;
                            const newDataItem: DataItem = JSON.parse(message.body);
                            if (newDataItem.unspecifiedChange) {
                                this.getRequest(object, metricNames[i], params)
                                    .then(newValue => {
                                        this.updateLastCompositePartValue(subject, object.id, newValue, metricNames[i], mode);
                                    })
                                    .catch(() => { })
                                    .then(() => {
                                        if (requestQueued) {
                                            requestQueued = false;
                                            this.getRequest(object, metricNames[i], params)
                                                .then(newValue => {
                                                    this.updateLastCompositePartValue(subject, object.id, newValue, metricNames[i], mode);
                                                    wait = false;
                                                })
                                                .catch(() => wait = false);
                                        } else {
                                            wait = false;
                                        }
                                    });
                            } else {
                                this.updateLastCompositePartValue(subject, object.id, newDataItem, metricNames[i], mode);
                                wait = false;
                            }
                        } else {
                            requestQueued = true;
                        }
                    }
                });
            });
        this.socketSubscriptionMap.set(object.id, socketSubscriptionIds);

        // subscribe if is thing
        if (object['thingDefinitionId'] && !object['thing']) {
            this.thingSubject = { id: object.id, subscription: this.propertyService.subscribeToThingProperties(object.id) };
            this.thingSubject.subscription.subscribe((thingEvent: ThingDataItem[]) => this.updateProperties(object as Thing, thingEvent, subject, mode));
        }
    }

    private updateProperties(thing: Thing, thingEvent: ThingDataItem[], subject: BehaviorSubject<CompositePartValue | Value>, mode: CompositePartMode): void {

        if (thingEvent) {
            let lastCompositePartValue = this.lastCompositePartValueMap.get(thing.id);
            for (let thingDataItem of thingEvent) {
                let field;
                if (thingDataItem.type == ThingEventType.FIELD) {
                    field = thingDataItem.name;
                } else {    // ThingEventType.PROPERTY
                    field = 'properties.' + thingDataItem.name;
                }
                thing[field] = thingDataItem.value;
            }
            let result = {}
            this.properties.forEach(p => result[p.label || p.name] = this.getPropertyValue(thing, p.name));
            lastCompositePartValue = _.merge({}, lastCompositePartValue, result);
            this.lastCompositePartValueMap.set(thing.id, lastCompositePartValue);
            this.zone.run(() => {
                if (mode === CompositePartMode.DETAIL) {
                    subject.next({
                        timestamp: new Date().getTime(),
                        value: lastCompositePartValue,
                        unspecifiedChange: false
                    });
                } else {
                    subject.next(lastCompositePartValue)
                }
            });
        }
    }

    private getRequest(object: Customer | Location | Thing | Partner | AlertWorkSession, metricName: string, params?: HttpParams, isLocationMetric?: boolean): Promise<Value> {
        if (object.constructor === Customer) {
            return this.dataService.getLastValueByCustomerIdAndMetricName(object.id, metricName);
        } else if (object.constructor === Location) {
            if (isLocationMetric) {
                return this.networkDataService.getLastValueByLocationIdAndMetricName(object.id, metricName);
            } else {
                return this.dataService.getLastValueByLocationIdAndMetricName(object.id, metricName);
            }
        } else if (object.constructor === Partner) {
            return this.dataService.getLastValueByPartnerIdAndMetricName(object.id, metricName);
        } else {
            return this.dataService.getLastValueByThingIdAndMetricName(object.id, metricName, params);
        }
    }

    private getTopic(object: Customer | Location | Thing | Partner | AlertWorkSession, metricName: string): string {
        const listTopic = SOCKET_TOPIC_LIST_VALUES.replace('{id}', object.id).replace('{metricName}', MetricService.extractMetricName(metricName));
        if (object.constructor === Customer) {
            return listTopic.replace('{type}', 'customerValue');
        } else if (object.constructor === Location) {
            return listTopic.replace('{type}', 'locationValue');
        } else if (object.constructor === Partner) {
            return listTopic.replace('{type}', 'partnerValue');
        } else {
            return SOCKET_TOPIC_DATA_VALUES.replace('{thingId}', object.id).replace('{metricName}', metricName);
        }
    }

    private buildCompositePartValue(metricNames: string[], values: Value[], object: any, mode: CompositePartMode): CompositePartValue {
        const result = {};

        metricNames.forEach((metricName: string, i: number) => {
            let value = null;
            if (mode === CompositePartMode.DETAIL) {
                if (!isEmpty(values[i])) {
                    if (this.detailedValue) {
                        value = values[i];
                    } else {
                        value = values[i].value;
                    }
                    if (values[i].privateData) {
                        this.privateMetrics.push(metricName)
                    }
                }
            } else {
                if (object.values) {
                    const dataItem: DataItem = object.values[metricName];
                    if (dataItem) {
                        if (this.detailedValue) {
                            value = {
                                value: DataService.extractValue(dataItem.values),
                                timestamp: dataItem.timestamp
                            };
                        } else {
                            value = DataService.extractValue(dataItem.values);
                        }
                    }
                }
                const anyObject = object as any;
                if (anyObject.privateMetricNames && anyObject.privateMetricNames.includes(metricName)) {
                    this.privateMetrics.push(metricName)
                }
            }
            result[metricName] = value;
        });

        this.properties.forEach(p => result[p.label || p.name] = this.getPropertyValue(object, p.name));
        return result;
    }

    private getLastTimestamp(values: Value[]): number {
        let lastTimestamp = 0;
        values.forEach(v => lastTimestamp = v && v.timestamp && v.timestamp > lastTimestamp ? v.timestamp : lastTimestamp);
        if (lastTimestamp === 0) {
            lastTimestamp = Date.now();
        }
        return lastTimestamp;
    }

    private updateLastCompositePartValue(subject: BehaviorSubject<Value | CompositePartValue>, id: string, val: Value | DataItem, metricName: string, mode: CompositePartMode): void {
        let lastCompositePartValue = this.lastCompositePartValueMap.get(id);
        if (lastCompositePartValue) {
            let value;
            if (val['values']) {
                if (this.detailedValue) {
                    value = {
                        value: DataService.extractValue(val['values']),
                        timestamp: val.timestamp
                    };
                } else {
                    value = DataService.extractValue(val['values']);
                }
            } else {
                if (this.detailedValue) {
                    value = val;
                } else {
                    value = val['value'];
                }
            }
            lastCompositePartValue = _.merge({}, lastCompositePartValue, { [metricName]: value });
            this.lastCompositePartValueMap.set(id, lastCompositePartValue);
            this.zone.run(() => {
                if (mode === CompositePartMode.DETAIL) {
                    subject.next({
                        timestamp: val.timestamp,
                        value: lastCompositePartValue,
                        unspecifiedChange: false
                    });
                } else {
                    subject.next(lastCompositePartValue)
                }
            });
        }
    }

    private getMetricNames(): string[] {
        let metricNames = [];
        if (this.metrics && this.metrics.length > 0) {
            metricNames = this.metrics.map(m => m.name);
        }
        return metricNames;
    }

    private getPropertyValue(object: any, path: string): any {
        if (object) {
            if (object['thing']) {  // active & historical alerts/workSessions
                let alertWSPath = path + '';
                if (path.startsWith('customer.') && !object['customer']) {
                    alertWSPath = 'thing.location.' + path;
                } else if ((path.startsWith('location') && !object['location']) || (path.startsWith('thingDefinition') && !object['thingDefinitionId'])) {
                    alertWSPath = 'thing.' + path;
                }
                return _.get(object, alertWSPath, this.getDefaultPropertyValueFromPath(null, path));
            } else if (object['thingDefinitionId']) { // thing
                return ThingService.getValue(object, path, this.getDefaultPropertyValueFromPath(CustomPropertyType.Thing, path));
            } else if (object['customer']) { // location
                return CustomerTreeService.getLocationValue(object, path, this.getDefaultPropertyValueFromPath(CustomPropertyType.Location, path));
            } else if (object['organization']) { // partner
                return CustomerTreeService.getLocationValue(object, path, this.getDefaultPropertyValueFromPath(CustomPropertyType.Partner, path));
            } else {  // customer
                return CustomerTreeService.getCustomerValue(object, path, this.getDefaultPropertyValueFromPath(CustomPropertyType.Customer, path));
            }
        }
        return null;
    }

    getPropertyValues(object: any): any {
        let result = {};
        this.properties.forEach(p => result[p.label || p.name] = this.getPropertyValue(object, p.name));
        return result;
    }

    closeSocket() {
        if (this.socketSubscriptionMap) {
            this.socketSubscriptionMap.forEach(ids => ids.forEach(id => this.socketService.delete(id)));
            this.socketSubscriptionMap = new Map();
        }
    }

    private getDefaultPropertyValueFromPath(defaultPropertyType: CustomPropertyType, path: string): any {
        if (defaultPropertyType == null) {
            return null;
        }
        if (path.startsWith('properties.')) {
            return this.customPropertyService.getDefaultPropertyValue(defaultPropertyType, path.substring(11));
        } else if (path.startsWith('thing.properties.')) {
            return this.customPropertyService.getDefaultPropertyValue(CustomPropertyType.Thing, path.substring(17));
        } else if (path.startsWith('customer.properties.')) {
            return this.customPropertyService.getDefaultPropertyValue(CustomPropertyType.Customer, path.substring(20));
        } else if (path.startsWith('location.properties.')) {
            return this.customPropertyService.getDefaultPropertyValue(CustomPropertyType.Location, path.substring(20));
        } else if (path.startsWith('thingDefinition.properties.')) {
            return this.customPropertyService.getDefaultPropertyValue(CustomPropertyType.ThingDefinition, path.substring(27));
        }
        return null;
    }

    private setAggregationParams(params: HttpParams, metricName): HttpParams {
        const metric = this.metrics.find(metric => metric.name == metricName);
        if (metric.aggregation) {
            if (!params) {
                params = new HttpParams();
            }
            params = params.set('aggregation', metric.aggregation);
            if (!params.get("startDate")) {
                params = params.set('startDate', moment_tz.tz(this.timezone || LOCALE_TIMEZONE).subtract(7, 'days').startOf('day').valueOf().toString());
            }
        }
        return params
    }

    setColumnName(columnName: string): void {
        this.columnName = columnName;
    }

    getColumnName(): string {
        return this.columnName;
    }
}