import { Component, EventEmitter, Inject, Input, OnInit, Output, forwardRef } from "@angular/core";
import { DynamicListRow } from "../../dashboard-area/dynamic-list/dynamic-list-row";
import { CustomTableColumn } from "../../shared/custom-table";
import { ClickOnRowBehaviour } from "../../shared/list-widget-v2/list-widget-v2.components";
import { DefaultEventSeverityPipe } from "../../shared/pipe/default-event-severity.pipe";


const toBeFiltered: String[] = ["severity", "category", "title", "description", "name", "startTimestamp", "date", "duration", "thing.serialNumber", "showDetails"];
@Component({
    selector: 'alert-block-v2',
    template: require('./alert-block-v2.component.html'),
    styles: [require('./alert-block-v2.component.css')]
})
export class AlertBlockV2Component implements OnInit {

    @Input() alert: any;

    @Input() displayedColumns: CustomTableColumn[];

    @Input() expandable: boolean;

    @Input() isEvent: boolean;

    @Input() clickOnRowBehaviour: ClickOnRowBehaviour;

    @Output() goToAlertDetailsEvent = new EventEmitter();

    filteredColumns: CustomTableColumn[];
    severity: string;
    severityClass: string;

    constructor(@Inject(forwardRef(() => DefaultEventSeverityPipe)) private defaultEventSeverityPipe: DefaultEventSeverityPipe) { }


    ngOnInit() {
        if (this.isEvent) {
            this.severity = this.defaultEventSeverityPipe.transform(this.alert.eventSeverity);
            this.severityClass = this.severity.split(' ')[0].toLowerCase();
        } else {
            this.severity = this.alert.severity;
            this.severityClass = this.severity.toLowerCase();
        }
        this.filteredColumns = this.displayedColumns.filter(el => !toBeFiltered.includes(el.name));
    }

    getValue(row: DynamicListRow, columnIndex: number) {
        return row.values[columnIndex];
    }

    checkFiltered(index: number): boolean {
        return !toBeFiltered.some(el => el == (this.displayedColumns[index].name || this.displayedColumns[index].label));
    }

    goToAlertDetails(event: Event): void {
        event.stopPropagation();
        this.goToAlertDetailsEvent.emit(this.alert.id);
    }
}