
export class SubscriptionSet {
    id: string;
    type: SubscriptionSetType;
    customerId: string;
    partnerId: string;
    activationTimestamp: number;
    duration: SubscriptionSetDuration;
    subscriptionThings: SubscriptionThing[];
    subscriptionPlan: SubscriptionDigitalService;
    subscriptionAddOns: SubscriptionDigitalService[];
    // enriched fields
    config: DigitalServiceConfiguration;
    plans: DigitalService[];
    addOns: DigitalService[];
    features: DigitalServiceFeature[];
}

export class SubscriptionThing {
    thingId: string;
    subscriptionPlan: SubscriptionDigitalService;
    subscriptionaddOns: SubscriptionDigitalService[];
}

export class SubscriptionDigitalService {
    digitalServiceId: string;
    activationTimestamp: number;
    deactivationTimestamp: number;
    replacingDigitalServiceId: string;
}

export enum SubscriptionSetType {
    CUSTOMER = 'CUSTOMER',
    PARTNER = 'PARTNER'
}

export enum SubscriptionSetDuration {
    YEAR_1 = 'YEAR_1'
}

export class DigitalServiceConfiguration {
    id: string;
    type: DigitalServiceConfigurationType;
    trialPeriod: boolean;
    trialPeriodMonths: number;
    trialPeriodAvailability: DigitalServiceTrialPeriodAvailability;
    trialPeriodPlanId: string;
    trialPeriodAddOnIds: string[];
    activationMode: DigitalServiceActivationMode;
}

export class DigitalService {
    id: string;
    type: DigitalServiceType;
    digitalServiceConfigurationId: string;
    name: string;
    label: string;
    description: string;
    featureIds: string[];
    defaultRecurringFee: number;
    productModelRecurringFees: DigitalServiceProductModelFee[];
    temporaryAccess: boolean;
    temporaryAccessDays: number;
    temporaryAccessActivationFee: number;
    temporaryAccessProductModelActivationFees: DigitalServiceProductModelFee[];
    orderIndex: number;
    defaultPlan: boolean;
}

export interface DigitalServiceFeature {
    id: string;
    digitalServiceConfigurationId: string;
    name: string;
    label: string;
    description: string;
    orderIndex: number;
    predefined: boolean;
}

export enum DigitalServiceConfigurationType {
    CUSTOMER = 'CUSTOMER',
    PARTNER = 'PARTNER'
}

export enum DigitalServiceActivationMode {
    BY_CUSTOMER = 'BY_CUSTOMER',
    BY_PARTNER = 'BY_PARTNER',
    BY_THING = 'BY_THING'
}

export enum DigitalServiceTrialPeriodAvailability {
    FIRST_ACTIVATION_ONLY = 'FIRST_ACTIVATION_ONLY',
    ANY_ACTIVATION = 'ANY_ACTIVATION'
}

export enum DigitalServiceType {
    PLAN = 'PLAN',
    ADD_ON = 'ADD_ON'
}

export interface DigitalServiceProductModelFee {
    productModelId: string;
    fee: number;
}