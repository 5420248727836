import { Component, forwardRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { ErrorMessages } from '../../common/constants';
import { ActionDefinition, ActionStatusCount } from '../../model';
import { ActionContextService } from '../../service/action-context.service';
import { AuthenticationService } from '../../service/authentication.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';
import { ErrorUtility } from '../../utility/error-utility';
import { GroupedActionListService } from '../grouped-action-list/grouped-action-list.service';
import { ActionGroupListDialogComponent } from './action-group-list-dialog.component';

@Component({
    selector: 'action-group-list-widget',
    template: require('./action-group-list-widget.component.html'),
    styles: [require('./action-group-list-widget.component.css')],
    providers: [GroupedActionListService]
})

export class ActionGroupListWidgetComponent implements OnInit {

    @Input() title: string;

    @Input() status: string[];

    @Input() topic: string[];

    @Input() type: string[] = [];

    @Input() description: string;

    @ViewChild(ActionGroupListDialogComponent) dialog: ActionGroupListDialogComponent;

    error: string;
    loaded: boolean;
    readPermission: boolean;
    private actionDefinitions: ActionDefinition[] = [];
    actionStatusCounts: ActionStatusCount[];

    constructor(
        @Inject(forwardRef(() => GroupedActionListService)) private groupedActionListService: GroupedActionListService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => ActionContextService)) private actionContextService: ActionContextService
    ) { }

    ngOnInit() {
        this.readPermission = this.authenticationService.isCustomerUser() || this.authenticationService.isLocationUser()
            || ((this.authenticationService.isOrganizationUser() || this.authenticationService.isPartnerUser()) && !!this.contextService.getCurrentCustomer() && !this.contextService.getCurrentLocation() && !this.thingContextService.getCurrentThing());
        this.groupedActionListService.getAllActionDefinition().then(actionDefinitions => {
            this.actionDefinitions = actionDefinitions;
            this.getActionGroupList();
        }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
    }

    private getActionGroupList(): void {
        let body = [];
        body['states'] = this.status;
        body['topics'] = this.topic;
        body['actionDefinitionIds'] = this.getActionDefinitionIdsFromNames();
        this.groupedActionListService.getActionStatusCount(body).then(actionStatusCounts => {
            this.actionStatusCounts = actionStatusCounts;
            this.loaded = true;
        }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
    }

    private getActionDefinitionIdsFromNames(): string[] {
        let ids = [];
        this.type.forEach(actionName => {
            if (this.actionDefinitions.find(definition => actionName == definition.name)) {
                ids.push(this.actionDefinitions.find(definition => actionName == definition.name).id);
            }
        });
        return ids;
    }

    goToDetails(actionStatusCount: ActionStatusCount): void {
        this.actionContextService.updateCurrentActionDefinition(this.actionDefinitions.find(el => el.id == actionStatusCount.actionDefinitionId));
        if (this.status) {
            this.actionContextService.updateCurrentStatuses(this.status);
        }
        this.dialog.open(actionStatusCount.name);
    }

    getBadgeClass(priority: string): string {
        switch (priority) {
            case 'LOW': return 'badge badge-success';
            case 'NORMAL': return 'badge badge-warning';
            case 'HIGH': return 'badge badge-danger';
            default: return null;
        }
    }

    refresh(): void {
        this.loaded = false;
        this.getActionGroupList();
    }
}