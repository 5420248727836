import { HttpParams } from '@angular/common/http';
import { Component, forwardRef, Host, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CKEDITOR_CONFIG } from "../../common/config";
import { ErrorMessages, SAVE_DATA_ERROR } from '../../common/constants';
import { ACTIVE_ALERTS, ALERT_DEFINITIONS } from '../../common/endpoints';
import { AlertService } from '../../dashboard-area/shared/alert.service';
import { Alert, AlertDefinition, Thing } from '../../model';
import { HttpService } from '../../service/http.service';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';
import { ErrorUtility } from '../../utility/error-utility';

@Component({
    selector: 'manual-alert-reporting-widget',
    template: require('./manual-alert-reporting-widget.component.html'),
    styles: [require('./manual-alert-reporting-widget.component.css')],
    providers: [AlertService]
})
export class ManualAlertReportingWidgetComponent implements OnInit {

    @Input() title: string;

    @Input() showHeader: boolean;

    @Input() description: string;

    @ViewChild('reportForm') private reportForm: NgForm;

    error: string = null;
    alertDefinitions: AlertDefinition[];
    loaded: boolean;
    config = CKEDITOR_CONFIG;

    private activeAlerts: Alert[];
    private contextThing: Thing;
    private alertSubscription: Subscription;

    constructor(
        @Inject(forwardRef(() => AbstractThingContextService)) @Host() private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => ActivatedRoute)) private activatedRoute: ActivatedRoute,
        @Inject(forwardRef(() => HttpService)) private http: HttpService,
        @Inject(forwardRef(() => AlertService)) private alertService: AlertService
    ) { }

    ngOnInit(): void {
        this.contextThing = this.thingContextService.getCurrentThing();
        this.title = this.title || 'manualReportingTitle';
        if (this.showHeader === undefined) {
            this.showHeader = true;
        }
    }

    ngAfterContentInit() {
        this.activatedRoute.data.subscribe(data => {
            this.loadData(data);
        });
    }

    ngOnDestroy() {
        if (this.alertSubscription) {
            this.alertSubscription.unsubscribe();
            this.alertSubscription = null;
        }
        this.alertService.dispose();
    }

    private loadData(contextData: any): void {
        this.alertSubscription = this.alertService.loadActiveAlerts(contextData)
            .subscribe(alerts => {
                if (alerts) {
                    this.activeAlerts = alerts;
                    this.loadAlertDefinitions();
                }
            }, err => {
                this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR);
                this.loaded = true;
            });
    }

    private loadAlertDefinitions(): Promise<void> {

        return this.http.get<AlertDefinition[]>(ALERT_DEFINITIONS).toPromise()
            .then(alertDefs => {
                this.alertDefinitions = alertDefs.filter(a => a.manualActivation == true && !this.activeAlerts.some(actAl => actAl.alertDefinitionId == a.id)
                    && (!this.contextThing || this.contextThing.thingDefinitionId == a.thingDefinition.id));
                this.loaded = true;
            }).catch(err => {
                this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR);
                this.loaded = true;
            });
    }

    getDescription(): String {
        const alert = this.alertDefinitions.find(el => el.id == this.reportForm.value.occurredFault);
        return alert.technicalDescription ? (alert.technicalDescription.length ? alert.technicalDescription : alert.description) : alert.description;
    }

    report(): Promise<void> {
        if (this.contextThing && this.reportForm.value.occurredFault) {
            let params = new HttpParams();
            params = params.set('thingId', this.contextThing.id);
            params = params.set('alertDefinitionId', this.reportForm.value.occurredFault);
            return this.http.post<void>(ACTIVE_ALERTS, null, params).toPromise()
                .then(() => this.reportForm.reset())
                .catch(err => {
                    this.error = SAVE_DATA_ERROR;
                    console.log(err);
                });
        } else {
            this.error = SAVE_DATA_ERROR;
            return null;
        }
    }
}

