import { Component, forwardRef, Inject, Input, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { take } from "rxjs";
import { Permissions } from "../../common/constants";
import { ConfigurationParameter, ListRangeSelectionModeType, ProductModel, ThingDefinition, ThingInventoryManagementType } from '../../model';
import { Recipe } from "../../model/recipe";
import { AuthenticationService } from '../../service/authentication.service';
import { ProductModelService } from "../../service/product-model.service";
import { ThingDefinitionService } from "../../service/thing-definition.service";
import { ErrorUtility } from '../../utility/error-utility';
import { RecipeEditDialogComponent } from "./recipe-edit-dialog.component";
import { RecipeParameterViewerDialogComponent } from "./recipe-parameter-viewer-dialog.component";
import { RecipeService } from "./recipe.service";

@Component({
    selector: 'recipe-list-widget',
    template: require('./recipe-list.component.html'),
    styles: [require('./recipe-list.component.css')],
    providers: [RecipeService]
})
export class RecipeListComponent implements OnInit {

    @Input() title: string = 'recipesProperty';

    @Input() parameterForm: FormGroup;

    @Input() pageSize: number = 10;

    @Input() description: string;

    @ViewChild(RecipeEditDialogComponent) recipeDialog: RecipeEditDialogComponent;

    hasContext: boolean;
    recipes: Recipe[] = [];
    loaded: boolean;
    error: string;
    parameterValues: { [name: string]: any };
    writePermission: boolean;
    showLoader: boolean;
    showResult: boolean;
    resultIconClass: string;
    resultIconColor: string;
    disableApply: boolean;
    isCustomerContext: boolean;
    thingDefinitions: ThingDefinition[] = [];
    pageIndex: number = 0;
    totalPages: number;
    length: number;
    matPaginatorClass: string;
    productModels: ProductModel[];

    private allRecipes: Recipe[];

    constructor(
        @Inject(forwardRef(() => RecipeService)) private recipeService: RecipeService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog,
        @Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef,
        @Inject(forwardRef(() => ThingDefinitionService)) private thingDefinitionService: ThingDefinitionService,
        @Inject(forwardRef(() => ProductModelService)) private productModelService: ProductModelService
    ) { }

    ngOnInit(): void {
        this.updatePaginatorClass();
        this.hasContext = this.recipeService.hasContext();
        if (this.hasContext) {
            this.loadRecipes();
            this.writePermission = this.authenticationService.hasPermission(Permissions.WRITE_RECIPE);
            this.disableApply = !this.authenticationService.hasPermission(Permissions.SET_THING_PARAMETER);
            this.isCustomerContext = this.recipeService.isCustomerContext();
            if (this.isCustomerContext) {
                if (this.authenticationService.getThingInventoryManagement() == ThingInventoryManagementType.BY_MODEL) {
                    this.productModelService.getProductModelsAssociatedToThings().then(productModels => this.productModels = productModels);
                } else {
                    this.thingDefinitionService.getThingDefinitionsAssociatedToThings().then(defs => this.thingDefinitions = defs);
                }
            }
        }
    }

    addRecipe(): void {
        this.openRecipeDialog(null);
    }

    editRecipe(recipe: Recipe): void {
        this.openRecipeDialog(recipe);
    }

    openRecipeDialog(recipe: Recipe): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '30%';
        dialogConfig.maxWidth = '900px';
        dialogConfig.panelClass = "edit-recipe-dialog";
        dialogConfig.autoFocus = false;
        dialogConfig.data = {
            recipe: recipe,
            isCustomerContext: this.isCustomerContext,
            thingDefinitions: this.thingDefinitions,
            productModels: this.productModels
        }
        dialogConfig.viewContainerRef = this.vcRef;
        this.dialog.open(RecipeEditDialogComponent, dialogConfig).afterClosed().pipe(take(1)).subscribe(result => {
            if (result) {
                this.reloadRecipes();
            }
        });
    }

    viewParameters(recipe: Recipe): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = '700px'
        dialogConfig.autoFocus = false;
        dialogConfig.data = recipe;
        this.dialog.open(RecipeParameterViewerDialogComponent, dialogConfig);
    }

    import(importObject: { recipeId: string, file: File }): void {
        this.recipeService.import(importObject.recipeId, importObject.file)
            .then(() => this.reloadRecipes())
            .catch(err => this.error = ErrorUtility.getMessage(err));
    }

    applyParametersValues(recipe: Recipe): void {
        this.disableApply = true;
        this.showLoader = true;

        let configurationParameters: ConfigurationParameter[] = [];
        this.recipeService.getConfigurationParametersByThingDefinitionId(recipe.thingDefinition.id).then(params => {
            if (recipe.parameters) {
                for (let paramName in recipe.parameters) {
                    let param = params.find(p => p.name == paramName);
                    if (param) {
                        configurationParameters.push(param);
                    }
                }
            }
            this.recipeService.update(configurationParameters, recipe.parameters, recipe.id).then(() => {
                this.updateFeedback('green', 'fa-check-circle');
            }).catch(err => {
                this.error = err.error.message;
                this.updateFeedback('red', 'fa-times-circle');
            });
        });
    }

    private loadRecipes(): void {
        this.recipeService.getRecipesByContext().then(recipes => {
            this.allRecipes = recipes;
            this.length = this.allRecipes.length;
            this.totalPages = this.length / this.pageSize;
            this.pageIndex = 0;
            this.getSinglePageFromList();
            this.loaded = true;
        }).catch(err => this.error = ErrorUtility.getMessage(err));
    }

    private reloadRecipes(): void {
        this.loaded = false;
        this.error = null;
        this.loadRecipes();
    }

    private updateFeedback(color: string, iconClass: string): void {
        this.disableApply = false;
        this.showLoader = false;
        this.showResult = true;
        this.resultIconClass = iconClass + " float-right";
        this.resultIconColor = color;
        setTimeout(() => {
            this.showResult = false
        }, 2000);
    }

    private updatePaginatorClass(): void {
        if (this.authenticationService.getTenant().listRangeSelectionMode == ListRangeSelectionModeType.PAGES) {
            this.matPaginatorClass = "mat-paginator-pages-mode";
        }
    }

    changePage(pageEvent: PageEvent): void {
        this.pageIndex = pageEvent.pageIndex;
        this.getSinglePageFromList();
    }

    getSinglePageFromList(): void {
        const startIndex = this.pageIndex * this.pageSize;
        const endIndex = (startIndex + this.pageSize) > this.allRecipes.length ? (this.allRecipes.length) : (startIndex + this.pageSize);
        this.recipes = this.allRecipes.slice(startIndex, endIndex);
    }

}