import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, Inject, Input, OnInit, forwardRef } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import * as moment_tz from 'moment-timezone';
import { firstValueFrom } from "rxjs";
import { Permissions } from '../../common/constants';
import { TaskService } from "../../dashboard-area/task/task.service";
import { PagedList } from "../../model";
import { Task, TaskStatus } from "../../model/task";
import { TaskDefinition } from "../../model/task-definition";
import { AuthenticationService } from "../../service/authentication.service";
import { NavigationService } from "../../service/navigation.service";
import { TaskDefinitionService } from "../../service/task-definition.service";
import { ThingContextService } from "../../service/thing-context.service";
import { ConfirmDialog } from "../../shared/confirm-dialog/confirm-dialog.component";
import { ButtonActionValue, CustomTableColumn, CustomTableService } from "../../shared/custom-table";
import { LocalizationPipe } from "../../shared/pipe";
import { ErrorUtility } from "../../utility/error-utility";
import { TaskQueueWidgetAddDialogComponent } from "./task-queue-widget-add-dialog.component";
import { TaskQueueWidgetService } from "./task-queue-widget.service";

@Component({
    selector: 'task-queue-widget',
    template: require('./task-queue-widget.component.html'),
    providers: [TaskQueueWidgetService, TaskService, TaskDefinitionService],
})
export class TaskQueueWidgetComponent implements OnInit {

    @Input() title: string;

    @Input() description: string;

    tasks: Task[] = [];
    taskDefinitions: TaskDefinition[];
    widgetView: TaskQueueWidgetView = TaskQueueWidgetView.UPCOMING;
    pageIndex: number = 0;
    pageSize: number = 50;
    length: number;
    dataSource = new MatTableDataSource<Task>([]);
    hasReadPermission: boolean;
    hasWritePermission: boolean;
    isPaginatedTable: boolean;
    isDragAndDropEnabled: boolean;
    loaded: boolean;
    error: string;
    displayedColumns: CustomTableColumn[];
    timezone: string;
    locale: string;

    private offSyncMessage: string;

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => TaskQueueWidgetService)) private taskQueueWidgetService: TaskQueueWidgetService,
        @Inject(forwardRef(() => TaskService)) private taskService: TaskService,
        @Inject(forwardRef(() => TaskDefinitionService)) private taskDefinitionService: TaskDefinitionService,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => ThingContextService)) private thingContextService: ThingContextService,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog
    ) { }

    ngOnInit(): void {
        const user = this.authenticationService.getUser();
        this.locale = user.locale || user.language || navigator.language;
        this.timezone = user.timezone || moment_tz.tz.guess();
        this.hasReadPermission = this.authenticationService.hasPermission(Permissions.READ_TASK);
        this.hasWritePermission = this.authenticationService.hasPermission(Permissions.WRITE_TASK);
        this.offSyncMessage = this.localizationPipe.transform('Off Sync');
        if (this.thingContextService.getCurrentThing()) {
            this.getTasks();
            this.getTaskDefinitions();
        } else {
            this.loaded = true;
            this.error = this.localizationPipe.transform('Invalid widget context!');
        }
    }

    getTasks(): void {
        if (this.widgetView == TaskQueueWidgetView.UPCOMING) {
            this.getAllTasks();
        } else {
            this.getPagedTasks();
        }
    }

    viewChange(): void {
        this.loaded = false;
        this.getTasks();
    }

    getTaskDefinitions(): void {
        this.taskDefinitionService.getAllByThingDefinitionId(this.thingContextService.getCurrentThing().thingDefinitionId)
            .then(taskDefs => this.taskDefinitions = taskDefs);
    }

    setDisplayedColumns(): void {
        let columns = [
            CustomTableService.newSimpleColumn('type', 'typeProperty', 'taskDefinition.name'),
            CustomTableService.newSimpleColumn('name', 'nameProperty', 'name'),
            CustomTableService.newSimpleColumn('description', 'descriptionProperty', 'description')
        ]
        if (this.widgetView == TaskQueueWidgetView.UPCOMING) {
            columns.push(
                CustomTableService.newDatetimeColumn('creationTimestamp', 'createdOnProperty', 'creationTimestamp', null, this.authenticationService.getUser().timezone)
            );
        } else {
            columns.push(
                CustomTableService.newDatetimeColumn('completedTimestamp', 'processedOnProperty', 'completedTimestamp', null, this.authenticationService.getUser().timezone)
            );
        }
        columns.push(
            CustomTableService.newSimpleColumn('createdBy', 'createdByProperty', 'createdBy'),
        );
        if (this.widgetView == TaskQueueWidgetView.UPCOMING) {
            columns.push(
                CustomTableService.newSimpleColumn('status', 'statusProperty', 'statusWithTs'),
            );
        } else {
            columns.push(
                CustomTableService.newPipedColumn('status', 'statusProperty', 'status', 'underscoreRemover'),
            );
        }
        columns.push(
            CustomTableService.newIconColumn('offSync', '', 'showComplete', {
                "_default": {
                    isFontAwesome: false,
                    customIconClass: 'material-symbols-outlined',
                    customIconHtml: 'sync_problem',
                    iconStyle: { 'background-color': '#f39c12', 'padding': '2px', 'font-size': '20px', 'border-radius': '2px' },
                    tooltipPath: 'offSyncTooltip'
                }
            })
                .withVisiblePath('showComplete')
                .withStyle({ '_any': { 'width': '40px', 'padding-top': '10px' } }),
            CustomTableService.newButtonColumn('delete', '', 'id', 'float-right', 'deleteButton')
                .withMatIcon('delete')
                .withMatIconClass('material-symbols-outlined')
                .withVisiblePath('showWriteIcons')
                .withStyle({ '_any': { 'font-size': '20px', 'width': '10px', 'color': '#ff0000' } })
                .withStickyEndColumn(),
        );
        this.displayedColumns = columns;
    }

    private enrichTasks(tasks: Task[]) {
        const since = this.localizationPipe.transform('since');
        tasks.forEach(t => {
            t['showWriteIcons'] = this.hasWritePermission && this.widgetView == TaskQueueWidgetView.UPCOMING;
            t['isOffSync'] = (t.thingSyncTimestamp > 0) && (t.lastUpdateTimestamp > t.thingSyncTimestamp)
            t['offSyncTooltip'] = this.offSyncMessage;
            t['statusWithTs'] = `${t.status.replace('_', ' ')} (${since} ${this.getFormattedDate(this.getStatusTimestamp(t))})`
        });
    }

    private getStatusTimestamp(task: Task): number {
        switch (task.status) {
            case TaskStatus.PARKED:
                return task.parkedTimestamp;
            case TaskStatus.QUEUED:
                return task.queuedTimestamp;
            case TaskStatus.IN_PROGRESS:
                return task.startedTimestamp;
            case TaskStatus.COMPLETED:
                return task.completedTimestamp;
        }
    }

    private getFormattedDate(timestamp: number): string {
        return moment_tz.tz(timestamp, this.timezone).locale(this.locale).format('lll');
    }

    private getAllTasks(): void {
        this.taskQueueWidgetService.getAllTasks(['order', 'asc'], [TaskStatus.PARKED, TaskStatus.QUEUED, TaskStatus.IN_PROGRESS]).then(tasks => {
            this.enrichTasks(tasks);
            this.refreshTable(tasks);
        }).catch(err => {
            this.error = ErrorUtility.getMessage(err);
            this.loaded = true;
        });
    }

    private getPagedTasks(): void {
        this.taskQueueWidgetService.getTasks(this.pageIndex, 50, ['completedTimestamp', 'desc'], [TaskStatus.COMPLETED]).then(pagedList => {
            this.enrichTasks(pagedList.content);
            this.refreshPagedTable(pagedList);
        }).catch(err => {
            this.error = ErrorUtility.getMessage(err);
            this.loaded = true;
        });
    }

    private refreshTable(tasks: Task[]): void {
        this.tasks = tasks;
        this.pageIndex = 0;
        this.dataSource = new MatTableDataSource<Task>(this.tasks);
        this.loaded = true;
        this.setDisplayedColumns();
        this.setViewFlags();
    }

    private refreshPagedTable(pagedList: PagedList<Task>): void {
        this.tasks = pagedList.content;
        this.length = pagedList.totalElements;
        this.pageIndex = pagedList.number;
        this.pageSize = pagedList.size;
        this.dataSource = new MatTableDataSource<Task>(this.tasks);
        this.loaded = true;
        this.setDisplayedColumns();
        this.setViewFlags();
    }

    private setViewFlags() {
        this.isPaginatedTable = this.widgetView == TaskQueueWidgetView.PROCESSED;
        this.isDragAndDropEnabled = !this.isPaginatedTable && this.hasWritePermission;
    }

    changePage(pageEvent: PageEvent): void {
        this.pageIndex = pageEvent.pageIndex;
        this.getTasks();
    }

    rowClick(element: Task) {
        this.goToTaskDetail(element.id);
    }

    onButtonAction(actionValue: ButtonActionValue): void {
        switch (actionValue.action) {
            case 'edit':
                this.goToTaskDetail(actionValue.value);
                break;
            case 'delete':
                this.deleteTask(actionValue.value);
                break;
            case 'detail':
                this.goToTaskDetail(actionValue.value);
                break;
        }
    }

    private deleteTask(id: string): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '40%';
        dialogConfig.autoFocus = false;
        dialogConfig.data = {
            title: "taskDeleteAlertTitle",
            message: "taskDeleteAlertMessage"
        }
        firstValueFrom(this.dialog.open(ConfirmDialog, dialogConfig).afterClosed()).then(result => {
            if (result) {
                this.taskService.delete(id).then(() => this.getTasks());
            }
        });
    }

    private goToTaskDetail(id: string) {
        this.navigationService.navigateTo(['/dashboard/task_details', id]);
    }

    addTask() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '25%';
        dialogConfig.autoFocus = false;
        dialogConfig.data = this.taskDefinitions;
        firstValueFrom(this.dialog.open(TaskQueueWidgetAddDialogComponent, dialogConfig).afterClosed()).then(result => {
            if (result) {
                result['thingId'] = this.thingContextService.getCurrentThing().id;
                result['customerId'] = this.thingContextService.getCurrentThing().customerId;
                this.taskService.save(result).then(task => this.goToTaskDetail(task.id));
            }
        });
    }

    dragAndDropAction(event: CdkDragDrop<Task[]>): void {
        moveItemInArray(this.tasks, event.previousIndex, event.currentIndex);
        let tasksToUpdate: Task[] = [];
        this.tasks.forEach((t, i) => {
            if (t.order != i) {
                t.order = i;
                tasksToUpdate.push(t);
            }
        });
        Promise.all(tasksToUpdate.map(t => this.taskService.save(t, t.id)))
            .then(() => this.getTasks())
            .catch(err => {
                this.error = ErrorUtility.getMessage(err);
                this.loaded = true;
            });
    }
}

enum TaskQueueWidgetView {
    UPCOMING = 'UPCOMING',
    PROCESSED = 'PROCESSED'
}