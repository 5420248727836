import { Component, forwardRef, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { firstValueFrom, Subscription } from "rxjs";
import { GET_DATA_ERROR } from "../../common/constants";
import { ThingGroup } from "../../model";
import { AddButtonContextService, AddButtonResource } from "../../service/add-button-context.service";
import { AuthenticationService } from "../../service/authentication.service";
import { GroupedThingListService } from "../../service/grouped-thing-list.service";
import { AbstractContextService } from "../../shared/class/abstract-context-service.class";
import { AddTagDialogComponent } from "../../shared/component/tag-editor/add-tag-dialog.component";
import { CustomTableColumn, CustomTableService } from "../../shared/custom-table";
import { ErrorUtility } from "../../utility/error-utility";

@Component({
    selector: 'grouped-thing-list-widget',
    template: require('./grouped-thing-list.component.html'),
    providers: [GroupedThingListService]
})
export class GroupedThingListComponent implements OnInit, OnDestroy {

    @Input() groupingProperty: string;

    @Input() groupLabel: string;

    @Input() template: string;

    @Input() title: string;

    @Input() description: string;

    groups: ThingGroup[] = [];
    loaded: boolean;
    error: string;
    sort: string[] = ['name', 'asc'];
    displayedColumns: CustomTableColumn[] = [];
    dataSource = new MatTableDataSource<ThingGroup>([]);
    isTagGrouping: boolean;
    id = 'GroupedThingList_' + GroupedThingListService.GROUP_THING_LIST_WIDGET_ID++;
    hideAddTagButton: boolean;

    private subscription: Subscription;
    private addTagButtonVisibilitySubscription: Subscription;
    private addTagEventSubscription: Subscription;

    constructor(
        @Inject(forwardRef(() => GroupedThingListService)) private groupedThingListService: GroupedThingListService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog,
        @Inject(forwardRef(() => AddButtonContextService)) private addButtonService: AddButtonContextService
    ) { }

    ngOnInit(): void {
        this.isTagGrouping = 'tags' == this.groupingProperty;
        this.getAllThingGroups();
        this.displayedColumns = this.getDisplayedColumns();
        this.subscription = this.groupedThingListService.getSubscription().subscribe(refreshId => {
            if (this.id == refreshId) {
                this.getAllThingGroups();
            }
        });
        if (this.isTagGrouping) {
            this.subscribeToAddTagServices();
        }
    }

    private getAllThingGroups(): void {
        this.loaded = false;
        this.groupedThingListService.getRecursivelyAllThingGroups(this.groupingProperty, 0, [], this.sort)
            .then(groups => {
                this.groups = groups;
                if (['tags', 'customerTags', 'locationTags', 'partnerTags'].includes(this.groupingProperty)) {
                    this.addEmptyTagGroups(groups);
                }
                this.dataSource = new MatTableDataSource<ThingGroup>(this.groups);
            })
            .catch(err => this.error = ErrorUtility.getMessage(err, GET_DATA_ERROR))
            .finally(() => this.loaded = true);
    }

    private addEmptyTagGroups(groups: ThingGroup[]): void {
        let tags = this.getContextTags();
        let emptyTagGroups: ThingGroup[] = [];
        for (let tag of tags) {
            if (!groups.some(g => g.name == tag)) {
                emptyTagGroups.push({ name: tag, thingCount: 0, locationCount: 0 });
            }
        }
        if (emptyTagGroups.length) {
            if (this.sort[0] == 'name') {
                this.groups = [...this.groups, ...emptyTagGroups];
                if (this.sort[1] == 'asc') {
                    this.groups.sort(this.sortGroupsByNameAsc);
                } else {
                    this.groups.sort(this.sortGroupsByNameDesc);
                }
            } else if (this.sort[1] == 'asc') {
                this.groups = [...emptyTagGroups, ...this.groups];
            } else {
                this.groups = [...this.groups, ...emptyTagGroups];
            }
        }
    }

    private getContextTags(): string[] {
        switch (this.groupingProperty) {
            case 'tags':
                return this.contextService.getTags();
            case 'customerTags':
                return this.contextService.getCustomerTags();
            case 'locationTags':
                return this.contextService.getLocationTags();
            case 'partnerTags':
                return this.contextService.getPartnerTags();
            default:
                return [];
        }
    }

    private sortGroupsByNameAsc(t1: ThingGroup, t2: ThingGroup): number {
        return t1.name.localeCompare(t2.name);
    }
    private sortGroupsByNameDesc(t1: ThingGroup, t2: ThingGroup): number {
        return t2.name.localeCompare(t1.name);
    }

    private getDisplayedColumns(): CustomTableColumn[] {
        let columns = [
            CustomTableService.newSimpleColumn('name', this.groupLabel || 'groupProperty', 'name').withSortField('name'),
            CustomTableService.newSimpleColumn('thingCount', 'thingCountProperty', 'thingCount').withSortField('thingCount')
        ];
        if (!this.contextService.getCurrentLocation() && !this.authenticationService.isLocationUser()) {
            columns.push(CustomTableService.newSimpleColumn('locationCount', 'locationCountProperty', 'locationCount').withSortField('locationCount'));
        }
        return columns;
    }

    buildQuery(group: ThingGroup): string {
        let value;
        if (this.isTagGrouping || ['customerTags', 'locationTags', 'partnerTags'].includes(this.groupingProperty)) {
            value = `['${group.name}']`;
        } else {
            value = `'${group.name}'`;
        }
        return `[{ property: '${this.groupingProperty}', predicate: 'eq', value: ${value} }]`;
    }

    changeSort(sort: Sort): void {
        this.sort = [sort.active, sort.direction];
        this.getAllThingGroups();
    }

    addTag(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '25%';
        dialogConfig.maxWidth = '428px';
        dialogConfig.autoFocus = false;
        firstValueFrom(this.dialog.open(AddTagDialogComponent, dialogConfig).afterClosed()).then(result => {
            if (result) {
                this.contextService.initTags();
                this.getAllThingGroups();
            }
        })
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.unsubscribeFromAddButtonServices();
    }

    private subscribeToAddTagServices(): void {
        this.subscribeToAddTagEvent();
        this.subscribeToAddButtonVisibility();
    }

    private subscribeToAddButtonVisibility(): void {
        this.addTagButtonVisibilitySubscription = this.addButtonService.getIsAddButtonPresent(AddButtonResource.TAG).subscribe(isAddButtonPresent => {
            this.hideAddTagButton = isAddButtonPresent;
        });
    }

    private subscribeToAddTagEvent(): void {
        this.addTagEventSubscription = this.addButtonService.getAddTagEventSubject().subscribe(() => {
            this.contextService.initTags();
            this.getAllThingGroups();
        });
    }

    private unsubscribeFromAddButtonServices(): void {
        if (this.addTagButtonVisibilitySubscription) {
            this.addTagButtonVisibilitySubscription.unsubscribe();
        }
        if (this.addTagEventSubscription) {
            this.addTagEventSubscription.unsubscribe();
        }
    }
}