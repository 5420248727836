import { HttpParams } from "@angular/common/http";
import { Inject, Injectable, forwardRef } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { THING_CONNECTION_TOKENS_V2 } from "../../common/endpoints";
import { Customer, Location, PagedList, Thing } from "../../model";
import { ThingConnectionToken } from "../../model/thing-connection-token";
import { AuthenticationService } from "../../service/authentication.service";
import { CustomPropertyService, CustomPropertyType } from "../../service/custom-property.service";
import { HttpService } from "../../service/http.service";
import { CompositePartComponent, MetricDetailComponent, PropertyComponent } from "../../shared/component";
import { CustomTableColumn, CustomTableService, PipedTableColumn } from "../../shared/custom-table";
import { AbstractListWidgetV2Service } from "../../shared/list-widget-v2/abstract-list-widget-v2.service";

@Injectable()
export class ConnectionTokensListService extends AbstractListWidgetV2Service<ThingConnectionToken> {

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) authenticationService: AuthenticationService,
        @Inject(forwardRef(() => CustomPropertyService)) customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) {
        super(authenticationService, customPropertyService);
    }

    getPagedList(page: number, size: number, sort: string[], metricNames: Set<string>, searchFields: string[], advancedSearchBody: any, location?: Location, assigned?: boolean, unassigned?: boolean, currentThing?: Thing, includeParentsOnly?: boolean, customer?: Customer): Promise<PagedList<ThingConnectionToken>> {
        let params = new HttpParams();
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        if (sort && sort[0]) {
            if (sort[0].startsWith(AbstractListWidgetV2Service.COMPOSITE_SORT_PREFIX)) {
                sort = this.assignCompositeSort(sort);
            }
            params = params.set('sort', sort.join(','));
        }
        if (advancedSearchBody) {
            if (advancedSearchBody.key) {
                params = params.set('searchText', "*" + advancedSearchBody.key + "*");
                searchFields.forEach(field => params = params.append('searchField', field));
            }
        }

        return firstValueFrom(this.httpService.get<PagedList<ThingConnectionToken>>(THING_CONNECTION_TOKENS_V2, params));
    }

    protected getPropertyColumn(col: PropertyComponent, columnName: string, defaultType: string): CustomTableColumn {
        return (CustomTableService.newPipedColumn(columnName, this.getLabel(col, defaultType), col.name, this.getFilter(col, defaultType)) as PipedTableColumn).withArgument(this.getArgument(col))
            .withSortField(col.name).withValueMap(this.getValueMap(col)).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
    }

    protected isColumnVisible(columnName: string): boolean {
        return true;
    }

    protected getLabel(col: MetricDetailComponent | CompositePartComponent | PropertyComponent, defaultType: string): string {
        return col.label;
    }

    protected getCustomPropertyNameAndType(columnName: string): { name: string; type: CustomPropertyType; } {
        throw new Error("Method not implemented.");
    }
}