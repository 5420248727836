import { Component, forwardRef, Inject, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { take } from 'rxjs';
import { AppService } from '../../../service/app.service';
import { FieldService } from '../../../service/field.service';
import { ThingAdvancedSearchAddPropertiesDialog } from '../../advanced-search/thing-advanced-search/thing-advanced-search-add-properties-dialog.component';
import { ThingAdvancedSearchComponent } from '../../advanced-search/thing-advanced-search/thing-advanced-search.component';
import { AbstractSearchFieldComponent } from '../abstract-search-field.component';
import { SearchFieldInput } from '../search-field.component';
import { SearchFieldService } from '../search-field.service';
import { SelectionInputDialogData } from '../selection-input/selection-input.component';
import { ThingSelectionInputDialogComponent } from '../selection-input/thing-selection-input-dialog/thing-selection-input-dialog.component';
import { ThingsPropertySearchInputComponent } from './things-property-search-input.component';

@Component({
    selector: 'things-search-field',
    template: require('./things-search-field.component.html'),
    styles: [require('../search-field.component.css')]
})
export class ThingsSearchFieldComponent extends AbstractSearchFieldComponent {

    @ViewChild(ThingAdvancedSearchComponent) private thingAdvancedSearch: ThingAdvancedSearchComponent;

    @ViewChild(ThingAdvancedSearchAddPropertiesDialog) private addPropertiesDialog: ThingAdvancedSearchAddPropertiesDialog;

    @ViewChildren(ThingsPropertySearchInputComponent) private thingsPropertySearchInputs: QueryList<ThingsPropertySearchInputComponent>;

    private isMobile: boolean;

    constructor(
        @Inject(forwardRef(() => FieldService)) fieldService: FieldService,
        @Inject(forwardRef(() => SearchFieldService)) searchFieldService: SearchFieldService,
        @Inject(forwardRef(() => MatDialog)) private matDialog: MatDialog,
        @Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef,
        @Inject(forwardRef(() => AppService)) private appService: AppService
    ) {
        super(fieldService, searchFieldService);
        this.isMobile = this.appService.isMobile();
    }

    openAddPropertiesDialog(advancedSearchProperties: { name: string, label: string }[]): void {
        this.close();
        this.advancedSearchProperties = advancedSearchProperties;
        setTimeout(() => this.addPropertiesDialog.open(), 400);
    }

    closeAddPropertiesDialog(updatedSearchFields: string[]): void {
        if (updatedSearchFields) {
            this.thingAdvancedSearch.updateSearchFields(updatedSearchFields);
        }
        setTimeout(() => this.open(), 400);
    }

    performClear(): void {
        this.thingAdvancedSearch?.clearAll();
        this.clearInputs();
    }

    protected updateAdvancedSearch(fieldsToSaveBody: any): void {
        this.thingAdvancedSearch?.updateLocalStorage(fieldsToSaveBody);
        this.thingAdvancedSearch?.initAllConfigurations();
    }

    protected getEncodedRawValues(): any {
        let body: any = {};
        this.thingsPropertySearchInputs?.forEach(property => Object.assign(body, property.getFormValue(true)));
        return body;
    }

    protected getRawValues(): any {
        let body: any = {};
        this.thingsPropertySearchInputs?.forEach(property => Object.assign(body, property.getFormValue(false)));
        return body;
    }

    protected refreshInputConfigurations(): void {
        this.thingsPropertySearchInputs.forEach(input => {
            input.refreshConfiguration();
        });
    }

    openSelectionInput(input: SearchFieldInput): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = this.isMobile ? '90%' : '25%';
        dialogConfig.maxWidth = '800px';
        dialogConfig.autoFocus = false;
        const data: SelectionInputDialogData = {
            columnComponents: input.columnComponents,
            query: this.query,
            queryId: this.id,
            propertySearchFields: this.propertySearchFields
        };
        dialogConfig.data = data;
        dialogConfig.viewContainerRef = this.vcRef;
        this.matDialog.open(ThingSelectionInputDialogComponent, dialogConfig).afterClosed().pipe(take(1)).subscribe(selectedIds => {
            if (selectedIds) {
                this.advancedSearchBody = Object.assign({}, this.advancedSearchBody, { 'selectedThingIds': selectedIds.length ? selectedIds : null });
                this.selectedThingIdCount = selectedIds.length;
                this.updateLocalStorage(selectedIds);
                this.emitAdvancedSearch(this.advancedSearchBody);
            }
        });
    }

    private updateLocalStorage(selectedThingIds: string[]): void {
        let savedFieldsValues = localStorage.getItem(this.id) ? JSON.parse(localStorage.getItem(this.id)) : null;
        savedFieldsValues = Object.assign({}, savedFieldsValues, { 'selectedThingIds': selectedThingIds.length ? selectedThingIds : null });
        localStorage.setItem(this.id, JSON.stringify(savedFieldsValues));
    }

}