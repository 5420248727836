import { DOCUMENT } from "@angular/common";
import { Component, forwardRef, Inject, Input, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { firstValueFrom, Subscription } from "rxjs";
import { DownloadService, ExportPdfMode } from "../../../service/download.service";
import { ExportContextService } from "../../../service/export-context.service";
import { DownloadStatus, DownloadType } from "../../../shared/download-dialog/download-dialog.component";
import { ExportPdfButtonService } from "./export-pdf-button.service";

@Component({
    selector: 'export-pdf-button',
    template: `
        <div *ngIf="downloadService.isExportPdfButtonVisible() && !this.isExportButtonPresent" [ngClass]="divClass" [ngStyle]="{'top': topPosition + 'px' }">
            <button mat-icon-button (click)="exportPdf()" [ngClass]="buttonClass" [matTooltip]="'Export report' | localize" matTooltipClass="control-bar-tooltip">
                <mat-icon class="material-symbols-outlined">file_save</mat-icon>
            </button>
        </div>
    `
})
export class ExportPdfButtonComponent implements OnInit, OnDestroy {

    @Input() fileName: string = 'default.pdf'

    @Input() topPosition: number = 0;

    @Input() divClass: string = 'buttons-container';

    @Input() buttonClass: string = 'btn-export-pdf no-print';

    private subscription: Subscription;

    isExportButtonPresent: boolean;

    constructor(
        @Inject(forwardRef(() => DownloadService)) public downloadService: DownloadService,
        @Inject(forwardRef(() => ExportContextService)) private exportService: ExportContextService,
        @Inject(DOCUMENT) private document: Document,
        @Inject(forwardRef(() => Renderer2)) private renderer: Renderer2,
        @Inject(forwardRef(() => ExportPdfButtonService)) private exportPdfButtonService: ExportPdfButtonService
    ) { }

    ngOnInit(): void {
        this.subscription = this.exportService.getIsExportButtonPresent().subscribe(isExportButtonPresent => {
            this.isExportButtonPresent = isExportButtonPresent;
        });
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    exportPdf(): void {
        if (this.downloadService.getExportPdfMode() == ExportPdfMode.LOCAL) {
            const periodValue = this.exportService.getPdfPeriodRefValue();
            let fileName = this.exportService.resolveExportFileNamePlaceholders(this.exportService.getReportPdfFileName() || this.fileName, periodValue?.start, periodValue?.end);
            this.exportPdfButtonService.localExportPdf(fileName, this.document, this.renderer);
        } else {
            this.remoteExportPdf();
        }
    }

    private remoteExportPdf(): void {
        firstValueFrom(this.downloadService.getExportReportJobKey()).then(resp => {
            const periodValue = this.exportService.getPdfPeriodRefValue();
            const downloadingObject = {
                fileName: this.exportService.resolveExportFileNamePlaceholders(this.exportService.getReportPdfFileName() || this.fileName, periodValue?.start, periodValue?.end),
                uuid: resp.uuid,
                status: DownloadStatus.DOWNLOADING,
                type: DownloadType.PDF
            }
            this.downloadService.addDownloadingObject(downloadingObject);
            this.downloadService.setVisible();
        }).catch(err => {
            console.error(err);
        });
    }

}