import { Component, forwardRef, Host, Inject, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription, take } from 'rxjs';
import { Permissions } from '../../common/constants';
import { Firmware, Thing } from '../../model';
import { AppService } from '../../service/app.service';
import { AuthenticationService } from '../../service/authentication.service';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';
import { CustomTableColumn, CustomTableService } from '../../shared/custom-table';
import { ErrorUtility } from '../../utility/error-utility';
import { ThingFirmwareListInfoDialogComponent } from './thing-firmware-list-info-dialog.component';
import { ThingFirmwareListService } from './thing-firmware-list.service';

@Component({
    selector: 'thing-firmware-widget',
    template: require('./thing-firmware-list.component.html'),
    styles: [require('./thing-firmware-list.component.css')],
    providers: [ThingFirmwareListService]
})
export class ThingFirmwareListComponent implements OnInit, OnDestroy {

    @Input() maxHeight: string;

    @Input() title: string;

    @Input() timeout: number = 300; //seconds

    @Input() description: string;

    firmwares: Firmware[];
    writePermission: boolean;
    thing: Thing;
    updateOngoing: boolean;
    isThingOnline: boolean;
    loaded: boolean;
    error: string;
    displayedColumns: CustomTableColumn[] = [
        CustomTableService.newSimpleColumn('name', 'firmwareTabItem', 'name'),
        CustomTableService.newSimpleColumn('installedVersion', 'installedVersionProperty', 'installedVersion'),
        CustomTableService.newSimpleColumn('latestVersion', 'latestVersionProperty', 'version'),
        CustomTableService.newPipedColumn('updateStatus', '', 'updateStatus', 'firmwareUpdateStatus')
    ];
    dataSource = new MatTableDataSource<Firmware>([]);
    isMobile: boolean;

    private versionMetricSubscriptions: Subscription[] = [];
    private thingSubscription: Subscription;
    private updatingTimeoutIdMap: { [firmwareId: string]: any } = {};
    private supportLocalStorage: boolean;

    constructor(
        @Inject(forwardRef(() => AbstractThingContextService)) @Host() private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => ThingFirmwareListService)) private thingFirmwareListService: ThingFirmwareListService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => AppService)) private appService: AppService,
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog,
        @Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef
    ) { }

    ngOnInit() {
        this.isMobile = this.appService.isMobile();
        this.thing = this.thingContextService.getCurrentThing();
        this.writePermission = this.authenticationService.hasPermission(Permissions.UPDATE_FIRMWARE);
        this.thingSubscription = this.thingFirmwareListService.getConnectionStatusValueSubject(this.thing.id).subscribe(val => this.isThingOnline = val?.value == 1);
        if (this.thing) {
            this.getPagedFirmwares();
        }
        this.supportLocalStorage = !!window.localStorage;
    }

    ngOnDestroy() {
        this.thingFirmwareListService.destroy();
        this.unsubscribeVersionMetricSubscriptions();
        this.unsubscribeThingSubscription();
        if (this.updatingTimeoutIdMap) {
            Object.keys(this.updatingTimeoutIdMap).forEach(firmwareId => this.clearTimeout(firmwareId));
        }
    }

    private getPagedFirmwares(): void {
        this.loaded = false;
        this.thingFirmwareListService.getRecursivelyAllFirmwaresByThingDefinitionId(this.thing.thingDefinitionId, null, null).then(firmwares => {
            this.firmwares = firmwares.filter(f => (f.size || f.url) && f.currentVersion); //only shows current version firmware with file);
            this.initializeUpdateStatus();
            this.dataSource = new MatTableDataSource<Firmware>(this.firmwares);
            this.loaded = true;
        }).catch(err => {
            this.error = ErrorUtility.getMessage(err);
            this.loaded = true;
        });
    }

    private initializeUpdateStatus(): void {
        this.firmwares.forEach(f => {
            const storedStartTime = this.supportLocalStorage ? window.localStorage.getItem(this.getLocalStorageKey(f)) : null;
            let storedTimeoutIdPresent: boolean;
            if (storedStartTime) {
                // setting the updating state if the timeout is not over
                const timeLeft = (this.timeout * 1000) - (new Date().getTime() - Number(storedStartTime));
                if (timeLeft > 0) {
                    storedTimeoutIdPresent = true
                    this.setUpdatingStatus(f, timeLeft);
                    if (this.updatingTimeoutIdMap[f.id]) {
                        this.clearTimeout(f.id);
                    }
                    this.updatingTimeoutIdMap[f.id] = setTimeout(() => {
                        this.updatingTimeoutIdMap[f.id] = null;
                        storedTimeoutIdPresent = false;
                        this.completeUpdate(f, FirmwareUpdateStatus.UPDATING_FAILURE);
                    }, timeLeft);
                } else {
                    window.localStorage.removeItem(this.getLocalStorageKey(f));
                }
            }
            let versionSubject = this.thingFirmwareListService.getVersionMetricSubject(f.versionMetric, this.thing.id);
            this.versionMetricSubscriptions.push(versionSubject.subscribe(val => {
                f['installedVersion'] = val.value;
                if ((this.updatingTimeoutIdMap[f.id] && (!storedTimeoutIdPresent || val.timestamp > Number(storedStartTime)))) {
                    this.clearTimeout(f.id);
                    this.completeUpdate(f, (f['installedVersion'] == f.version) ? FirmwareUpdateStatus.UPDATING_SUCCESS : FirmwareUpdateStatus.UPDATING_FAILURE);
                } else if (!this.updatingTimeoutIdMap[f.id]) {
                    f['updateStatus'] = this.getFirmwareUpdateStatus(f);
                }
            }));
        });
    }

    private unsubscribeVersionMetricSubscriptions(): void {
        if (this.versionMetricSubscriptions?.length) {
            this.versionMetricSubscriptions.forEach(s => s.unsubscribe());
        }
        this.versionMetricSubscriptions = [];
    }

    private unsubscribeThingSubscription(): void {
        if (this.thingSubscription) {
            this.thingSubscription.unsubscribe();
        }
        this.thingSubscription = null;
    }

    openDetailsDialog(firmware: Firmware): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = this.isMobile ? '90%' : '25%';
        dialogConfig.maxWidth = '428px';
        dialogConfig.autoFocus = false;
        dialogConfig.viewContainerRef = this.vcRef;
        dialogConfig.data = {
            firmware: firmware,
            writePermission: this.writePermission && this.isThingOnline && !this.updatingTimeoutIdMap[firmware.id],
            thing: this.thing
        };
        this.dialog.open(ThingFirmwareListInfoDialogComponent, dialogConfig).afterClosed().pipe(take(1)).subscribe(update => {
            if (update) {
                if (this.supportLocalStorage) {
                    window.localStorage.setItem(this.getLocalStorageKey(firmware), new Date().getTime() + "");
                }
                this.setUpdatingStatus(firmware, this.timeout * 1000);
            }
        });
    }

    private setUpdatingStatus(firmware: Firmware, timeout: number): void {
        firmware['updateStatus'] = FirmwareUpdateStatus.UPDATING;
        this.updatingTimeoutIdMap[firmware.id] = setTimeout(() => this.completeUpdate(firmware, FirmwareUpdateStatus.UPDATING_FAILURE), timeout);
    }

    private completeUpdate(firmware: Firmware, state: FirmwareUpdateStatus) {
        firmware['updateStatus'] = state;
        this.updatingTimeoutIdMap[firmware.id] = null;
        setTimeout(() => {
            firmware['updateStatus'] = this.getFirmwareUpdateStatus(firmware);
        }, 5000);
        if (this.supportLocalStorage) {
            window.localStorage.removeItem(this.getLocalStorageKey(firmware));
        }
    }

    private getLocalStorageKey(firmware: Firmware): string {
        return this.thing.id + '_' + firmware.id;
    }

    private getFirmwareUpdateStatus(firmware: Firmware): FirmwareUpdateStatus {
        return firmware['installedVersion'] == firmware.version ? FirmwareUpdateStatus.UPDATED : FirmwareUpdateStatus.TO_UPDATE;
    }

    private clearTimeout(firmwareId: string): void {
        clearTimeout(this.updatingTimeoutIdMap[firmwareId]);
        this.updatingTimeoutIdMap[firmwareId];
    }

}

export enum FirmwareUpdateStatus {
    TO_UPDATE = 'TO_UPDATE',
    UPDATED = 'UPDATED',
    UPDATING = 'UPDATING',
    UPDATING_SUCCESS = 'UPDATING_SUCCESS',
    UPDATING_FAILURE = 'UPDATING_FAILURE'
}