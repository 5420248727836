import { HttpParams } from "@angular/common/http";
import { Component, forwardRef, Inject, OnInit } from "@angular/core";
import { DateRange } from "@angular/material/datepicker";
import { firstValueFrom } from "rxjs";
import { AlertSeverities } from "../../../common/constants";
import { THING_DEFINITIONS } from "../../../common/endpoints";
import { CustomerService } from "../../../dashboard-area/customer/customer.service";
import { AlertService } from "../../../dashboard-area/shared/alert.service";
import { Customer, LocationAlertDefinition, ThingDefinition } from "../../../model";
import { AlertDefinitionService } from "../../../service/alert-definition.service";
import { AuthenticationService } from "../../../service/authentication.service";
import { ContextService } from "../../../service/context.service";
import { CustomLabelService } from "../../../service/custom-label.service";
import { CustomPropertyService } from "../../../service/custom-property.service";
import { DateRangeService } from "../../../service/date-range.service";
import { HttpService } from "../../../service/http.service";
import { UserCustomerService } from "../../../service/user-customer.service";
import { AbstractContextService } from "../../../shared/class/abstract-context-service.class";
import { FormOption } from "../../../shared/form-editor/form-field-type/form-option.interface";
import { LocalizationPipe } from "../../../shared/pipe";
import { SearchFieldService } from "../search-field.service";
import { AbstractPropertySearchInputComponent } from "../search-input/abstract-property-search-input.component";

@Component({
    selector: 'alerts-property-search-input',
    template: require('./alerts-property-search-input.component.html'),
    providers: [SearchFieldService, CustomerService]
})
export class AlertsPropertySearchInputComponent extends AbstractPropertySearchInputComponent implements OnInit {

    private addCustomerSearchField: boolean;

    constructor(
        @Inject(forwardRef(() => CustomPropertyService)) customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => CustomLabelService)) customLabelService: CustomLabelService,
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => UserCustomerService)) private userCustomerService: UserCustomerService,
        @Inject(forwardRef(() => SearchFieldService)) protected searchFieldService: SearchFieldService,
        @Inject(forwardRef(() => CustomerService)) private customerService: CustomerService,
        @Inject(forwardRef(() => AlertDefinitionService)) private alertDefinitionService: AlertDefinitionService,
        @Inject(forwardRef(() => AlertService)) private alertService: AlertService,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => DateRangeService)) private dateRangeService: DateRangeService
    ) {
        super(customPropertyService, customLabelService, searchFieldService);
    }

    initConfiguration(): void {
        this.defaultProperties = [
        ];
        const customerId = this.authenticationService.getUser().customerId || (this.contextService.getCurrentCustomer() ? this.contextService.getCurrentCustomer().id : null)
            || (this.authenticationService.isLocationUser() ? (ContextService.getCustomerFromLocation(this.authenticationService.getUser().location) ? ContextService.getCustomerFromLocation(this.authenticationService.getUser().location).id : null) : null);
        this.addCustomerSearchField = ((this.authenticationService.isOrganizationUser() || this.authenticationService.isPartnerUser()) && !customerId) || this.authenticationService.getUser().hostCustomers?.length > 0;
        if (this.property?.indexOf('properties.') > -1 || this.defaultProperties.some(prop => prop.name == this.property)) {
            if (!this.addCustomerSearchField && this.property.indexOf("customer.") > -1) {
                this.searchFields = null;
            } else {
                this.searchFields = [this.property];
            }
        } else {
            this.useDefaultProperty()
        }
    }

    protected handleDefaultProperty(): void {
        if (this.property == "customer" && this.addCustomerSearchField) {
            this.handleCustomer();
        } else if (this.property == "thingDefinitions") {
            this.handleThingDefinitions();
        } else if (this.property == "severities" || this.property == "severity") {
            this.handleSeverities();
        } else if (this.property == "alertDefinitions") {
            this.handleAlertDefinitions();
        } else if (this.property == "period") {
            this.handlePeriod();
        }
    }

    private handleCustomer(): void {
        if (this.authenticationService.getUser().hostCustomers?.length) {
            const customerIds = this.authenticationService.getUser().hostCustomers.map(hc => { return hc.customerId });
            Promise.all(customerIds.map(id => { return this.customerService.getCustomerById(id) })).then(results => {
                const customerTypes = results.map((c: Customer) => { return { value: c.id, label: c.name } });
                if (customerTypes?.length) {
                    this.advancedSearchConfiguration = [{ name: 'customer', placeholder: this.customLabels && this.customLabels['customerProperty'] ? this.customLabels['customerProperty'] : this.property, type: 'STRING', selectionMode: 'MAT_SELECTION', values: customerTypes, value: this.searchFieldService.getValueFromFieldsValues('customer', this.savedFieldsValues), hideLabel: true }];
                }
            }).catch(() => []);
        } else {
            let partnerParam = null;
            if (this.contextService.getCurrentPartner()) {
                partnerParam = new HttpParams().set('partnerId', this.contextService.getCurrentPartner().id);
            }
            this.userCustomerService.getRecursivelyAllCustomers(null, null, partnerParam).then(customers => {
                const customerTypes = customers.map((c: Customer) => { return { value: c.id, label: c.name } });
                if (customerTypes?.length) {
                    this.advancedSearchConfiguration = [{ name: 'customer', placeholder: this.customLabels && this.customLabels['customerProperty'] ? this.customLabels['customerProperty'] : this.property, type: 'STRING', selectionMode: 'MAT_SELECTION', values: customerTypes, value: this.searchFieldService.getValueFromFieldsValues('customer', this.savedFieldsValues), hideLabel: true }];
                }
            }).catch(err => console.log(err));
        }
    }

    private handleThingDefinitions(): void {
        firstValueFrom(this.httpService.get<ThingDefinition[]>(THING_DEFINITIONS)).then(thingDefinitions => {
            const thingDefinitionTypes = thingDefinitions.map((td: ThingDefinition) => { return { value: td.id, label: td.name } });
            if (thingDefinitionTypes?.length) {
                this.advancedSearchConfiguration = [{ name: 'thingDefinitions', placeholder: "All Thing Definitions", type: 'STRING', selectionMode: 'MAT_SELECTION', values: thingDefinitionTypes, value: this.searchFieldService.getValueFromFieldsValues('thingDefinitions', this.savedFieldsValues), multipleSelection: true, hideLabel: true }];
            }
        }).catch(err => console.log(err));
    }

    private handleSeverities(): void {
        let tenant = this.authenticationService.getTenant();
        const severities = AlertSeverities.filter(s => {
            if (tenant.alertCriticalSeverityType == 'FAILURE') {
                return s.value != 'CRITICAL';
            } else if (tenant.alertCriticalSeverityType == 'CRITICAL') {
                return s.value != 'FAILURE';
            }
            return true;
        });
        this.advancedSearchConfiguration = [{ name: 'severities', placeholder: "All Severities", type: 'STRING', selectionMode: 'MAT_SELECTION', values: severities, value: this.searchFieldService.getValueFromFieldsValues('severities', this.savedFieldsValues), multipleSelection: true, hideLabel: true }];
    }

    private handleAlertDefinitions(): void {
        let promises = [];
        promises.push(this.alertService.getAlertDefinitions().catch(() => []));
        promises.push(this.alertDefinitionService.getLocationAlertDefintions().catch(() => []));
        Promise.all(promises).then(results => {
            let alertDefinitionGroups: { groupId: string, groupLabel: string, groupValues: FormOption[] }[] = [];
            const alertDefinitionsTree = this.alertService.buildTree(results[0]);
            const locationAlertDefinitions = results[1].map((locDef: LocationAlertDefinition) => { return { value: "LOCATION_ALERT_DEF:" + locDef.id, label: locDef.name }; });
            if (alertDefinitionsTree && alertDefinitionsTree.length) {
                alertDefinitionGroups = alertDefinitionsTree.map(el => { return { groupId: el.id, groupLabel: el.label, groupValues: el.children.map(child => { return { value: child.id, label: child.label } }) } });
            }
            if (locationAlertDefinitions && locationAlertDefinitions.length) {
                alertDefinitionGroups.push({ groupId: null, groupLabel: this.localizationPipe.transform('Location Alert Definitions'), groupValues: locationAlertDefinitions });
            }
            if (alertDefinitionGroups && alertDefinitionGroups.length) {
                this.advancedSearchConfiguration = [{ name: 'alertDefinitions', placeholder: "All Alert Types", type: 'STRING', selectionMode: 'MAT_SELECTION', values: [], value: this.searchFieldService.getValueFromFieldsValues('alertDefinitions', this.savedFieldsValues), multipleSelection: true, enableMatSelectGroups: true, matSelectGroupValues: alertDefinitionGroups, hideLabel: true }];
            }
        });
    }

    private handlePeriod(): void {
        this.advancedSearchConfiguration = [{ name: 'period', type: 'PERIOD', value: this.getDateRange(), hideLabel: true }];
    }

    protected handleValue(body: any, isEncoded: boolean): any {
        if (this.property == 'period') {
            const rangeValue: { range: DateRange<moment.Moment>, rangeName: string } = body['period'];
            const range: DateRange<moment.Moment> = rangeValue?.rangeName ? this.dateRangeService.getCustomDateRangeByName(rangeValue.rangeName)?.range : rangeValue?.range;
            delete body['period'];
            body['rangeName'] = rangeValue?.rangeName;
            body['startTimestamp'] = range?.start?.valueOf();
            body['endTimestamp'] = range?.end?.valueOf();
        } else if (this.property == 'alertDefinitions' && isEncoded) {
            const allAlertDefinitions = body['alertDefinitions'] || [];
            const alertDefinitionIds = allAlertDefinitions.filter((id: string) => !id.startsWith("LOCATION_ALERT_DEF:"));
            let locationAlertDefinitionIds = allAlertDefinitions.filter((id: string) => id.startsWith("LOCATION_ALERT_DEF:"));
            locationAlertDefinitionIds = locationAlertDefinitionIds.map(id => { return id.substring(19) });
            body['alertDefinitions'] = alertDefinitionIds?.length ? alertDefinitionIds : null;
            body['locationAlertDefinitions'] = locationAlertDefinitionIds?.length ? locationAlertDefinitionIds : null;
        }
        return body;
    }

}