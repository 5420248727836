import { AmChartsModule } from '@amcharts/amcharts3-angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from 'ckeditor4-angular';
import { DynamicListModule } from '../dashboard-area/dynamic-list/dynamic-list.module';
import { EventDetailsPageDialogModule } from '../dashboard-area/event-details/event-details-page-dialog.module';
import { WorkSessionDetailsModule } from '../dashboard-area/work-session-details/work-session-details.module';
import { SharedModule } from '../shared/shared.module';
import { TagModule } from '../shared/tags/tag.module';
import { ActionGroupListDialogContentComponent } from './action-group-list/action-group-list-dialog-content.component';
import { ActionGroupListDialogComponent } from './action-group-list/action-group-list-dialog.component';
import { ActionGroupListWidgetComponent } from './action-group-list/action-group-list-widget.component';
import { ActionInfoDialogComponent } from './action-info-dialog/action-info-dialog.component';
import { ActionListWidgetReschedulingDialogComponent } from './action-list/action-list-widget-rescheduling-dialog.component';
import { ActionListWidgetComponent } from './action-list/action-list-widget.component';
import { ActivateThingLocationSelectionDialogComponent } from './activate-thing/activate-thing-location-selection-dialog.component';
import { ActivateThingWidgetComponent } from './activate-thing/activate-thing-widget.component';
import { AddButtonComponent } from './add-button/add-button.component';
import { AlertCountWidgetComponent } from './alert-count/alert-count-widget.component';
import { ActiveAlertListWidgetV2Component } from './alert-list-v2/active-alert-list-v2/active-alert-list-widget-v2.component';
import { AlertBlockV2Component } from './alert-list-v2/alert-block-v2.component';
import { EventListWidgetComponent } from './alert-list-v2/event-list/event-list-widget.component';
import { HistoricalAlertListWidgetV2Component } from './alert-list-v2/historical-alert-list-v2/historical-alert-list-widget-v2.component';
import { ActiveAlertListWidgetComponent } from './alert-list/active-alert-list/active-alert-list-widget.component';
import { AlertBlockComponent } from './alert-list/active-alert-list/alert-block.component';
import { HistoricalAlertWidgetComponent } from './alert-list/historical-alert-list/historical-alert-list-widget.component';
import { AuditEventBlockComponent } from './audit-event-list-widget/audit-event-block.component';
import { AuditEventDetailDialogComponent } from './audit-event-list-widget/audit-event-detail-dialog.component';
import { AuditEventDetailComponent } from './audit-event-list-widget/audit-event-detail.component';
import { AuditEventListWidgetComponent } from './audit-event-list-widget/audit-event-list-widget.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { BenchmarkComponent } from './benchmark/benchmark.component';
import { BulkOperationDialogComponent } from './bulk-operation-dialog/bulk-operation-dialog.component';
import { BulkOperationSelectionDialogComponent } from './bulk-operation-selection-dialog/bulk-operation-selection-dialog.component';
import { BulkUpdateStatusWidgetDetailsComponent } from './bulk-update-status/bulk-update-status-widget-details.component';
import { BulkUpdateStatusWidgetScheduleDialogComponent } from './bulk-update-status/bulk-update-status-widget-schedule-dialog.component';
import { BulkUpdateStatusWidgetComponent } from './bulk-update-status/bulk-update-status-widget.component';
import { CalendlyWidgetComponent } from './calendly-widget/calendly-widget.component';
import { CatalogDetailsDialogDynamicContentComponent } from './catalog-widget/catalog-details-dialog/catalog-details-dialog-dynamic-content.component';
import { SparePartDefinitionCatalogDetailsDialogComponent } from './catalog-widget/catalog-details-dialog/spare-part-definition-catalog-details-dialog/spare-part-definition-catalog-details-dialog.component';
import { CatalogWidgetComponent } from './catalog-widget/catalog-widget.component';
import { ProductModelTechnicalSchemeSelectorComponent } from './catalog-widget/product-model-scheme-selector/product-model-technical-scheme-selector.component';
import { SparePartDefinitionCatalogCardComponent } from './catalog-widget/spare-part-definition-catalog-card/spare-part-definition-catalog-card.component';
import { SparePartDefinitionCatalogListComponent } from './catalog-widget/spare-part-definition-catalog-list/spare-part-definition-catalog-list.component';
import { CollapsibleWidgetComponent } from './collapsible-widget/collapsible-widget.component';
import { CommandComponent } from './commands/command.component';
import { CommandsComponent } from './commands/commands.component';
import { CompositeWidgetComponent } from './composite-widget/composite-widget.component';
import { ConfigurationParameterEntryComponent } from './configuration-parameters/configuration-parameter-entry.component';
import { ConfigurationParameterFieldComponent } from './configuration-parameters/configuration-parameter-field.component';
import { ConfigurationParametersComponent } from './configuration-parameters/configuration-parameters.component';
import { ConnectionTestConfigurationComponent } from './connection-test/connection-test-configuration/connection-test-configuration.component';
import { ConnectionTestListComponent } from './connection-test/connection-test-list/connection-test-list.component';
import { ConnectionTestWidgetComponent } from './connection-test/connection-test-widget.component';
import { ConnectionTokensDeleteComponent } from './connection-tokens/connection-tokens-delete.component';
import { ConnectionTokensEditComponent } from './connection-tokens/connection-tokens-edit.component';
import { ConnectionTokensImportComponent } from './connection-tokens/connection-tokens-import.component';
import { ConnectionTokensListComponent } from './connection-tokens/connection-tokens-list.component';
import { ConnectionTokensWidgetComponent } from './connection-tokens/connection-tokens-widget.component';
import { ConsumptionChartDialogComponent } from './consumption/consumption-chart-dialog.component';
import { ConsumptionChartComponent } from './consumption/consumption-chart.component';
import { ConsumptionComponent } from './consumption/consumption.component';
import { CustomWidgetComponent } from './custom-widget/custom-widget.component';
import { CustomerBulkImportWidgetComponent } from './customer-bulk-import/customer-bulk-import.component';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { CustomerListWidgetV2Component } from './customer-list/customer-list-widget-v2.component';
import { CustomerListWidgetComponent } from './customer-list/customer-list-widget.component';
import { CustomerTrendComponent } from './customer-trend/customer-trend.component';
import { DataExportMetricSelectorTableComponent } from './data-export-widget/data-export-metric-selector-table.component';
import { DataExportWidgetAddThingDefinitionDialog } from './data-export-widget/data-export-widget-add-thing-definition-dialog.component';
import { DataExportWidgetSchedulePageComponent } from './data-export-widget/data-export-widget-schedule-page.component';
import { DataExportWidgetComponent } from './data-export-widget/data-export-widget.component';
import { EventDetailsComponent } from './event-details/event-details.component';
import { ExportButtonComponent } from './export-button/export-button.component';
import { ExportDialogComponent } from './export-button/export-dialog.component';
import { GaugeWidgetComponent } from './gauge/gauge-widget.component';
import { GroupedActionContentComponent } from './grouped-action-list/grouped-action-content.component';
import { GroupedActionListComponent } from './grouped-action-list/grouped-action-list.component';
import { GroupedThingContentTagActionComponent } from './grouped-thing-list/grouped-thing-content-tag-action.component';
import { GroupedThingContentTagDialogComponent } from './grouped-thing-list/grouped-thing-content-tag-dialog.component';
import { GroupedThingContentComponent } from './grouped-thing-list/grouped-thing-content.component';
import { GroupedThingListComponent } from './grouped-thing-list/grouped-thing-list.component';
import { IncludeTemplateComponent } from './include-template/include-template.component';
import { LinkElementComponent } from './link-element/link-element.component';
import { LocationDetailsComponent } from './location-details/location-details.component';
import { LocationListElementComponent } from './location-list/location-list-element.component';
import { LocationListWidgetV2Component } from './location-list/location-list-widget-v2.component';
import { LocationListWidgetComponent } from './location-list/location-list-widget.component';
import { MaintenanceRegistryDetailsComponent } from './maintenance-registry/maintenance-registry-details.component';
import { MaintenanceRegistryDialogComponent } from './maintenance-registry/maintenance-registry-dialog.component';
import { MaintenanceRegistryWidgetComponent } from './maintenance-registry/maintenance-registry-widget.component';
import { ManualAlertReportingWidgetComponent } from './manual-alert-reporting/manual-alert-reporting-widget.component';
import { ManualEventReportDialogComponent } from './manual-event-report-dialog/manual-event-report-dialog.component';
import { MapFilterComponent } from './map/map-filter/map-filter.component';
import { MapComponent } from './map/map.component';
import { MetricStateDiagramDetailsComponent } from './metric-state-diagram/metric-state-diagram-details.component';
import { MetricStateDiagramComponent } from './metric-state-diagram/metric-state-diagram.component';
import { MicroChartComponent } from './micro-chart/micro-chart.component';
import { MobileListWidgetV2Component } from './mobile-list-widget-v2/mobile-list-widget-v2.component';
import { MultiMetricListComponent } from './multi-metric-list/multi-metric-list.component';
import { PartnerAssignDialogComponent } from './partner-details/partner-assign-dialog.component';
import { PartnerDetailsComponent } from './partner-details/partner-details.component';
import { PartnerListWidgetV2Component } from './partner-list/partner-list-widget-v2.component';
import { PartnerListWidgetComponent } from './partner-list/partner-list-widget.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { ProductModelDetailsComponent } from './product-model-details/product-model-details.component';
import { RadialGaugeWidgetComponent } from './radial-gauge/radial-gauge-widget.component';
import { RadialGaugeComponent } from './radial-gauge/radial-gauge.component';
import { RecipeEditDialogComponent } from './recipe-list/recipe-edit-dialog.component';
import { RecipeListItemComponent } from './recipe-list/recipe-list-item.component';
import { RecipeListComponent } from './recipe-list/recipe-list.component';
import { RecipeParameterEditorComponent } from './recipe-list/recipe-parameter-editor.component';
import { RecipeParameterSelectorComponent } from './recipe-list/recipe-parameter-selector.component';
import { RecipeParameterViewerDialogComponent } from './recipe-list/recipe-parameter-viewer-dialog.component';
import { RegisterThingsComponent } from './register-things/register-things.component';
import { ReportPdfComponent } from './report-pdf/report-pdf.component';
import { SchemaComponent } from './schema/schema.component';
import { ExpandableWidgetDialogComponent } from './shared/expanded-widget-dialog.component';
import { QuickHistoryDialogComponent } from './shared/quick-history-dialog.component';
import { ResetMetricDialogComponent } from './shared/reset-metric-dialog.component';
import { TextDynamicContentComponent } from './shared/text-dynamic-content.component';
import { WidgetDescriptionComponent } from './shared/widget-description.component';
import { CartTableComponent } from './subscription/subscription-cart/cart-table.component';
import { ServiceLevelPaymentDetailsComponent } from './subscription/subscription-cart/service-level-payment-details.component';
import { SubscriptionPaymentsApprovalDialogComponent } from './subscription/subscription-payments-approval-dialog.component';
import { SubscriptionPaymentsDetailsComponent } from './subscription/subscription-payments-details.component';
import { SubscriptionPaymentsComponent } from './subscription/subscription-payments.component';
import { TableWidgetComponent } from './table-widget/table-widget.component';
import { TaskQueueWidgetAddDialogComponent } from './task-queue-widget/task-queue-widget-add-dialog.component';
import { TaskQueueWidgetComponent } from './task-queue-widget/task-queue-widget.component';
import { ThingDetailsWidgetComponent } from './thing-details/thing-details-widget.component';
import { ThingDocumentationElementComponent } from './thing-documentation/thing-documentation-element.component';
import { ThingDocumentationWidgetComponent } from './thing-documentation/thing-documentation-widget.component';
import { ThingFirmwareBlockComponent } from './thing-firmware-list/thing-firmware-block.component';
import { ThingFirmwareListInfoDialogComponent } from './thing-firmware-list/thing-firmware-list-info-dialog.component';
import { ThingFirmwareListComponent } from './thing-firmware-list/thing-firmware-list.component';
import { ThingGridElementComponent } from './thing-grid/thing-grid-element.component';
import { ThingGridWidgetComponent } from './thing-grid/thing-grid-widget.component';
import { ThingListWidgetV2Component } from './thing-list/thing-list-widget-v2.component';
import { ThingListWidgetComponent } from './thing-list/thing-list-widget.component';
import { MarkerComponent } from './thing-map/marker.component';
import { ThingMapAddBulkOperationDialogComponent } from './thing-map/thing-map-add-bulk-operation-dialog.component';
import { ThingMapBulkOperationDialogComponent } from './thing-map/thing-map-bulk-operation-dialog.component';
import { ThingMapBulkOperationScheduleDialogComponent } from './thing-map/thing-map-bulk-operation-schedule-dialog.component';
import { ThingMapChangeParentThingDialogComponent } from './thing-map/thing-map-change-parent-thing-dialog.component';
import { ThingMapSetTagDialogComponent } from './thing-map/thing-map-set-tag-dialog.component';
import { ThingMapComponent } from './thing-map/thing-map.component';
import { ThingTestSessionListWidgetComponent } from './thing-test-session-list/thing-test-session-list-widget.component';
import { ThingTestSessionOutcomeDialogComponent } from './thing-test-session-list/thing-test-session-outcome-dialog.component';
import { ThingTestSessionTokenDialogComponent } from './thing-test-session-list/thing-test-session-token-dialog.component';
import { ThingTestSessionValidationComponent } from './thing-test-session-list/thing-test-session-validation.component';
import { ThingTrendComponent } from './thing-trend/thing-trend.component';
import { ThingTroubleshootingDialogComponent } from './thing-troubleshooting/thing-troubleshooting-dialog.component';
import { ThingTroubleshootingDynamicContentComponent } from './thing-troubleshooting/thing-troubleshooting-dynamic-content.component';
import { ThingTroubleshootingItemComponent } from './thing-troubleshooting/thing-troubleshooting-item.component';
import { ThingTroubleshootingWidgetComponent } from './thing-troubleshooting/thing-troubleshooting-widget.component';
import { TroubleshootingHistoryWidgetComponent } from './troubleshooting-history/troubleshooting-history-widget.component';
import { UserThingAuthorizationModule } from './user-thing-authorization/user-thing-authorization.module';
import { ValueWidgetComponent } from './value-widget/value-widget.component';
import { WearStatusWidgetElementDetailsDialogComponent } from './wear-status-widget/wear-status-widget-element-details-dialog.component';
import { WearStatusWidgetElementDetailsComponent } from './wear-status-widget/wear-status-widget-element-details.component';
import { WearStatusWidgetComponent } from './wear-status-widget/wear-status-widget.component';
import { WistiaComponent } from './wistia/wistia.component';
import { WorkSessionDetailsComponent } from './work-session-details/work-session-details.component';
import { ActiveWorkSessionListWidgetV2Component } from './work-session-list-v2/active-work-session-list-v2/active-work-session-list-widget-v2.component';
import { HistoricalWorkSessionListWidgetV2Component } from './work-session-list-v2/historical-work-session-list-v2/historical-work-session-list-widget-v2.component';
import { ActiveWorkSessionListWidgetComponent } from './work-session-list/active-work-session-list/active-work-session-list-widget.component';
import { WorkSessionBlockComponent } from './work-session-list/active-work-session-list/work-session-block.component';
import { HistoricalWorkSessionWidgetComponent } from './work-session-list/historical-work-session-list/historical-work-session-list-widget.component';
import { YamazumiChartComponent } from './yamazumi-chart/yamazumi-chart.component';

export const WIDGETS = [
    ActivateThingWidgetComponent,
    ActivateThingLocationSelectionDialogComponent,
    ActiveAlertListWidgetComponent,
    AlertBlockComponent,
    ActiveWorkSessionListWidgetComponent,
    AlertCountWidgetComponent,
    BarChartComponent,
    CommandComponent,
    CommandsComponent,
    ConsumptionComponent,
    ConsumptionChartComponent,
    ConsumptionChartDialogComponent,
    CustomerDetailsComponent,
    CustomerListWidgetComponent,
    CustomerTrendComponent,
    ThingListWidgetComponent,
    ThingListWidgetV2Component,
    GaugeWidgetComponent,
    HistoricalAlertWidgetComponent,
    HistoricalWorkSessionWidgetComponent,
    LinkElementComponent,
    LocationListWidgetComponent,
    LocationDetailsComponent,
    ThingDetailsWidgetComponent,
    MarkerComponent,
    MultiMetricListComponent,
    PartnerDetailsComponent,
    PartnerAssignDialogComponent,
    PartnerListWidgetComponent,
    PieChartComponent,
    RadialGaugeComponent,
    RadialGaugeWidgetComponent,
    RegisterThingsComponent,
    ReportPdfComponent,
    SchemaComponent,
    ThingMapComponent,
    ThingMapSetTagDialogComponent,
    ThingMapBulkOperationDialogComponent,
    ThingMapAddBulkOperationDialogComponent,
    ThingTestSessionListWidgetComponent,
    ThingTestSessionValidationComponent,
    ThingTestSessionOutcomeDialogComponent,
    ThingTestSessionTokenDialogComponent,
    ThingTrendComponent,
    ThingTroubleshootingDialogComponent,
    ThingTroubleshootingDynamicContentComponent,
    ThingTroubleshootingItemComponent,
    ThingTroubleshootingWidgetComponent,
    TroubleshootingHistoryWidgetComponent,
    WistiaComponent,
    YamazumiChartComponent,
    ThingDocumentationWidgetComponent,
    ThingDocumentationElementComponent,
    MaintenanceRegistryDetailsComponent,
    MaintenanceRegistryDialogComponent,
    MaintenanceRegistryWidgetComponent,
    ManualAlertReportingWidgetComponent,
    BulkUpdateStatusWidgetComponent,
    BulkUpdateStatusWidgetDetailsComponent,
    CustomerBulkImportWidgetComponent,
    ThingMapChangeParentThingDialogComponent,
    CustomWidgetComponent,
    ThingMapBulkOperationScheduleDialogComponent,
    BulkUpdateStatusWidgetScheduleDialogComponent,
    CustomerListWidgetV2Component,
    MobileListWidgetV2Component,
    PartnerListWidgetV2Component,
    LocationListWidgetV2Component,
    LocationListElementComponent,
    ThingGridWidgetComponent,
    ThingGridElementComponent,
    WorkSessionBlockComponent,
    IncludeTemplateComponent,
    ActionListWidgetComponent,
    ActionGroupListWidgetComponent,
    ActionGroupListDialogComponent,
    ActionGroupListDialogContentComponent,
    TableWidgetComponent,
    ConnectionTestWidgetComponent,
    ConnectionTestListComponent,
    ConnectionTestConfigurationComponent,
    SubscriptionPaymentsComponent,
    SubscriptionPaymentsApprovalDialogComponent,
    SubscriptionPaymentsDetailsComponent,
    CartTableComponent,
    ServiceLevelPaymentDetailsComponent,
    WorkSessionDetailsComponent,
    CompositeWidgetComponent,
    GroupedThingListComponent,
    GroupedThingContentComponent,
    GroupedThingContentTagActionComponent,
    GroupedThingContentTagDialogComponent,
    GroupedActionListComponent,
    GroupedActionContentComponent,
    ActiveAlertListWidgetV2Component,
    EventListWidgetComponent,
    HistoricalAlertListWidgetV2Component,
    AlertBlockV2Component,
    ExportButtonComponent,
    ExportDialogComponent,
    ActionListWidgetReschedulingDialogComponent,
    QuickHistoryDialogComponent,
    ResetMetricDialogComponent,
    MapComponent,
    MapFilterComponent,
    ConnectionTokensDeleteComponent,
    ConnectionTokensEditComponent,
    ConnectionTokensImportComponent,
    ConnectionTokensListComponent,
    ConnectionTokensWidgetComponent,
    DataExportWidgetComponent,
    DataExportWidgetSchedulePageComponent,
    DataExportWidgetAddThingDefinitionDialog,
    DataExportMetricSelectorTableComponent,
    CalendlyWidgetComponent,
    ValueWidgetComponent,
    ActiveWorkSessionListWidgetV2Component,
    HistoricalWorkSessionListWidgetV2Component,
    MetricStateDiagramComponent,
    MetricStateDiagramDetailsComponent,
    WidgetDescriptionComponent,
    BenchmarkComponent,
    MicroChartComponent,
    ProductModelDetailsComponent,
    CatalogWidgetComponent,
    SparePartDefinitionCatalogCardComponent,
    ProductModelTechnicalSchemeSelectorComponent,
    SparePartDefinitionCatalogListComponent,
    AuditEventListWidgetComponent,
    AuditEventDetailComponent,
    AuditEventBlockComponent,
    AuditEventDetailDialogComponent,
    SparePartDefinitionCatalogDetailsDialogComponent,
    CatalogDetailsDialogDynamicContentComponent,
    WearStatusWidgetComponent,
    WearStatusWidgetElementDetailsComponent,
    WearStatusWidgetElementDetailsDialogComponent,
    ActionInfoDialogComponent,
    TextDynamicContentComponent,
    TaskQueueWidgetComponent,
    TaskQueueWidgetAddDialogComponent,
    AddButtonComponent,
    ExpandableWidgetDialogComponent,
    ManualEventReportDialogComponent,
    EventDetailsComponent,
    CollapsibleWidgetComponent,
    BulkOperationDialogComponent,
    BulkOperationSelectionDialogComponent,
    ThingFirmwareListComponent,
    ThingFirmwareListInfoDialogComponent,
    ThingFirmwareBlockComponent,
    ConfigurationParametersComponent,
    ConfigurationParameterEntryComponent,
    ConfigurationParameterFieldComponent,
    RecipeListComponent,
    RecipeListItemComponent,
    RecipeEditDialogComponent,
    RecipeParameterSelectorComponent,
    RecipeParameterEditorComponent,
    RecipeParameterViewerDialogComponent
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        SharedModule,
        DynamicListModule,
        AmChartsModule,
        FormsModule,
        MatButtonToggleModule,
        UserThingAuthorizationModule,
        CKEditorModule,
        MatListModule,
        MatTableModule,
        TagModule,
        EventDetailsPageDialogModule,
        WorkSessionDetailsModule,
        MatTreeModule
    ],
    declarations: [
        WIDGETS
    ],
    exports: [
        WIDGETS
    ]
})
export class WidgetModule { }