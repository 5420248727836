import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from "@angular/core";
import { firstValueFrom } from 'rxjs';
import { HISTORICAL_EVENTS, HISTORICAL_EVENTS_BY_ID, USER_HISTORICAL_EVENTS_V2, USER_THING } from "../../common/endpoints";
import { Properties } from '../../common/properties';
import { Alert, AlertDefinition, PagedList, Thing } from "../../model";
import { AlertDefinitionService } from '../../service/alert-definition.service';
import { AuthenticationService } from '../../service/authentication.service';
import { CustomPropertyService, CustomPropertyType } from '../../service/custom-property.service';
import { HttpService } from '../../service/http.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';
import { PropertyComponent } from '../../shared/component';
import { CustomTableColumn, CustomTableService } from '../../shared/custom-table';
import { AbstractListWidgetV2Service } from '../../shared/list-widget-v2/abstract-list-widget-v2.service';

@Injectable()
export class MaintenanceRegistryWidgetService extends AbstractListWidgetV2Service<Alert> {

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) authenticationService: AuthenticationService,
        @Inject(forwardRef(() => CustomPropertyService)) customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => AlertDefinitionService)) private alertDefinitionService: AlertDefinitionService
    ) {
        super(authenticationService, customPropertyService);
    }

    getThingsByLocationId(locationId: string): Promise<Thing[]> {
        const params = new HttpParams().set('locationId', locationId);
        return firstValueFrom(this.httpService.get<Thing[]>(USER_THING, params));
    }

    protected getPropertyColumn(col: PropertyComponent, columnName: string, defaultType: string): CustomTableColumn {
        switch (col.name) {
            case 'startTimestamp':
                return CustomTableService.newDatetimeColumn(col.name, this.getLabel(col, defaultType), 'startTimestamp', null, this.authenticationService.getUser().timezone).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'endTimestamp':
                return CustomTableService.newDatetimeColumn(col.name, this.getLabel(col, defaultType), 'endTimestamp', null, this.authenticationService.getUser().timezone).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'userEmail':
                return CustomTableService.newSimpleColumn(col.name, this.getLabel(col, defaultType), 'activateUserEmail').withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'duration':
                return CustomTableService.newTimerColumn(col.name, this.getLabel(col, defaultType), 'startTimestamp', 'endTimestamp').withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'type':
                return CustomTableService.newSimpleColumn(columnName, this.getLabel(col, defaultType), 'name').withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'thingName':
                return CustomTableService.newSimpleColumn(columnName, this.getLabel(col, defaultType), 'thing.name',).withColumnClass(col.columnClass).withShowHeader(col.showHeader)
            default:
                return CustomTableService.newPipedColumn(columnName, this.getLabel(col, defaultType), col.name, this.getFilter(col, defaultType))
                    .withValueMap(this.getValueMap(col)).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
        }
    }

    protected isColumnVisible(columnName: string): boolean {
        if (columnName == 'thingName') {
            return !this.thingContextService.getCurrentThing() && !!this.contextService.getCurrentLocation();
        } else {
            return true;
        }
    }

    protected getLabel(col: PropertyComponent, defaultType: string): string {
        if (col.label) return col.label;
        return Properties.getLabelByName(col.name, defaultType) || col.name;
    }

    protected getCustomPropertyNameAndType(columnName: string): { name: string, type: CustomPropertyType } {
        let name = columnName;
        let type = CustomPropertyType.MaintenanceWork;
        name = name.substring(11);
        return { name, type };
    }

    getPagedList(page: number, size: number): Promise<PagedList<Alert>> {
        let params = new HttpParams();
        if (this.thingContextService.getCurrentThing()) {
            params = params.set('thingId', this.thingContextService.getCurrentThing().id);
        } else if (this.contextService.getCurrentLocation()) {
            params = params.set('locationId', this.contextService.getCurrentLocation().id);
        } else if (this.contextService.getCurrentCustomer()) {
            params = params.set('customerId', this.contextService.getCurrentCustomer().id);
        } else if (this.contextService.getCurrentPartner()) {
            params = params.set('partnerId', this.contextService.getCurrentPartner().id);
        }
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        params = params.set('eventSeverity', 5);
        return firstValueFrom(this.httpService.get<PagedList<Alert>>(USER_HISTORICAL_EVENTS_V2, params));
    }

    deleteMaintenanceWork(id: string): Promise<void> {
        return firstValueFrom(this.httpService.delete<void>(HISTORICAL_EVENTS_BY_ID.replace('{id}', id)));
    }

    saveMaintenanceWork(maintenanceWork: Alert, id: string): Promise<Alert> {
        if (id) {
            return firstValueFrom(this.httpService.put<Alert>(HISTORICAL_EVENTS_BY_ID.replace('{id}', id), maintenanceWork));
        } else {
            return firstValueFrom(this.httpService.post<Alert>(HISTORICAL_EVENTS, maintenanceWork));
        }
    }

    getMaintenanceWorkDefinitionsByThingDefinition(thingDefinitionId): Promise<AlertDefinition[]> {
        const params = new HttpParams().set('eventSeverities', 5);
        return this.alertDefinitionService.getAlertDefinitionsByThingDefinitionId(thingDefinitionId, params).then(defs => { return defs.filter(def => def.manualHistoricization) });
    }
}