import { Component, EventEmitter, forwardRef, Inject, Input, Output } from "@angular/core";
import { CustomLabels, CustomLabelService } from "../../../service/custom-label.service";
import { BulkControl } from "../../list-widget-v2/list-widget-v2.components";

@Component({
    selector: 'bulk-control-bar',
    template: require('./bulk-control-bar.component.html'),
    styles: [require('./bulk-control-bar.component.css')]
})
export class BulkControlBar {

    @Input() bulkControls: BulkControl[];

    @Input() showSelectAll: boolean = true;

    @Input() selectedCount: number;

    @Input() allCount: number;

    @Output() performActionEvent = new EventEmitter<string>();

    @Output() clearAllEvent = new EventEmitter();

    @Output() selectAllEvent = new EventEmitter();

    allElementSelected: boolean;
    customLabels: CustomLabels = {};

    constructor(
        @Inject(forwardRef(() => CustomLabelService)) private customLabelService: CustomLabelService
    ) {
        this.customLabelService.getCustomLabels().then(results => this.customLabels = results);
    }

    performBulkAction(action: string): void {
        this.performActionEvent.emit(action);
    }

    clearAll(): void {
        this.clearAllEvent.emit();
    }

    selectAll(): void {
        this.selectAllEvent.emit();
    }

}

