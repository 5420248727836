import { AfterViewChecked, Component, forwardRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { ErrorMessages, Permissions, SAVE_DATA_ERROR } from '../../common/constants';
import { THING_DEFINITIONS, THINGS } from '../../common/endpoints';
import { Location, ProductModel, ProductModelCategory, ThingDefinition, ThingInventoryManagementType } from '../../model';
import { AuthenticationService } from '../../service/authentication.service';
import { FlatTreeNode } from '../../service/flat-tree.service';
import { FormCheckerService } from '../../service/form-checker.service';
import { HttpService } from '../../service/http.service';
import { ProductModelCategoryService } from '../../service/product-model-category.service';
import { ProductModelService } from '../../service/product-model.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { FormChecked } from '../../shared/interface/form-checked.interface';
import { HttpUtility } from '../../utility';
import { ErrorUtility } from '../../utility/error-utility';

@Component({
    selector: 'register-things-widget',
    template: require('./register-things.component.html'),
    styles: [`
        frame-custom-content mat-checkbox ::ng-deep label {
            white-space: unset;
        }

        .mat-select-group-option {
            font-weight: 600;
        }
    `]
})
export class RegisterThingsComponent implements OnInit, FormChecked, OnDestroy, AfterViewChecked {

    @Input() title: string;

    @Input() width: string;

    @Input() description: string;

    @ViewChild('importForm') importForm: NgForm;

    showFields: boolean;
    thingDefinitions: ThingDefinition[] = [];
    error: string;
    readPermission: boolean;
    csvLoaded: boolean;
    importInProgress: boolean;
    multipleRegister: boolean = true;
    serialNumber: string;
    assetId: string;
    location: Location;
    overwriteExistentData: boolean = false;
    csvExample = `serialNumber,name,gpsPosition,properties.<property_name>,mapping.path,mapping.assetId,sharedKey,publicKey,thingDefinitionName\nSerialNumber1,Name1,"1.12345,2.12345",Prop1,Path1,AssetId1,SharedKey1,PublicKey1,ThingDef1\nSerialNumber2,Name2,"10.12345,11.12345",Prop2,Path2,AssetId2,SharedKey2,PublicKey2,ThingDef2`;
    csvExampleWithParentThing = `serialNumber,name,gpsPosition,properties.<property_name>,mapping.path,mapping.assetId,sharedKey,publicKey,thingDefinitionName,parentThingSerialNumber,parentThingName\nSerialNumber1,Name1,"1.12345,2.12345",Prop1,Path1,AssetId1,SharedKey1,PublicKey1,ThingDef1,ParentThingSerialNumber1,ParentThingName1\nSerialNumber2,Name2,"10.12345,11.12345",Prop2,Path2,AssetId2,SharedKey2,PublicKey2,ThingDef2,ParentThingSerialNumber2,ParentThingName2`;
    csvExampleWithThingDefinitionAndProductModel = `serialNumber,name,gpsPosition,properties.<property_name>,mapping.path,mapping.assetId,sharedKey,publicKey,thingDefinitionName,productModelName\nSerialNumber1,Name1,"1.12345,2.12345",Prop1,Path1,AssetId1,SharedKey1,PublicKey1,ThingDef1,Model1\nSerialNumber2,Name2,"10.12345,11.12345",Prop2,Path2,AssetId2,SharedKey2,PublicKey2,ThingDef2,Model2`;
    csvExampleWithProductModel = `serialNumber,name,gpsPosition,properties.<property_name>,mapping.path,mapping.assetId,sharedKey,publicKey,productModelName\nSerialNumber1,Name1,"1.12345,2.12345",Prop1,Path1,AssetId1,SharedKey1,PublicKey1,Model1\nSerialNumber2,Name2,"10.12345,11.12345",Prop2,Path2,AssetId2,SharedKey2,PublicKey2,Model2`;
    initFileInput: boolean;
    thingInventoryManagement: ThingInventoryManagementType;
    visibleProductModels: ProductModel[]
    productModelFlatTree: FlatTreeNode[];

    private csv: File;
    private fileInputs: any;
    private allProductModels: ProductModel[] = [];
    private productModelCategories: ProductModelCategory[];

    static REGISTER_THINGS_FORM_KEY = 'REGISTER_THINGS_FORM_KEY';

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authService: AuthenticationService,
        @Inject(forwardRef(() => FormCheckerService)) private formCheckerService: FormCheckerService,
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => HttpUtility)) private httpUtility: HttpUtility,
        @Inject(forwardRef(() => ProductModelService)) private productModelService: ProductModelService,
        @Inject(forwardRef(() => ProductModelCategoryService)) private productModelCategoryService: ProductModelCategoryService
    ) { }

    loadCsv(event): void {
        const files: FileList = <any>(event.srcElement || event.target).files;
        if (files.length > 0) {
            this.csvLoaded = true;
            this.csv = files.item(0);
        } else {
            this.csvLoaded = false;
            this.csv = null;
        }
    }

    ngOnInit(): void {
        this.title = this.title || 'registerThingsTitle';
        this.readPermission = this.authService.hasPermission(Permissions.REGISTER_THING);
        this.formCheckerService.registerForm(this);
        this.location = this.contextService.getCurrentLocation();
        this.thingInventoryManagement = this.authService.getThingInventoryManagement();
        Promise.all(this.getPromises()).then(() => {
            this.filterVisibleProductModels(null);
            this.buildProductModelFlatTree();
        }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
    }

    private getPromises(): any[] {
        let promises = [];
        promises.push(this.getThingDefinitions().then(definitions => this.thingDefinitions = definitions));
        if (this.thingInventoryManagement == ThingInventoryManagementType.BY_MODEL || this.thingInventoryManagement == ThingInventoryManagementType.BY_THING_DEFINITION_AND_MODEL) {
            promises.push(this.productModelService.getRecursivelyAllProductModels().then(productModels => this.allProductModels = productModels.filter(pm => pm.thingDefinitionId)));
            promises.push(this.productModelCategoryService.getRecursivelyAllProductModelCategories().then(productModelCategories => this.productModelCategories = productModelCategories));
        }
        return promises;
    }

    ngAfterViewChecked(): void {
        if (!this.initFileInput) {
            const opt = {
                buttonBefore: true,
                disabled: false,
                btnClass: "btn-default"
            };
            this.fileInputs = $(':file') as any;
            this.fileInputs.filestyle(opt);
            this.initFileInput = true;
        }
    }

    import(): void {
        this.importInProgress = true;
        const url = THINGS + '/import';
        const context = 'Bulk import things';
        const data = new FormData();
        const rawValues = this.importForm.form.getRawValue();
        if (this.multipleRegister) {
            data.set('file', this.csv, this.csv.name);
            data.set('overwriteExistentData', this.overwriteExistentData + '');
        } else {
            let fileString;
            const mandatoryPropertyColumns = rawValues.properties ? Object.keys(rawValues.properties).map(key => { return ('properties.' + key) }) : [];
            const mandatoryPropertyValues = rawValues.properties ? Object.values(rawValues.properties) : [];
            if (this.location) {
                fileString = ['serialNumber', 'mapping.assetId', ...mandatoryPropertyColumns].join(',') + '\n' + [this.serialNumber, this.assetId, ...mandatoryPropertyValues].join(',');
            } else {
                fileString = ['serialNumber', ...mandatoryPropertyColumns].join(',') + '\n' + [this.serialNumber, ...mandatoryPropertyValues].join(',');
            }
            const file = new Blob([fileString]);
            data.set('file', file, 'single-thing.csv');
            data.set('overwriteExistentData', 'false');
        }
        let thingDef = rawValues.thingDefinitionId;
        if (thingDef) {
            data.set('thingDefinitionId', thingDef);
        }
        if (this.location) {
            data.set('locationId', this.location.id);
        }
        let productModel = rawValues.productModelId;
        if (productModel) {
            data.set('productModelId', productModel);
        }
        firstValueFrom(this.httpService.post(url, data, null, context))
            .then(() => {
                this.error = null;
                this.importInProgress = false;
                this.csv = null;
                this.csvLoaded = false;
                this.fileInputs.filestyle('clear');
                this.showFields = false;
                this.serialNumber = "";
                this.assetId = "";
            })
            .catch((err) => {
                this.error = ErrorUtility.getMessage(err, SAVE_DATA_ERROR);
                this.csv = null;
                this.csvLoaded = false;
                this.fileInputs.filestyle('clear');
                this.importInProgress = false;
            });
    }

    getFormKey(): string {
        return RegisterThingsComponent.REGISTER_THINGS_FORM_KEY;
    }

    isDirty(): boolean {
        if (this.importForm) {
            const rawValues = this.importForm.form.getRawValue();
            return (rawValues.thingDefinitionId || rawValues.productModelId);
        }
        return false;
    }

    resetStatus(): void {
        this.error = null;
        this.showFields = false;
        this.importInProgress = false;
        this.fileInputs.filestyle('clear');
    }

    ngOnDestroy(): void {
        this.formCheckerService.unregisterForm(this.getFormKey());
    }

    getThingDefinitions(): Promise<ThingDefinition[]> {
        return firstValueFrom(this.httpService.get<ThingDefinition[]>(THING_DEFINITIONS));
    }

    isAssetIdRequired(): boolean {
        const rawValues = this.importForm.form.getRawValue();
        let thingDefId = null;
        if (this.thingInventoryManagement == ThingInventoryManagementType.BY_MODEL) {
            if (rawValues.productModelId) {
                const model = this.allProductModels.find(pm => pm.id == rawValues.productModelId)
                thingDefId = model?.thingDefinitionId;
            }
        } else {
            thingDefId = rawValues.thingDefinitionId;
        }
        if (thingDefId) {
            let thingDefinition = this.thingDefinitions.find(td => td.id == thingDefId);
            return !thingDefinition.defaultMappingAssetId;
        }
        return false;
    }

    downloadExampleCsv(): void {
        let blob;
        const rawValues = this.importForm.form.getRawValue();
        if (this.thingInventoryManagement == ThingInventoryManagementType.BY_THING_DEFINITION) {
            blob = new Blob([this.csvExample]);
        } else if (this.thingInventoryManagement == ThingInventoryManagementType.BY_MODEL) {
            blob = new Blob([this.csvExampleWithProductModel])
        } else {
            blob = new Blob([this.csvExampleWithThingDefinitionAndProductModel]);
            let thingDefId = rawValues.thingDefinitionId;
            if (thingDefId) {
                let thingDefinition = this.thingDefinitions.find(td => td.id == thingDefId);
                if (thingDefinition?.parentThingDefinitionId) {
                    blob = new Blob([this.csvExampleWithParentThing]);
                }
            }
        }
        this.httpUtility.wrapFileAndDownload({ file: blob, fileName: "csvExample.csv" });
    }

    filterVisibleProductModels(thingDefId: string): void {
        if ([ThingInventoryManagementType.BY_THING_DEFINITION_AND_MODEL].includes(this.thingInventoryManagement)) {
            this.importForm?.form?.controls['productModelId']?.reset();
            if (thingDefId) {
                this.visibleProductModels = this.allProductModels.filter(pm => pm.thingDefinitionId == thingDefId);
            } else {
                this.visibleProductModels = [];
            }
        } else if ([ThingInventoryManagementType.BY_MODEL].includes(this.thingInventoryManagement)) {
            this.visibleProductModels = this.allProductModels;
        }
    }

    thingDefinitionChanged(thingDefinitionId: string): void {
        this.filterVisibleProductModels(thingDefinitionId);
        this.buildProductModelFlatTree();
    }

    private buildProductModelFlatTree(): void {
        this.productModelFlatTree = this.productModelCategoryService.buildProductModelCategoryWithProductModelFlatTree(this.visibleProductModels, this.productModelCategories);
    }

    getCurrentThingDefinitionId(): string {
        const rawValues = this.importForm?.form?.getRawValue();
        if (rawValues?.productModelId) {
            return this.allProductModels?.find(pm => pm.id == rawValues.productModelId)?.thingDefinitionId;
        } else {
            return rawValues?.thingDefinitionId;
        }
    }
}