import { AfterContentInit, Component, ContentChildren, forwardRef, Host, Inject, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Permissions, WidgetUpdatePolicy } from '../../common/constants';
import { AuthenticationService } from '../../service/authentication.service';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';
import { ModalComponent } from '../../shared/component/modal/modal.component';
import { ConfigurationParameterEntryComponent } from './configuration-parameter-entry.component';
import { ConfigurationParameterFieldComponent } from './configuration-parameter-field.component';
import { ConfigurationParameterDefinition, ConfigurationParametersService, Feedback } from './configuration-parameters.service';

@Component({
    selector: 'configuration-parameters-widget',
    template: require('./configuration-parameters.component.html'),
    providers: [ConfigurationParametersService]
})
export class ConfigurationParametersComponent implements OnDestroy, OnInit, AfterContentInit {

    @ViewChild('confirmUpdateAlert') private confirmUpdateAlert: ModalComponent;

    showLoader: boolean;
    showResult: boolean;
    resultIconClass: string;
    resultIconColor: string;
    disabled: boolean;
    configurationParameters: ConfigurationParameterDefinition[];
    configurationParametersForm: FormGroup;

    private writePermission: boolean;
    private files: { name: string, data: { filename: string, file: File, base64?: string } }[];
    private feedbackSubscription: Subscription;

    @Input() title: string;

    @Input() buttonLabel: string;

    @Input() private timeout: number;

    @Input() updatePolicy: WidgetUpdatePolicy = WidgetUpdatePolicy.FULL;

    @Input() confirmDialog: boolean;

    @Input() confirmDialogMessage: string;

    @Input() description: string;

    @ContentChildren(ConfigurationParameterEntryComponent) configurationParameterEntries: QueryList<ConfigurationParameterEntryComponent>;

    @ViewChildren(ConfigurationParameterFieldComponent) configurationParameterFields: QueryList<ConfigurationParameterFieldComponent>;

    constructor(
        @Inject(forwardRef(() => ConfigurationParametersService)) private configurationParametersService: ConfigurationParametersService,
        @Inject(forwardRef(() => AuthenticationService)) private authService: AuthenticationService,
        @Inject(forwardRef(() => AbstractThingContextService)) @Host() private thingContextService: AbstractThingContextService
    ) { }

    ngAfterContentInit() {
        let thing = this.thingContextService.getCurrentThing();
        this.configurationParametersService.init(thing, this.configurationParameterEntries, this.timeout)
            .then(configurationParameters => this.configurationParameters = configurationParameters)
            .then(() => this.feedbackSubscription = this.configurationParametersService.getFeedback()
                .pipe(filter(v => v != null))
                .subscribe(feedback => {
                    switch (feedback) {
                        case Feedback.KO:
                            this.updateFeedback('red', 'fa-times-circle');
                            break;

                        case Feedback.OK:
                            this.updateFeedback('green', 'fa-check-circle');
                            this.configurationParameterFields.forEach(f => f.fieldUpdated());
                            break;

                        case Feedback.Updating:
                            this.configurationParametersForm.disable();
                            this.showLoader = true;
                            this.disabled = true;
                            break;

                        default:
                            this.showLoader = false;
                            this.showResult = false;
                            this.disabled = !this.writePermission;
                    }
                }));
    }

    ngOnDestroy() {
        this.configurationParametersService.dispose();
        if (this.feedbackSubscription) {
            this.feedbackSubscription.unsubscribe();
            this.feedbackSubscription = null;
        }
    }

    ngOnInit() {
        this.buttonLabel = this.buttonLabel || 'updateButton';
        this.files = [];
        this.configurationParameters = [];
        this.configurationParametersForm = new FormGroup({});
        this.timeout = this.timeout || 30;
        this.writePermission = this.authService.hasPermission(Permissions.SET_THING_PARAMETER);
    }

    onFileUpload(files: { name: string, data: any }[]): void {
        const existingFileIdx = this.files.findIndex(f => f.name == files[0].name);
        if (existingFileIdx >= 0) {
            this.files = this.files.filter(file => file.name !== files[0].name);
        }
        this.files = this.files.concat(files);
    }

    update() {
        const requiredConfigurationParameters = this.configurationParameters.map(cp => cp.configurationParameter).filter(cp => cp.mandatory);
        const invalidConfigurationParameters = requiredConfigurationParameters.filter(cp => {
            const value = this.configurationParametersForm.controls[cp.name].value;
            return value == undefined || value === '';
        });
        if (invalidConfigurationParameters.length > 0) {
            invalidConfigurationParameters.forEach(cp => {
                this.configurationParametersForm.controls[cp.name].setErrors({ 'requiredValidation': 'required' });
            })
        } else {
            this.configurationParametersService.update(this.configurationParameters, this.configurationParametersForm, this.files, this.updatePolicy);
        }
        if (this.confirmDialog) {
            this.closeAlert();
        }

    }


    private updateFeedback(color: string, iconClass: string): void {
        this.configurationParametersService.getEnableConditionValues().forEach(cp => {
            if (cp.enabled) {
                this.configurationParametersForm.controls[cp.name].enable();
            } else {
                this.configurationParametersForm.controls[cp.name].disable();
            }
        })
        this.disabled = !this.writePermission;
        this.showLoader = false;
        this.showResult = true;
        this.resultIconClass = iconClass;
        this.resultIconColor = color;
        setTimeout(() => {
            this.showResult = false
        }, 2000);
    }

    closeAlert(): void {
        this.confirmUpdateAlert.hide();
    }

    confirmUpdate(): void {
        if (this.confirmDialog) {
            this.confirmUpdateAlert.show();
        }
        else {
            this.update();
        }
    }
}