import { AmChartsService } from "@amcharts/amcharts3-angular";
import { AfterViewInit, Component, forwardRef, Host, Inject, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { AbstractThingContextService } from "../../shared/class/abstract-thing-context-service.class";
import { LoaderPipe, LocalizationPipe } from '../../shared/pipe';
import { AmChartComponent } from "../amchart/am-chart.component";
import { YamazumiChartDataset, YamazumiChartService } from './yamazumi-chart.service';

@Component({
    selector: 'yamazumi-chart',
    template: require('./yamazumi-chart.component.html'),
    providers: [YamazumiChartService]
})
export class YamazumiChartComponent extends AmChartComponent implements AfterViewInit, OnDestroy, OnInit {

    @Input('metric') metricName: string;

    @Input('period') interval: string;

    @Input() title: string;

    @Input() colors: string[];

    @Input() styleClass: string;

    @Input() colorFilter: string;

    @Input() width: string;

    @Input() height: string;

    @Input() description: string;

    private dataSubscription: Subscription;
    private chart: any;
    private clearTimeoutId;
    private colorsByName: { [key: string]: string };

    id: string;
    loaded: boolean;
    hasData: boolean;

    static nextId = 0;

    constructor(
        @Inject(forwardRef(() => AbstractThingContextService)) @Host() private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef,
        @Inject(forwardRef(() => YamazumiChartService)) private yamazumiService: YamazumiChartService,
        @Inject(forwardRef(() => AmChartsService)) private amChart: AmChartsService,
        @Inject(forwardRef(() => LoaderPipe)) private loaderPipe: LoaderPipe,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe
    ) { super(); }

    ngAfterViewInit(): void {
        let thing = this.thingContextService.getCurrentThing();
        AmChartComponent.loadResources(this.vcRef).then(() => {
            const daysNumber = this.parsePeriod();
            this.dataSubscription = this.yamazumiService.getData(thing, this.metricName, daysNumber, this.colors, this.colorsByName).subscribe(data => {
                this.loaded = data.loaded;
                if (!this.isEmptyData(data)) {
                    this.hasData = true;
                    this.clearTimeoutId = setTimeout(() => {
                        if (!this.chart) {
                            this.chart = this.amChart.makeChart(this.id, this.getDefaultProp());
                        }
                        this.amChart.updateChart(this.chart, () => {
                            this.chart.dataProvider = data.dataProvider;
                            this.chart.legend.data = data.legend;
                        });
                    }, 0)
                }
            }, err => {
                console.error(err);
            });
        });
    }

    ngOnDestroy(): void {
        this.yamazumiService.dispose();
        if (this.chart) {
            this.amChart.destroyChart(this.chart);
        }
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
            this.dataSubscription = null;
        }
        if (this.clearTimeoutId) {
            clearTimeout(this.clearTimeoutId);
        }
    }

    ngOnInit(): void {
        this.id = 'yamazumi-chart-' + YamazumiChartComponent.nextId++;
        this.width = this.width || '100%';
        this.height = this.height || '500px';
        this.loaded = false;
        this.hasData = false;
        if (this.colorFilter) {
            this.colorsByName = this.loaderPipe.transform(null, this.colorFilter, true);
        }
    }

    private parsePeriod(): number {
        if (this.interval) {
            // TODO: parse input period
            return null;
        } else {
            return 7;
        }
    }

    private getDefaultProp(): any {
        const defaultProp = {
            "type": "gantt",
            "theme": "light",
            "marginRight": 70,
            "columnWidth": 0.5,
            "hideCredits": true,
            "precision": -1,
            "valueAxis": {
                "type": "date",
                "dateFormats": [
                    { period: 'ss', format: 'JJ:NN:SS' },
                    { period: 'mm', format: 'JJ:NN' },
                    { period: 'hh', format: 'JJ:NN' },
                    { period: 'DD', format: 'JJ:NN' },
                ]
            },
            "graph": {
                "lineAlpha": 1,
                "lineColor": "#fff",
                "fillAlphas": 0.85,
                "balloonText": "[[task]]|[[start]]|[[end]]",
                "balloonFunction": this.loadBalloonValueFunction()
            },
            "legend": {
                "data": []
            },
            "rotate": true,
            "categoryField": "day",
            "segmentsField": "values",
            "colorField": "color",
            "startDateField": "start",
            "endDateField": "end",
            "dataProvider": [],
            "valueScrollbar": {
                "autoGridCount": false
            },
            "chartCursor": {
                "cursorColor": "#55bb76",
                "valueBalloonsEnabled": false,
                "cursorAlpha": 0,
                "valueLineAlpha": 0.5,
                "valueLineBalloonEnabled": true,
                "valueLineEnabled": true,
                "zoomable": false,
                "valueZoomable": true
            },
            "export": {
                "enabled": false
            },
            "path": "https://www.amcharts.com/lib/3"
        };

        const prop = Object.assign({}, defaultProp, this.getChartProps());
        return prop;
    }

    private loadBalloonValueFunction(): Function {
        return (item, graph) => {
            var task = this.localizationPipe.transform(graph.segmentData.task);
            var start = moment(graph.segmentData.start).format('HH:mm');
            var end = moment(graph.segmentData.end).format('HH:mm');
            return '<b>' + task + '</b><br>' + start + ' - ' + end;
        }
    }

    private isEmptyData(data: YamazumiChartDataset): boolean {
        if (!data || !data.dataProvider || !data.dataProvider.length) {
            return true;
        }
        let empty = true;
        for (let entry of data.dataProvider) {
            if (entry.values && entry.values.length) {
                empty = false;
                break;
            }
        }
        return empty;
    }
}