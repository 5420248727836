import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from "@angular/core";
import { DateRange } from '@angular/material/datepicker';
import * as _ from 'lodash';
import * as moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { ACTION_COUNT_STATISTIC, STATISTICS, USER_ME_STATISTICS_V2 } from "../common/endpoints";
import { StatisticItem, StatisticType, Thing } from "../model";
import { QueryItem, StatisticComponent } from "../shared/component";
import { CapitalizePipe } from '../shared/pipe';
import { ObjectUtility } from '../utility';
import { PeriodRange } from '../widget/gauge/gauge-widget.component';
import { ContextService } from './context.service';
import { DateRangeName, DateRangeService, PeriodVariable } from './date-range.service';
import { HttpService } from "./http.service";
import { IncrementService } from './increment.service';

@Injectable()
export class StatisticService {

    static PERIOD_GROUP_BY_LIST = [
        'HOUR',
        'DAY',
        'MONTH',
        'YEAR'
    ]

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => IncrementService)) private incrementService: IncrementService,
        @Inject(forwardRef(() => DateRangeService)) private dateRangeService: DateRangeService,
        @Inject(forwardRef(() => CapitalizePipe)) private capitalizePipe: CapitalizePipe,
        @Inject(forwardRef(() => ContextService)) private contextService: ContextService,
        @Inject(forwardRef(() => ObjectUtility)) private objectUtility: ObjectUtility
    ) { }

    getStatisticValue(statistic: StatisticComponent, fieldsMap: { [field: string]: string }, thing?: Thing, queryObj?: object, period?: string): Promise<StatisticItem[]> {
        let params = this.getStatisticParams(statistic, fieldsMap, thing, queryObj, period);
        return this.getStatisticPromise(statistic.name, params);
    }

    private getStatisticParams(statistic: StatisticComponent, fieldsMap: { [field: string]: any }, thing?: Thing, queryObj?: object, period?: string): HttpParams {
        let params = new HttpParams();
        if (queryObj) {
            params = this.getThingFilterParams(queryObj);
        }
        params = this.setContextParams(statistic, thing, params);
        if (statistic.thingDefinition) {
            params = this.setIfNotDeprecated(statistic, params, 'thingDefinition', 'thingDefinitionName');
        }
        if (statistic.limit > 0) {
            params = params.set('limit', statistic.limit + "");
        }
        if (statistic.includeZeroValues) {
            params = params.set('includeZeroValues', 'true');
        }
        if (statistic.category) {
            params = this.setIfNotDeprecated(statistic, params, 'category');
        }
        if (statistic.severity) {
            params = this.setIfNotDeprecated(statistic, params, 'severity');
        }
        if (statistic.sumMetric) {
            params = this.setIfNotDeprecated(statistic, params, 'sumMetric');
        }
        if (statistic.names) {
            params = this.setIfNotDeprecated(statistic, params, 'names', 'name');
        }
        const startParam = statistic.resource ? 'periodStart' : 'startDate';
        const endParam = statistic.resource ? 'periodEnd' : 'endDate';
        if (period) {
            let range: DateRange<moment.Moment>;
            if (period == PeriodRange.YEAR_TO_DATE) {
                range = this.dateRangeService.getCustomDateRangeByName(DateRangeName.THIS_YEAR).range;
            } else if (period == PeriodRange.MONTH_TO_DATE) {
                range = this.dateRangeService.getCustomDateRangeByName(DateRangeName.THIS_MONTH).range;
            } else if (period == PeriodRange.LAST_24H) {
                range = this.dateRangeService.getCustomDateRangeByName(DateRangeName.LAST_24_HOURS).range;
            } else {
                range = this.dateRangeService.getCustomDateRangeByName(period).range;
            }
            params = params.set(startParam, range.start.valueOf());
            params = params.set(endParam, range.end.valueOf());
        } else if (statistic.startDateFieldRef || statistic.endDateFieldRef) {
            if (statistic.startDateFieldRef && fieldsMap[statistic.startDateFieldRef]) {
                params = params.set(startParam, fieldsMap[statistic.startDateFieldRef]);
            }
            if (statistic.endDateFieldRef && fieldsMap[statistic.endDateFieldRef]) {
                params = params.set(endParam, fieldsMap[statistic.endDateFieldRef]);
            }
        } else if (statistic.periodRef) {
            const periodVariable: PeriodVariable = fieldsMap[statistic.periodRef];
            if (periodVariable && periodVariable.start) {
                params = params.set(startParam, periodVariable.start);
            }
            if (periodVariable && periodVariable.end) {
                params = params.set(endParam, periodVariable.end);
            }
        }
        if (statistic.aggregation) {
            params = params.set('aggregation', statistic.aggregation);
        }
        if (statistic.property) {
            params = params.set('property', statistic.property);
        }
        if (statistic.groupBy) {
            let groupBys = this.getGroupBy(statistic, params);
            groupBys.forEach(gb => params = params.append('groupBy', gb));
        }
        if (statistic.query) {
            params = this.addQueryParams(params, statistic.query, !!statistic.resource);
        }
        if (statistic.resource) {
            params = params.set('resource', this.objectUtility.toCamelCase(statistic.resource.toLowerCase()));
        }
        if (statistic.activationType) {
            params = params.set('activationType', statistic.activationType);
        }
        if (statistic.sortDirection) {
            params = params.set('sortDirection', statistic.sortDirection);
        }
        if (statistic.averagedBy) {
            params = params.set('averagedBy', statistic.averagedBy);
        }
        return params;
    }

    private setIfNotDeprecated(statistic: StatisticComponent, params: HttpParams, inputName: string, paramName?: string) {
        if (statistic.resource != null) {
            console.error("Invalid statistic input: " + inputName);
        } else {
            if (statistic[inputName] instanceof Array) {
                statistic[inputName].forEach(input => params = params.append(paramName || inputName, input));
            } else {
                params = params.set(paramName || inputName, statistic[inputName]);
            }
        }
        return params;
    }

    getGroupBy(statistic: StatisticComponent, params: HttpParams): string[] {
        let groupBys: string[] = [];
        const periodGroupBy = statistic.groupBy.find(el => StatisticService.PERIOD_GROUP_BY_LIST.indexOf(el) > -1);
        if ((params.get("startDate") || params.get("endDate")) && periodGroupBy) {
            if (!params.get("endDate")) {
                params = params.set("endDate", new Date().getTime().toString());
            }
            if (params.get("startDate") && params.get("endDate")) {
                const differenceInDays = this.getDifferenceInDays(params.get("endDate"), params.get("startDate"));
                if (differenceInDays < 1) {
                    groupBys.push('HOUR');
                } else if (differenceInDays < 30) {
                    groupBys.push('DAY');
                } else {
                    groupBys.push('MONTH');
                }
            } else {
                groupBys.push('DAY');
            }
        } else if (periodGroupBy) {
            groupBys.push(periodGroupBy);
        }
        let groupByWithoutPeriod = statistic.groupBy.filter(el => !StatisticService.PERIOD_GROUP_BY_LIST.find(period => period == el));
        if (groupByWithoutPeriod.length > 0) {
            groupByWithoutPeriod.forEach(gb => groupBys.push(gb));
        }
        return groupBys;
    }

    private getDifferenceInDays(endTimestamp: string, startTimestamp: string): number {
        let differenceInTime = parseInt(endTimestamp) - parseInt(startTimestamp);
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);
        return differenceInDays;
    }

    private setContextParams(statistic: StatisticComponent, thing?: Thing, params?: HttpParams): HttpParams {
        if (!params) {
            params = new HttpParams();
        }
        if (thing) {
            params = params.append('thingId', thing.id);
        } else {
            if (statistic.location) {
                params = params.append('locationId', statistic.location.id);
            } else if (statistic.customer) {
                params = params.append('customerId', statistic.customer.id);
            } else if (statistic.partner) {
                params = params.append('partnerId', statistic.partner.id);
            }
        }
        return params;
    }

    addQueryParams(params: HttpParams, query: QueryItem[], hasResource: boolean): HttpParams {
        for (let queryItem of query) {
            let predicate = (queryItem.predicate || 'eq').toLowerCase();
            let value = (queryItem.value) || '';
            let property = queryItem.property;
            if (!property) {
                continue;
            }
            if (['thingDefinitionId', 'state', 'topic', 'priority', 'actionDefinitionId'].includes(property) && !hasResource) {
                if (Array.isArray(value)) {
                    value.forEach(v => params = params.append(property, v));
                } else {
                    params = params.append(property, value);
                }
            } else if (['assigned', 'unassigned'].includes(property)) {
                if (Array.isArray(value)) {
                    continue;
                }
                params = params.append(property, value);
            } else if (property == 'tags') {
                if (Array.isArray(value)) {
                    const tags = this.contextService.getTagObjects();
                    const tagIds = value.map(tag => tags.find(t => t.name == tag)?.id).filter(t => t);
                    tagIds.forEach(v => params = params.append('tagId', v));
                } else {
                    continue;
                }
            } else {
                switch (predicate) {
                    case "eq":
                    case "ne":
                    case "bt":
                        let paramValue = predicate + ';' + (Array.isArray(value) ? (value as string[]).join(',') : (value as string));
                        params = params.append(property, paramValue);
                        break;
                    case "isempty":
                        params = params.append(property, 'eq;');
                        break;
                    case "isnotempty":
                        params = params.append(property, 'ne;');
                        break;
                    case "contains":
                        let containsValue = 'like;' + (Array.isArray(value) ? (value as string[]).map(v => '*' + v + '*').join(',') : ('*' + value + '*'));
                        params = params.append(property, containsValue);
                        break;
                    case "notcontains":
                        let notContainsValue = 'notlike;' + (Array.isArray(value) ? (value as string[]).map(v => '*' + v + '*').join(',') : ('*' + value + '*'));
                        params = params.append(property, notContainsValue);
                        break;
                    case "beginswith":
                        let beginsWithValue = 'like;' + (Array.isArray(value) ? (value as string[]).map(v => v + '*').join(',') : (value + '*'));
                        params = params.append(property, beginsWithValue);
                        break;
                    case "endswith":
                        let endsWithValue = 'like;' + (Array.isArray(value) ? (value as string[]).map(v => '*' + v).join(',') : ('*' + value));
                        params = params.append(property, endsWithValue);
                        break;
                    case "gt":
                    case "gte":
                    case "lt":
                    case "lte":
                        if (Array.isArray(value)) {
                            break;
                        }
                        params = params.append(property, predicate + ';' + value);
                        break;
                    default:
                        params = params.append(property, predicate + ';' + value);
                        break;
                }
            }
        }
        return params;
    }

    getStatisticLabel(statistic: StatisticComponent): string {
        if (statistic.label) {
            return statistic.label;
        } else if (statistic.resource) {
            return this.capitalizePipe.transform(statistic.resource) + " Count";
        }
        switch (statistic.name) {
            case StatisticType.ALERT_COUNT:
                return 'Alert Count';
            case StatisticType.ACTIVE_ALERT_COUNT:
                return 'Active Alert Count';
            case StatisticType.ACTIVATED_ALERT_COUNT:
                return 'Activated Alert Count';
            case StatisticType.WORK_SESSION_COUNT:
                return 'Work Session Count';
            case StatisticType.WORK_SESSION_SUM_METRIC_VALUES:
                return 'Work Session Sum Metric Values';
            case StatisticType.WORK_SESSION_ELAPSED_TIME:
                return 'Work Session Elapsed Time';
            default:
                return statistic.name;
        }
    }

    sortStatisticItems(statisticItems: any, hasPeriodGroupBy: boolean, statisticComponent: StatisticComponent): StatisticItem[] {
        if (statisticComponent.limit > 0 || statisticComponent.sortDirection) {
            return statisticItems;
        }
        let sortedStatisticItems;
        if (statisticItems && statisticItems.length) {
            if (statisticItems.some(item => item.value instanceof Array)) {
                statisticItems.forEach(item => {
                    item.value = item.value.sort(this.sortByValueOrCategory);
                });
                sortedStatisticItems = statisticItems.sort(hasPeriodGroupBy ? this.sortByDate : (a, b) => a.category > b.category ? 1 : -1);
            } else {
                sortedStatisticItems = statisticItems.sort(hasPeriodGroupBy ? this.sortByDate : this.sortByValueOrCategory);
            }
        }
        return sortedStatisticItems;
    }

    private sortByValueOrCategory(a: any, b: any): number {
        if (a.value != b.value) {
            return (b.value - a.value);
        } else {
            return a.category > b.category ? 1 : -1;
        }
    }

    private sortByDate(a: any, b: any): number {
        return <any>new Date(a.category) - <any>new Date(b.category);
    }

    getThingFilterParams(queryObj: object, params?: HttpParams): HttpParams {
        if (!params) {
            params = new HttpParams();
        }
        let mappedFields = { 'thingDefinitions': 'thingDefinitionId', 'productModels': 'productModelId', 'serviceLevels': 'serviceLevelId', 'selectedThingIds': 'selectedThingId' };
        for (let key in queryObj) {
            if (Object.keys(mappedFields).indexOf(key) > -1) {
                let values: string[] = queryObj[key];
                if (values) {
                    values.forEach(td => params = params.append(mappedFields[key], td));
                }
            } else if (key == "customer") {
                if (queryObj[key]) {
                    params = params.append("customerId", queryObj[key]);
                }
            } else if (key == "tags") {
                let values: string[] = queryObj[key];
                if (values && Array.isArray(values)) {
                    const tags = this.contextService.getTagObjects();
                    const tagIds = values.map(tag => tags.find(t => t.id == tag)?.id).filter(t => t);
                    tagIds.forEach(v => params = params.append('tagId', v));
                }
            } else {
                if (queryObj[key]) {
                    params = params.append(key, queryObj[key]);
                }
            }
        }
        return params;
    }

    getStatisticValueWithIncrement(statistic: StatisticComponent, fieldsMap: { [field: string]: string }, thing?: Thing, queryObj?: object): Promise<StatisticItem[][]> {
        let params = this.getStatisticParams(statistic, fieldsMap, thing, queryObj);
        if (!params.get("startDate")) {
            params = params.set('startDate', moment().subtract(7, 'days').valueOf().toString());
        }
        let incrementParams = _.cloneDeep(params);
        incrementParams = this.incrementService.setIncrementParams(incrementParams);
        let promises = [];
        promises.push(this.getStatisticPromise(statistic.name, params));
        promises.push(this.getStatisticPromise(statistic.name, incrementParams));
        return Promise.all(promises).then(results => {
            return results;
        });
    }

    getStatisticPromise(statisticName: string, params: HttpParams): Promise<StatisticItem[]> {
        if (statisticName == "actionCount") {
            return firstValueFrom(this.httpService.get<StatisticItem[]>(ACTION_COUNT_STATISTIC, params));
        } else if (statisticName) {
            return firstValueFrom(this.httpService.get<StatisticItem[]>(STATISTICS.replace("{name}", statisticName), params));
        } else {
            return firstValueFrom(this.httpService.get<StatisticItem[]>(USER_ME_STATISTICS_V2, params));
        }
    }

    getStatisticPeriods(statistic: StatisticComponent, fieldsMap: { [field: string]: string }, thing?: Thing, queryObj?: object, period?: string): string[] {
        let params = this.getStatisticParams(statistic, fieldsMap, thing, queryObj, period);
        if (!params.get("startDate")) {
            params = params.set('startDate', moment().subtract(7, 'days').valueOf().toString());
        }
        let incrementParams = _.cloneDeep(params);
        incrementParams = this.incrementService.setIncrementParams(incrementParams);
        const valuePeriod = this.dateRangeService.getPeriod(parseInt(params.get('startDate')), params.get("endDate") ? parseInt(params.get('endDate')) : moment().valueOf());
        const incrementPeriod = this.dateRangeService.getPeriod(parseInt(incrementParams.get('startDate')), parseInt(incrementParams.get('endDate')));
        return [valuePeriod, incrementPeriod];
    }

    getUserStatisticsV2(params: HttpParams): Promise<StatisticItem[]> {
        return firstValueFrom(this.httpService.get<StatisticItem[]>(USER_ME_STATISTICS_V2, params));
    }

}