import { HttpParams } from "@angular/common/http";
import { Component, forwardRef, Inject, OnInit } from "@angular/core";
import { Customer } from "../../../model";
import { AuthenticationService } from "../../../service/authentication.service";
import { CustomLabelService } from "../../../service/custom-label.service";
import { CustomPropertyService } from "../../../service/custom-property.service";
import { UserCustomerService } from "../../../service/user-customer.service";
import { AbstractContextService } from "../../../shared/class/abstract-context-service.class";
import { SearchFieldService } from "../search-field.service";
import { AbstractPropertySearchInputComponent } from "../search-input/abstract-property-search-input.component";

@Component({
    selector: 'locations-property-search-input',
    template: require('./locations-property-search-input.component.html'),
    providers: [SearchFieldService]
})
export class LocationsPropertySearchInputComponent extends AbstractPropertySearchInputComponent implements OnInit {

    private addCustomerSearchField: boolean;

    constructor(
        @Inject(forwardRef(() => CustomPropertyService)) customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => CustomLabelService)) customLabelService: CustomLabelService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => UserCustomerService)) private userCustomerService: UserCustomerService,
        @Inject(forwardRef(() => SearchFieldService)) protected searchFieldService: SearchFieldService
    ) {
        super(customPropertyService, customLabelService, searchFieldService);
    }

    initConfiguration(): void {
        const customerId = this.authenticationService.getUser().customerId || (this.contextService.getCurrentCustomer() ? this.contextService.getCurrentCustomer().id : null);
        this.addCustomerSearchField = (this.authenticationService.isOrganizationUser() || this.authenticationService.isPartnerUser()) && !customerId;
        if (this.property?.indexOf('properties.') > -1 || this.defaultProperties.some(prop => prop.name == this.property)) {
            // MORE FILTERS NOT SUPPORTED
        } else {
            this.useDefaultProperty()
        }
    }

    protected handleDefaultProperty(): void {
        if (this.property == "customer" && this.addCustomerSearchField) {
            this.handleCustomer();
        }
    }

    private handleCustomer(): void {
        let partnerParam = null;
        if (this.contextService.getCurrentPartner()) {
            partnerParam = new HttpParams().set('partnerId', this.contextService.getCurrentPartner().id);
        }
        this.userCustomerService.getRecursivelyAllCustomers(null, null, partnerParam).then(customers => {
            const customerTypes = customers.map((c: Customer) => { return { value: c.id, label: c.name } });
            if (customerTypes?.length) {
                this.advancedSearchConfiguration = [{ name: 'customer', placeholder: this.customLabels && this.customLabels['customerProperty'] ? this.customLabels['customerProperty'] : this.property, type: 'STRING', selectionMode: 'MAT_SELECTION', values: customerTypes, value: this.searchFieldService.getValueFromFieldsValues('customer', this.savedFieldsValues), hideLabel: true }];
            }
        }).catch(err => console.log(err));
    }

    protected handleValue(body: any, isEncoded: boolean): any {
        return body;
    }

}