import { Directive, forwardRef, Input } from '@angular/core';
import { ColumnComponentDisplayMode } from '../../list-widget-v2/list-widget-v2.components';
import { COMPONENT_DEFINITION_REF } from "../../utility/component-definition-token";

@Directive({
    selector: 'property',
    providers: [{ provide: COMPONENT_DEFINITION_REF, useExisting: forwardRef(() => PropertyComponent) }]
})
export class PropertyComponent {

    @Input() id: string;

    @Input() name: string;

    @Input() label: string;

    @Input() filter: string | Function;

    @Input() sorting: string;

    @Input() showLabel: boolean = true;

    @Input() x: any;

    @Input() y: any;

    @Input() showHeader: boolean = true;

    @Input() useDefaultNullValue: boolean;

    @Input() exportOnly: boolean;

    @Input() description: string;

    @Input() config: any;

    @Input() columnClass: string;

    @Input() displayMode: ColumnComponentDisplayMode = ColumnComponentDisplayMode.VISIBLE;

    @Input() includeInExport: boolean;

    private columnName: string;

    getTemplateInputMap(): any {
        return { label: this.label, description: this.description, config: this.config };
    }

    setColumnName(columnName: string): void {
        this.columnName = columnName;
    }

    getColumnName(): string {
        return this.columnName;
    }
}