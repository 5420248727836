import { AfterContentInit, Component, ContentChildren, EventEmitter, forwardRef, Inject, OnInit, Output, QueryList } from '@angular/core';
import { Permissions } from '../../common/constants';
import { PropertyInfo } from '../../common/properties';
import { ThingConnectionToken } from '../../model/thing-connection-token';
import { AbstractExportContextService } from '../../service/abstract-export-context.service';
import { AppService } from '../../service/app.service';
import { AuthenticationService } from '../../service/authentication.service';
import { AdvancedSearchLayoutType, ListWidgetV2Component } from '../../shared/list-widget-v2/list-widget-v2.components';
import { DatetimeFormatterPipe } from '../../shared/pipe';
import { SearchTargetType } from '../../shared/search-field/search-field.component';
import { COMPONENT_DEFINITION_REF } from '../../shared/utility/component-definition-token';
import { ErrorUtility } from '../../utility/error-utility';
import { ConnectionTokensListService } from './connection-tokens-list.service';


@Component({
    selector: 'connection-tokens-list',
    template: require('./connection-tokens-list.component.html'),
    providers: [ConnectionTokensListService]
})
export class ConnectionTokensListComponent extends ListWidgetV2Component<ThingConnectionToken> implements OnInit, AfterContentInit {

    error: string;
    thingConnectionTokens: ThingConnectionToken[] = [];

    private searchFields: string[] = ['token', 'description', 'serialNumber', 'assetId'];

    @Output() editAction: EventEmitter<string> = new EventEmitter();

    @Output() importAction: EventEmitter<void> = new EventEmitter();

    @ContentChildren(COMPONENT_DEFINITION_REF) private columnComponents: QueryList<any>;

    private defaultProperties: { [name: string]: PropertyInfo } = {
        token: { label: 'tokenProperty', path: 'token', defaultFilter: null, defaultSorting: null },
        timestamp: { label: 'dateProperty', path: 'timestamp', defaultFilter: DatetimeFormatterPipe, defaultSorting: null },
        description: { label: 'descriptionProperty', path: 'description', defaultFilter: null, defaultSorting: null },
        serialNumber: { label: 'serialNumberProperty', path: 'serialNumber', defaultFilter: null, defaultSorting: null },
        assetId: { label: 'connectionMappingAssetIdProperty', path: 'assetId', defaultFilter: null, defaultSorting: null }
    };

    constructor(
        @Inject(forwardRef(() => ConnectionTokensListService)) private thingConnectionTokensListService: ConnectionTokensListService,
        @Inject(forwardRef(() => AbstractExportContextService)) exportService: AbstractExportContextService,
        @Inject(forwardRef(() => AuthenticationService)) authenticationService: AuthenticationService,
        @Inject(forwardRef(() => AppService)) appService: AppService
    ) {
        super(appService, authenticationService, exportService, null, null, null);
    }

    ngOnInit() {
        this.checkIsMobile();
        this.readPermission = this.authenticationService.hasPermission(Permissions.READ_THING_CONNECTION_TOKENS);
        this.writePermission = this.authenticationService.hasPermission(Permissions.WRITE_THING_CONNECTION_TOKENS);
        this.showAddButton = this.writePermission;
        this.handleAdvancedSearchLayoutType("connection-tokens-list-popup-advanced-search", SearchTargetType.THING_CONNECTION_TOKENS);
        this.exportEnabled = false;
    }

    ngAfterContentInit(): void {
        this.displayedColumns = this.thingConnectionTokensListService.getVisibleColumns(this.columnComponents.toArray(), this.defaultProperties, 'Thing Connection Token');
        this.descriptions = this.thingConnectionTokensListService.getColumnDescriptions(this.columnComponents.toArray());
        if (!this.sort) {
            this.sort = this.thingConnectionTokensListService.setDefaultSort(this.displayedColumns);
        }
        const storedFieldsValues = localStorage.getItem(this.queryFieldRef || 'connectionTokenAdvancedSearchFieldsValues');
        const savedFieldsValues = storedFieldsValues ? JSON.parse(storedFieldsValues) : null;
        if (!savedFieldsValues && !this.query && !this.queryFieldRef && this.advancedSearchLayout != AdvancedSearchLayoutType.POPUP) {
            this.getThingConnectionTokenList();
        }
    }

    protected goToDetail(element: ThingConnectionToken): void {
        this.editAction.emit(element.id);
    }

    protected refreshList(data?: { pageIndex: number, pageSize: number, advancedSearchBody: any, sort: string[] }): void {
        if (data) {
            this.pageIndex = data.pageIndex;
            this.pageSize = data.pageSize;
            this.advancedSearchBody = data.advancedSearchBody;
            this.sort = data.sort;
        }
        this.getThingConnectionTokenList();
    }

    protected subscribeToExportServices(): void {
        // do nothing, export not supported
    }

    importConnectionToken(): void {
        this.importAction.emit();
    }

    private getThingConnectionTokenList(): void {
        this.thingConnectionTokensListService.getPagedList(this.pageIndex, this.pageSize, this.sort, null, this.searchFields, this.advancedSearchBody, null, null, null, null, null, null).then(pagedList => {
            this.updateElementList(pagedList);
            this.error = null;
        }).catch(err => this.error = ErrorUtility.getMessage(err));
    }
}