import { AfterViewInit, Component, ContentChild, forwardRef, Inject, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { AlertSeverityOptions, ErrorMessages, Permissions } from '../../common/constants';
import { BulkUpdateParentThingsResponse } from '../../model/bulk-update-parent-things-response';
import { BulkUpdateResourcesResponseItem } from '../../model/bulk-update-resource-response-item';
import { Thing, Value } from '../../model/index';
import { AuthenticationService } from '../../service/authentication.service';
import { FieldService } from '../../service/field.service';
import { NavigationService } from '../../service/navigation.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { MessageComponent } from '../../shared/component';
import { BulkUpdateTagDialogComponent, BulkUpdateTagDialogData } from '../../shared/component/bulk-update-tag-dialog/bulk-update-tag-dialog.component';
import { LoaderPipe } from '../../shared/pipe';
import { ErrorUtility } from '../../utility/error-utility';
import { MarkerComponent, MarkerValue } from './marker.component';
import { ThingMapAddBulkOperationDialogComponent } from './thing-map-add-bulk-operation-dialog.component';
import { ThingMapBulkOperationDialogComponent } from './thing-map-bulk-operation-dialog.component';
import { ThingMapChangeParentThingDialogComponent } from './thing-map-change-parent-thing-dialog.component';
import { ThingMapService } from './thing-map.service';

require("../map/Leaflet.SelectAreaFeature.js");
require("../../../../node_modules/leaflet/dist/leaflet.css");
require("../../../../node_modules/leaflet.markercluster/dist/MarkerCluster.css");
require("../../../../node_modules/leaflet.markercluster/dist/MarkerCluster.Default.css");

const DEFAULT_HEIGHT = 600;
let thingMapComponentId = 0;

class Coordinates {
    lat: number;
    lng: number;
};

class Bounds {
    ne: Coordinates;
    sw: Coordinates;
}

@Component({
    selector: 'thing-map-widget',
    template: require('./thing-map.component.html'),
    styles: [require('./thing-map.component.css')],
    providers: [ThingMapService]
})
export class ThingMapComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input() height: string;

    @Input() mapOptions: any;

    @Input() styleClass: string;

    @Input() title: string;

    @Input() defaultMarkerUrl: string;

    @Input() warningMarkerUrl: string;

    @Input() failureMarkerUrl: string;

    @Input() selectedMarkerUrl: string;

    @Input() layerFilter: string;

    @Input() includeAlertSeverity: boolean = true;

    @Input() searchFields: string[] = ["name", "serialNumber", "customer.name", "customer.code"];

    @Input() query: { property: string, predicate: string, value: any }[];

    @Input() queryFieldRef: string;

    @Input() refreshInterval: string = 'PT300S'; // 300 secs (5 min) default

    @Input() description: string;

    @ContentChild(MarkerComponent) marker: MarkerComponent;

    @ViewChild(ThingMapBulkOperationDialogComponent) bulkOperationDialog: ThingMapBulkOperationDialogComponent;

    @ViewChild(ThingMapAddBulkOperationDialogComponent) addBulkOperationDialog: ThingMapAddBulkOperationDialogComponent;

    @ViewChild(ThingMapChangeParentThingDialogComponent) setParentThingDialog: ThingMapChangeParentThingDialogComponent;

    @ViewChild('saveMessage') saveMessage: MessageComponent;

    private bounds: Bounds;
    private initMap: boolean;
    private fitBounds: boolean;
    private map: any;
    private markers: { icon: any, thing: Thing }[];
    private markerCoordinates: string[];
    private filter: string;
    private thingValueMapping: { [thingId: string]: Value | MarkerValue } = {};
    private latLngAreaArray: any[][] = [];
    private latLngMarkersArray: any[] = [];
    private selectFeature: any;
    private isIconSelectable: boolean;
    private updateMarkersIcon: boolean;
    private userCustomerId: string;
    private advancedSearchBody: any;
    private recipeByAreaResponseItems: BulkUpdateResourcesResponseItem[] = [];
    private commandsByAreaResponseItems: BulkUpdateResourcesResponseItem[] = [];
    private selectAreaFeatureLength: number = 0;
    private selectedThingIds: string[] = [];
    private thingIdsInsideArea: string[] = [];
    private things: Thing[] = [];
    private thingQueryFieldSubInit: boolean;
    private refreshIntervalMillis: number;
    private intervalId: any;
    private thingIdMapping: { [thingId: string]: Thing } = {};
    private fieldServiceSubscription: Subscription;

    loading: boolean;
    noMarkerMessage: string;
    id: string;
    selectAreaFeature: boolean;
    writePermission: boolean;
    setTagPermission: boolean;
    bulkCommandPermission: boolean;
    bulkRecipePermission: boolean;
    error: string;
    message: string;
    firstInit: boolean;
    isAdvancedSearchOpen: boolean;
    bulkUpdateParentThingsResponse: BulkUpdateParentThingsResponse;
    setParentThingVisible: boolean;

    static defaultMarkerIcon = '/img/ok-marker.png';
    static warningMarkerIcon = '/img/warning-marker.png';
    static failureMarkerIcon = '/img/failure-marker.png';
    static selectedMarkerIcon = '/img/marker.png';
    static iconSize = [24, 24];

    markerCluster = new L.MarkerClusterGroup();

    constructor(
        @Inject(forwardRef(() => ThingMapService)) private thingMapService: ThingMapService,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => LoaderPipe)) private loaderPipe: LoaderPipe,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => FieldService)) private fieldService: FieldService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone,
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog
    ) { }

    ngAfterViewInit(): void {
        const storedFieldsValues = localStorage.getItem('thingAdvancedSearchFieldsValues');
        const savedFieldsValues = storedFieldsValues ? JSON.parse(storedFieldsValues) : null;
        if (!savedFieldsValues && !this.query) {
            this.buildMap(true);
            this.initRefreshInterval();
        } else {
            setTimeout(() => {
                this.firstInit = true;
                this.subscribeToThingQueryField();
            });
        }
    }

    private initRefreshInterval(body?: any): void {
        this.zone.runOutsideAngular(() => {
            this.intervalId = setInterval(() => {
                this.zone.run(() => {
                    this.buildMap(false, body);
                });
            }, this.refreshIntervalMillis);
        });
    }

    private subscribeToThingQueryField(): void {
        if (this.queryFieldRef && !this.thingQueryFieldSubInit) {
            this.thingQueryFieldSubInit = true;
            this.fieldServiceSubscription = this.fieldService.subscribeToFields([this.queryFieldRef])
                .subscribe(fieldsMap => this.manageThingsFilterValue(fieldsMap));
        }
    }

    private manageThingsFilterValue(fieldsMap: { [fields: string]: any }): void {
        let advancedSearchBody = fieldsMap[this.queryFieldRef];
        if (advancedSearchBody) {
            this.reloadMap(advancedSearchBody);
        }
    }

    private buildMap(setBounds?: boolean, advancedSearchBody?: any): void {
        if (this.marker?.clusterFilter) {
            this.markerCluster = this.loaderPipe.transform(this.buildDataObject(), this.marker.clusterFilter, true);
        }
        this.thingMapService.getThings(this.includeAlertSeverity, advancedSearchBody, this.searchFields).then(things => {
            things.forEach(t => {
                if (this.isValidLatLng(t.gpsPosition || t.location?.gpsPosition)) {
                    this.things.push(t);
                    this.thingIdMapping[t.id] = t;
                }
            });
            this.loading = false;
            this.firstInit = true;
            if (this.things.length) {
                this.noMarkerMessage = null;
                setTimeout(() => { // waiting for the map rendered otherwise document.getElementById won't work
                    if (!this.initMap) {
                        const mapDiv: Element = document.getElementById(this.id);
                        this.map = L.map(mapDiv, this.mapOptions).setView([51.505, -0.09], 13);
                        this.map.attributionControl.setPrefix('');
                        if (!this.layerFilter) {
                            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
                                subdomains: ['a', 'b', 'c']
                            }).addTo(this.map);
                        } else {
                            this.loaderPipe.transform(this.map, this.layerFilter, true);
                        }
                        this.initMap = true;
                        this.map.on('click', () => this.error = null);
                    }
                    // compute markers
                    if (this.marker?.filter) {
                        this.filter = this.marker.filter;
                        Promise.all(this.things.map(t => this.marker.get(t).then(value => { return { id: t.id, value: value } }))).then(results => {
                            results.forEach(result => {
                                let v = result.value;
                                if (v) {
                                    let value = v["value"] ? v["value"] : v;
                                    this.thingValueMapping[result.id] = value
                                }
                            });
                            this.updateMarkers();
                            if (setBounds) {
                                this.setBounds();
                            }
                            if (this.marker.clusterEnabled) {
                                this.map.addLayer(this.markerCluster);
                            }
                        });
                    } else {
                        this.updateMarkers();
                        if (setBounds) {
                            this.setBounds();
                        }
                    }
                    this.subscribeToThingQueryField();
                }, 0);
            } else {
                if (!this.initMap) {
                    this.noMarkerMessage = 'No thing with GPS position found';
                }
                this.subscribeToThingQueryField();
            }
        });
    }

    private setBounds(): void {
        if (this.bounds) {
            const southWest = L.latLng(this.bounds.sw.lat, this.bounds.sw.lng);
            const northEast = L.latLng(this.bounds.ne.lat, this.bounds.ne.lng);
            const bounds = L.latLngBounds(southWest, northEast);
            if (!this.fitBounds) {
                this.map.fitBounds(bounds);
                this.fitBounds = true;
            }
        }
    }

    ngOnDestroy(): void {
        if (this.queryFieldRef) {
            this.fieldService.unsubscribeFromFields([this.queryFieldRef]);
        }
        this.clearRefreshInterval();
        if (this.fieldServiceSubscription) {
            this.fieldServiceSubscription.unsubscribe();
        }
    }

    private clearRefreshInterval(): void {
        clearInterval(this.intervalId);
    }

    ngOnInit(): void {
        this.refreshIntervalMillis = Math.max(moment.duration(this.refreshInterval).asMilliseconds(), 5000); // min 5 sec
        this.setTagPermission = this.authenticationService.hasPermission(Permissions.WRITE_THING_TAG);
        this.bulkRecipePermission = this.authenticationService.hasPermission(Permissions.EXECUTE_BULK_UPDATE) && this.authenticationService.hasPermission(Permissions.EXECUTE_THING_COMMAND);
        this.bulkCommandPermission = this.authenticationService.hasPermission(Permissions.EXECUTE_BULK_UPDATE) && this.authenticationService.hasPermission(Permissions.EXECUTE_THING_COMMAND);
        this.writePermission = this.setTagPermission || this.bulkCommandPermission;
        this.height = this.height || (DEFAULT_HEIGHT + 'px');
        this.id = 'thing-map-widget-' + thingMapComponentId++;
        this.initMap = false;
        this.fitBounds = false;
        this.loading = true;
        this.firstInit = false;
        this.mapOptions = this.mapOptions || {};
        this.markers = [];
        this.markerCoordinates = [];
        if (this.authenticationService.isCustomerUser()) {
            this.userCustomerId = this.authenticationService.getUser().customerId;
        } else if (this.contextService.getCurrentCustomer()) {
            this.userCustomerId = this.contextService.getCurrentCustomer().id;
        }
    }

    private getMarkerFromThing(thing: Thing): { thing: Thing, icon: any } {
        const latlng = thing.gpsPosition || thing.location.gpsPosition;
        this.markerCoordinates.push(latlng);
        const coordinates = this.parseLatLng(latlng);
        if (coordinates) {
            this.updateBounds(coordinates);
            const markerIcon = this.getMarkerIcon(thing.alertSeverity);
            const icon = L.marker([coordinates.lat, coordinates.lng], { icon: markerIcon });
            if (this.marker?.clusterEnabled) {
                this.markerCluster.addLayer(icon);
            } else {
                icon.addTo(this.map);
            }
            icon.on('click', () => {
                if (this.selectAreaFeature) {
                    if (this.isIconSelectable) {
                        this.getParentThingsByArea();
                        let selectedIcon = {
                            iconUrl: this.selectedMarkerUrl || ThingMapComponent.selectedMarkerIcon,
                            iconSize: ThingMapComponent.iconSize
                        };
                        const selectedThings = this.getSelectedThings(icon);
                        if (!selectedThings.some(thing => this.selectedThingIds.includes(thing.id)) && !selectedThings.some(thing => this.thingIdsInsideArea.includes(thing.id))) {
                            if (icon._preSpiderfyLatlng) {      // open cluster
                                this.latLngMarkersArray.push(icon._preSpiderfyLatlng);
                                const clusterCoordinates = icon._preSpiderfyLatlng.lat + "," + icon._preSpiderfyLatlng.lng;
                                this.markers.forEach(marker => {
                                    const markerThing = marker.thing;
                                    if (markerThing.gpsPosition ? markerThing.gpsPosition === clusterCoordinates : (markerThing.location ? markerThing.location.gpsPosition === clusterCoordinates : null)) {
                                        marker.icon.setIcon(L.icon(selectedIcon));
                                    }
                                });
                            } else {
                                this.latLngMarkersArray.push(icon._latlng);
                                icon.setIcon(L.icon(selectedIcon));
                            }
                            selectedThings.forEach(thing => this.selectedThingIds.push(thing.id));
                        } else if (!selectedThings.some(thing => this.thingIdsInsideArea.includes(thing.id))) {
                            if (icon._preSpiderfyLatlng) {
                                const clusterCoordinates = icon._preSpiderfyLatlng.lat + "," + icon._preSpiderfyLatlng.lng;
                                this.markers.forEach(marker => {
                                    const markerThing = marker.thing;
                                    if (markerThing.gpsPosition ? markerThing.gpsPosition === clusterCoordinates : (markerThing.location ? markerThing.location.gpsPosition === clusterCoordinates : null)) {
                                        const oldIcon = this.getMarkerIcon(markerThing.alertSeverity);
                                        marker.icon.setIcon(oldIcon);
                                    }
                                });
                            } else {
                                const oldIcon = this.getMarkerIcon(thing.alertSeverity);
                                icon.setIcon(oldIcon);
                            }
                            selectedThings.forEach(thing => {
                                const indexToRemove = this.selectedThingIds.findIndex(el => el == thing.id);
                                if (indexToRemove > -1) {
                                    this.selectedThingIds.splice(indexToRemove, 1);
                                }
                            });
                            const indexToRemove = this.latLngMarkersArray.findIndex(_latlng => _latlng == (icon._preSpiderfyLatlng ? icon._preSpiderfyLatlng : icon._latlng));
                            if (indexToRemove > -1) {
                                this.latLngMarkersArray.splice(indexToRemove, 1);
                            }
                            if (this.marker) {
                                this.loaderPipe.transform(this.buildDataObject(), this.filter, true);
                                this.selectedThingIds.forEach(id => {
                                    const marker = this.markers.find(marker => marker.thing.id == id);
                                    marker.icon.setIcon(L.icon(selectedIcon));
                                });
                                this.thingIdsInsideArea.forEach(id => {
                                    const marker = this.markers.find(marker => marker.thing.id == id);
                                    marker.icon.setIcon(L.icon(selectedIcon));
                                });
                            }
                        }
                    }
                } else {
                    this.navigationService.navigateTo(['dashboard/thing_details', thing.id]);
                }
            });
            return {
                thing: thing,
                icon: icon
            }
        }
        return null;
    }

    private parseLatLng(latlng: string): Coordinates {
        const coordinates = latlng.split(',');
        if (coordinates.length != 2) {
            return undefined;
        }
        return {
            lat: parseFloat(coordinates[0]),
            lng: parseFloat(coordinates[1])
        };
    }

    private getMarkerIcon(severity) {
        let iconProp = null;
        if (severity && (severity === AlertSeverityOptions.FAILURE.value || severity === AlertSeverityOptions.CRITICAL.value || severity === AlertSeverityOptions.EMERGENCY.value)) {
            iconProp = {
                iconUrl: this.failureMarkerUrl || ThingMapComponent.failureMarkerIcon,
                iconSize: ThingMapComponent.iconSize
            };
        } else if (severity && severity === AlertSeverityOptions.WARNING.value) {
            iconProp = {
                iconUrl: this.warningMarkerUrl || ThingMapComponent.warningMarkerIcon,
                iconSize: ThingMapComponent.iconSize
            };
        } else {
            iconProp = {
                iconUrl: this.defaultMarkerUrl || ThingMapComponent.defaultMarkerIcon,
                iconSize: ThingMapComponent.iconSize
            };
        }
        return L.icon(iconProp);
    }

    private updateBounds(coordinates: Coordinates): void {
        if (!this.bounds) {
            this.bounds = {
                ne: Object.assign({}, coordinates),
                sw: Object.assign({}, coordinates)
            };
            return;
        }

        if (this.bounds.ne.lat < coordinates.lat) {
            this.bounds.ne.lat = coordinates.lat;
        }
        if (this.bounds.ne.lng < coordinates.lng) {
            this.bounds.ne.lng = coordinates.lng;
        }
        if (this.bounds.sw.lat > coordinates.lat) {
            this.bounds.sw.lat = coordinates.lat;
        }
        if (this.bounds.sw.lng > coordinates.lng) {
            this.bounds.sw.lng = coordinates.lng;
        }
    }

    private updateMarkers() {
        if (!this.selectAreaFeature) {
            if (this.markers.length > 0) {
                const alreadyPresentThingId = this.markers.map(marker => marker.thing.id);
                this.markers = this.markers.filter(marker => this.thingIdMapping[marker.thing.id]);
                this.markers.forEach(marker => {
                    const thing = this.thingIdMapping[marker.thing.id];
                    if (thing.alertSeverity != marker.thing.alertSeverity || this.selectedThingIds.find(el => el == marker.thing.id) || this.thingIdsInsideArea.find(el => el == marker.thing.id)) {
                        const markerIcon = this.getMarkerIcon(thing.alertSeverity);
                        marker.icon.setIcon(markerIcon);
                        marker.thing.alertSeverity = thing.alertSeverity;
                    }
                });
                this.markers = this.things.filter(t => alreadyPresentThingId.indexOf(t.id) < 0).map(thing => this.getMarkerFromThing(thing)).concat(this.markers);
            } else {
                this.markers = this.things.map(thing => this.getMarkerFromThing(thing));
            }
            if (this.marker) {
                this.loaderPipe.transform(this.buildDataObject(), this.filter, true);
            }
        }
    }

    private buildDataObject(): { data: any, marker: any, severity?: string }[] {
        return this.markers.map(m => {
            let obj = {
                data: this.thingValueMapping[m.thing.id],
                marker: m.icon,
            }
            if (this.marker.includeAlertSeverity) {
                obj["severity"] = m.thing.alertSeverity;
            }
            return obj;
        });
    }

    toggleSelectArea(): void {
        this.selectAreaFeature = !this.selectAreaFeature;
        if (this.selectAreaFeature) {
            this.updateMarkersIcon = true;
            this.selectFeature = this.map.selectAreaFeature.enable();
            this.selectFeature.options.color = '#00c0ef';
            this.selectFeature.options.weight = 3;
            this.latLngMarkersArray = [];
        } else {
            this.selectFeature.removeAllArea();
            this.selectFeature.disable();
            this.latLngMarkersArray = [];
            this.latLngAreaArray = [];
            this.selectAreaFeatureLength = 0;
            this.updateMarkersIcon = false;
            this.resetMarkerIcons();
            this.clearLayersMap();
            this.selectFeature._ARR_latlon = [];
        }
    }

    checkDraw(): boolean {
        if (this.selectAreaFeature && (this.selectFeature._area_pologon_layers.length || this.latLngMarkersArray.length)) {
            const length = this.selectFeature._area_pologon_layers.length;
            if (length > this.selectAreaFeatureLength) {
                if (this.selectFeature.getAreaLatLng().length > 0) {
                    this.selectAreaFeatureLength = length;
                    this.updateMarkersIcon = true;
                    this.getLat();
                    this.getParentThingsByArea();
                } else {
                    // no latLng => invalid area
                    this.selectFeature.removeLastArea();
                    if (this.selectFeature._area_pologon_layers.length == 0 && !this.latLngMarkersArray.length) {
                        return false;
                    }
                }
            }
            if (this.updateMarkersIcon) {
                this.updateAreaMarkers();
            }
            return true;
        } else {
            return false;
        }
    }

    private updateAreaMarkers(): void {
        try {
            let objects = this.selectFeature.getFeaturesSelected('marker');
            if (objects) {
                this.updateMarkersIcon = false;
                let selectedIcon = {
                    iconUrl: this.selectedMarkerUrl || ThingMapComponent.selectedMarkerIcon,
                    iconSize: ThingMapComponent.iconSize
                };
                objects.forEach(icon => {
                    if (this.isMarkerInsideArea(icon) && !icon._preSpiderfyLatlng) {
                        let selectedThings: Thing[] = [];
                        if (icon._childCount > 0) {
                            let markersToUpdate = icon.getAllChildMarkers();
                            markersToUpdate.forEach(markerToUpdate => {
                                markerToUpdate.setIcon(L.icon(selectedIcon));
                                const indexToRemove = this.latLngMarkersArray.findIndex(_latlng => _latlng == (markerToUpdate._preSpiderfyLatlng ? markerToUpdate._preSpiderfyLatlng : markerToUpdate._latlng));
                                if (indexToRemove > -1) {
                                    this.latLngMarkersArray.splice(indexToRemove, 1);
                                }
                                selectedThings = selectedThings.concat(this.getSelectedThings(markerToUpdate).filter(thing => !this.thingIdsInsideArea.includes(thing.id) && !selectedThings.includes(thing)));
                            });
                        } else {
                            icon.setIcon(L.icon(selectedIcon));
                            const indexToRemove = this.latLngMarkersArray.findIndex(_latlng => _latlng == icon._latlng);
                            if (indexToRemove > -1) {
                                this.latLngMarkersArray.splice(indexToRemove, 1);
                            }
                            selectedThings = this.getSelectedThings(icon).filter(thing => !this.thingIdsInsideArea.includes(thing.id));
                        }
                        if (selectedThings && selectedThings.length) {
                            selectedThings.forEach(thing => {
                                this.thingIdsInsideArea.push(thing.id);
                                const indexToRemove = this.selectedThingIds.findIndex(el => el == thing.id);
                                if (indexToRemove > -1) {
                                    this.selectedThingIds.splice(indexToRemove, 1);
                                }
                            });
                        }
                    }
                });
            }
        } catch {
            this.updateMarkersIcon = false;
        }
    }

    private isMarkerInsideArea(marker): boolean {
        let polyPoints = this.selectFeature.getAreaLatLng();
        let x = marker.getLatLng().lat, y = marker.getLatLng().lng;

        let inside: boolean = false;
        for (let i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
            let xi = polyPoints[i].lat, yi = polyPoints[i].lng;
            let xj = polyPoints[j].lat, yj = polyPoints[j].lng;

            let intersect = ((yi > y) != (yj > y))
                && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) {
                inside = !inside;
            }
        }
        return inside;
    };

    private resetMarkerIcons(): void {
        this.updateMarkers();
        this.selectedThingIds = [];
        this.thingIdsInsideArea = [];
    }

    onKeydown() {
        if (this.selectAreaFeature) {
            this.isIconSelectable = true;
            this.selectFeature.disable();
        }
    }

    onKeyup() {
        if (this.selectAreaFeature) {
            this.isIconSelectable = false;
            this.selectFeature.enable();
        }
    }

    private clearLayersMap(): void {
        for (let i in this.map._layers) {
            if (this.map._layers[i]._path != undefined) {
                try {
                    this.map.removeLayer(this.map._layers[i]);
                }
                catch (e) {
                    console.log("problem with " + e + this.map._layers[i]);
                }
            }
        }
    }

    getLat(): void {
        this.latLngAreaArray.push(this.selectFeature.getAreaLatLng());
    }

    openAddBulkOperationDialog(): void {
        let promises = [];
        const body = {
            selectedCoordinates: this.latLngMarkersArray,
            areaCoordinates: this.latLngAreaArray
        };
        promises.push(this.thingMapService.getResources(body, 'COMMAND', this.advancedSearchBody, this.searchFields))
        promises.push(this.thingMapService.getResources(body, 'RECIPE', this.advancedSearchBody, this.searchFields));
        Promise.all(promises).then(results => {
            this.commandsByAreaResponseItems = results[0];
            this.recipeByAreaResponseItems = results[1];
            if (this.userCustomerId) {
                this.recipeByAreaResponseItems.forEach(item => {
                    let resources: any = item.resources;
                    const filteredResources = resources.filter(resource => !resource.customerId || resource.customerId == this.userCustomerId);
                    item.resources = filteredResources;
                });
            }
            this.addBulkOperationDialog.open(this.bulkCommandPermission && this.commandsByAreaResponseItems.length > 0, this.bulkRecipePermission && this.recipeByAreaResponseItems.some(el => el.resources.length > 0));
        }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
    }

    openBulkOperationDialog(operation: string): void {
        switch (operation) {
            case "RECIPE":
                this.bulkOperationDialog.open(this.recipeByAreaResponseItems, operation);
                break;
            case "COMMAND":
                this.bulkOperationDialog.open(this.commandsByAreaResponseItems, operation);
                break;
            default:
                break;
        }
    }

    executeBulkOperation(result: any): void {
        const body = {
            name: result.name,
            type: result.type,
            scheduleTimestamp: result.scheduleTimestamp,
            thingDefinitionResourceMap: result.map,
            selectedCoordinates: this.latLngMarkersArray,
            areaCoordinates: this.latLngAreaArray
        };
        this.thingMapService.bulkCommandUpdate(body, this.advancedSearchBody, this.searchFields)
            .then(() => {
                this.message = "bulkUpdateScheduleMessage";
                this.saveMessage.show();
            })
            .catch(err => {
                this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR);
            });
    }

    openEditTagsDialog(): void {
        const params = this.thingMapService.buildParams(0, this.advancedSearchBody, this.searchFields);
        this.openBulkUpdateTagDialog(params);
    }

    private openBulkUpdateTagDialog(searchParams: any): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.minWidth = '25%';
        dialogConfig.maxWidth = '428px';
        const data: BulkUpdateTagDialogData = {
            selectedThingIds: null,
            allElementsSelected: null,
            searchParams: searchParams,
            areaCoordinates: this.latLngAreaArray,
            selectedCoordinates: this.latLngMarkersArray,
            mapTagging: true
        }
        dialogConfig.data = data;
        this.dialog.open(BulkUpdateTagDialogComponent, dialogConfig);
    }

    openSetParentThingDialog(): void {
        this.setParentThingDialog.open(this.bulkUpdateParentThingsResponse);
    }

    updateParentThing(parentThingId: string): void {
        const body = {
            parentThingId: parentThingId,
            selectedCoordinates: this.latLngMarkersArray,
            areaCoordinates: this.latLngAreaArray
        };
        this.thingMapService.setParentThing(body, this.advancedSearchBody, this.searchFields)
            .then(() => {
                this.message = "updateParentThingMessage";
                this.saveMessage.show();
            })
            .catch(err => {
                this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR);
            });
    }

    reloadMap(body: any): void {
        if (body) {
            this.advancedSearchBody = body;
        }
        this.bounds = null;
        this.selectAreaFeature = false;
        this.initMap = false;
        this.fitBounds = false;
        this.loading = true;
        this.mapOptions = this.mapOptions || {};
        this.things = [];
        this.markers = [];
        this.markerCoordinates = [];
        this.selectAreaFeatureLength = 0;
        this.latLngAreaArray = [];
        this.latLngMarkersArray = [];
        this.thingValueMapping = {};
        this.selectedThingIds = [];
        this.thingIdsInsideArea = [];
        this.thingIdMapping = {};
        this.clearRefreshInterval();
        this.buildMap(true, body);
        this.initRefreshInterval(body)
    }

    private isValidLatLng(latlng: string): boolean {
        if (latlng) {
            return /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/.test(latlng);
        }
        return false;
    }

    private getSelectedThings(icon): Thing[] {
        let lat;
        let lng;
        if (icon._preSpiderfyLatlng) {
            //before open cluster
            lat = icon._preSpiderfyLatlng.lat;
            lng = icon._preSpiderfyLatlng.lng;
        } else {
            lat = icon.getLatLng().lat;
            lng = icon.getLatLng().lng;
        }
        return this.things.filter(thing => thing.gpsPosition ? thing.gpsPosition === lat + "," + lng : (thing.location ? thing.location.gpsPosition == lat + "," + lng : null));
    }

    updateAdvancedSearchStatus(isOpen: boolean): void {
        this.isAdvancedSearchOpen = isOpen;
    }

    private getParentThingsByArea(): void {
        const body = {
            selectedCoordinates: this.latLngMarkersArray,
            areaCoordinates: this.latLngAreaArray
        }
        this.setParentThingVisible = false;
        this.thingMapService.getParentThingsByArea(body, this.advancedSearchBody, this.searchFields).then(result => {
            this.error = null;
            this.bulkUpdateParentThingsResponse = result;
            this.setParentThingVisible = Object.keys(this.bulkUpdateParentThingsResponse.parentThings).length > 0;
        }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
    }

}