import { HttpParams } from "@angular/common/http";
import { Component, forwardRef, Inject, OnInit } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { SERVICE_LEVELS, THING_DEFINITIONS } from "../../../common/endpoints";
import { Customer, ProductModel, ServiceLevel, Tag, ThingDefinition } from "../../../model";
import { AuthenticationService } from "../../../service/authentication.service";
import { ContextService } from "../../../service/context.service";
import { CustomLabelService } from "../../../service/custom-label.service";
import { CustomPropertyService } from "../../../service/custom-property.service";
import { HttpService } from "../../../service/http.service";
import { ProductModelService } from "../../../service/product-model.service";
import { ServiceLevelService } from "../../../service/service-level.service";
import { UserCustomerService } from "../../../service/user-customer.service";
import { AbstractContextService } from "../../../shared/class/abstract-context-service.class";
import { AdvancedSearchService } from "../../advanced-search/advanced-search.service";
import { SearchFieldService } from "../search-field.service";
import { AbstractPropertySearchInputComponent } from "../search-input/abstract-property-search-input.component";

@Component({
    selector: 'things-property-search-input',
    template: require('./things-property-search-input.component.html'),
    providers: [SearchFieldService, AdvancedSearchService]
})
export class ThingsPropertySearchInputComponent extends AbstractPropertySearchInputComponent implements OnInit {

    private userCustomerId: string;
    private tags: Tag[] = [];
    private addCustomerSearchField: boolean;

    constructor(
        @Inject(forwardRef(() => CustomPropertyService)) customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => CustomLabelService)) customLabelService: CustomLabelService,
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => UserCustomerService)) private userCustomerService: UserCustomerService,
        @Inject(forwardRef(() => SearchFieldService)) protected searchFieldService: SearchFieldService,
        @Inject(forwardRef(() => AdvancedSearchService)) private advancedSearchService: AdvancedSearchService,
        @Inject(forwardRef(() => ProductModelService)) private productModelService: ProductModelService
    ) {
        super(customPropertyService, customLabelService, searchFieldService);
    }

    protected handleThingProperties(): void {
        this.thingProperties = this.advancedSearchService.mergeThingProperties(this.allThingProperties);
    }

    initConfiguration(): void {
        this.defaultProperties = [
            { name: 'name', label: 'thingNameProperty' },
            { name: 'customer.code', label: 'customerCodeProperty' },
        ];
        this.userCustomerId = this.authenticationService.getUser().customerId || (this.contextService.getCurrentCustomer() ? this.contextService.getCurrentCustomer().id : null)
            || (this.authenticationService.isLocationUser() ? (ContextService.getCustomerFromLocation(this.authenticationService.getUser().location) ? ContextService.getCustomerFromLocation(this.authenticationService.getUser().location).id : null) : null);
        this.addCustomerSearchField = (this.authenticationService.isOrganizationUser() || this.authenticationService.isPartnerUser()) && !this.userCustomerId;
        if (this.property?.indexOf('properties.') > -1 || this.defaultProperties.some(prop => prop.name == this.property)) {
            if (!this.addCustomerSearchField && this.property.indexOf("customer.") > -1) {
                this.searchFields = null;
            } else {
                if (this.property.startsWith("properties.")) {
                    this.property = this.property.substring(11);
                }
                this.searchFields = [this.property];
            }
        } else {
            this.useDefaultProperty()
        }
    }

    protected handleDefaultProperty(): void {
        if (this.property == "customer" && this.addCustomerSearchField) {
            this.handleCustomer();
        } else if (this.property == "thingDefinitions") {
            this.handleThingDefinitions();
        } else if (this.property == "serviceLevels") {
            this.handleServiceLevels();
        } else if (this.property == "tags") {
            this.handleTags();
        } else if (this.property == "productModels") {
            this.handleProductModels();
        }
    }

    private handleCustomer(): void {
        let partnerParam = null;
        if (this.contextService.getCurrentPartner()) {
            partnerParam = new HttpParams().set('partnerId', this.contextService.getCurrentPartner().id);
        }
        this.userCustomerService.getRecursivelyAllCustomers(null, null, partnerParam).then(customers => {
            const customerTypes = customers.map((c: Customer) => { return { value: c.id, label: c.name } });
            if (customerTypes?.length) {
                this.advancedSearchConfiguration = [{ name: 'customer', placeholder: this.customLabels && this.customLabels['customerProperty'] ? this.customLabels['customerProperty'] : this.property, type: 'STRING', selectionMode: 'MAT_SELECTION', values: customerTypes, value: this.searchFieldService.getValueFromFieldsValues('customer', this.savedFieldsValues), hideLabel: true }];
            }
        }).catch(err => console.log(err));
    }

    private handleThingDefinitions(): void {
        firstValueFrom(this.httpService.get<ThingDefinition[]>(THING_DEFINITIONS)).then(thingDefinitions => {
            const thingDefinitionTypes = thingDefinitions.map((td: ThingDefinition) => { return { value: td.id, label: td.name } });
            if (thingDefinitionTypes?.length) {
                this.advancedSearchConfiguration = [{ name: 'thingDefinitions', placeholder: "All Thing Definitions", type: 'STRING', selectionMode: 'MAT_SELECTION', values: thingDefinitionTypes, value: this.searchFieldService.getValueFromFieldsValues('thingDefinitions', this.savedFieldsValues), multipleSelection: true, hideLabel: true }];
            }
        }).catch(err => console.log(err));
    }

    private handleServiceLevels(): void {
        firstValueFrom(this.httpService.get<ServiceLevel[]>(SERVICE_LEVELS)).then(serviceLevels => {
            const serviceLevelTypes = serviceLevels.sort(ServiceLevelService.compare).map((sl: ServiceLevel) => { return { value: sl.id, label: sl.name } });
            if (serviceLevelTypes?.length) {
                this.advancedSearchConfiguration = [{ name: 'serviceLevels', placeholder: "All Service Levels", type: 'STRING', selectionMode: 'MAT_SELECTION', values: serviceLevelTypes, value: this.searchFieldService.getValueFromFieldsValues('serviceLevels', this.savedFieldsValues), multipleSelection: true, hideLabel: true }];
            }
        }).catch(err => console.log(err));
    }

    private handleTags(): void {
        this.tags = this.contextService.getTagObjects();
        let allTags = this.tags?.length ? this.tags.map(t => { return { value: t.name, label: t.name } }) : [];
        if (allTags?.length) {
            this.advancedSearchConfiguration = [{ name: 'tags', placeholder: "All Tags", type: 'STRING', selectionMode: 'MAT_SELECTION', values: allTags, value: this.searchFieldService.getValueFromFieldsValues('tags', this.savedFieldsValues), multipleSelection: true, hideLabel: true }];
        }
    }

    protected handleValue(body: any, isEncoded: boolean): any {
        if (this.property == 'tags' && isEncoded) {
            body['tags'] = body['tags'] ? this.getTagsIds(body['tags']) : null;
            body['tagIds'] = body['tags'];
        }
        return body;
    }

    private handleProductModels(): void {
        this.productModelService.getProductModelsAssociatedToThings().then(productModels => {
            const productModelTypes = productModels.map((pm: ProductModel) => { return { value: pm.id, label: pm.name } });
            if (productModelTypes?.length) {
                this.advancedSearchConfiguration = [{ name: 'productModels', placeholder: "All Models", type: 'STRING', selectionMode: 'MAT_SELECTION', values: productModelTypes, value: this.searchFieldService.getValueFromFieldsValues('productModels', this.savedFieldsValues), multipleSelection: true, hideLabel: true }];
            }
        }).catch(err => console.log(err));
    }

    private getTagsIds(tagNames: string[]) {
        const tags = this.contextService.getTagObjects();
        return tagNames.map(tag => tags.find(t => t.name == tag)?.id).filter(t => t);
    }
}