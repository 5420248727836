import { Component, forwardRef, Inject, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FieldService } from '../../../service/field.service';
import { LocationAdvancedSearchComponent } from '../../advanced-search/location-advanced-search/location-advanced-search.component';
import { AbstractSearchFieldComponent } from '../abstract-search-field.component';
import { SearchFieldService } from '../search-field.service';
import { LocationsPropertySearchInputComponent } from './locations-property-search-input.component';

@Component({
    selector: 'locations-search-field',
    template: require('./locations-search-field.component.html'),
    styles: [require('../search-field.component.css')],
})
export class LocationsSearchFieldComponent extends AbstractSearchFieldComponent {

    @ViewChild(LocationAdvancedSearchComponent) locationAdvancedSearch: LocationAdvancedSearchComponent;

    @ViewChildren(LocationsPropertySearchInputComponent) private locationsPropertySearchInputs: QueryList<LocationsPropertySearchInputComponent>;

    constructor(
        @Inject(forwardRef(() => FieldService)) fieldService: FieldService,
        @Inject(forwardRef(() => SearchFieldService)) searchFieldService: SearchFieldService
    ) {
        super(fieldService, searchFieldService)
    }

    openAddPropertiesDialog(advancedSearchProperties: { name: string, label: string }[]): void {
    }

    closeAddPropertiesDialog(updatedSearchFields: string[]): void {
    }

    performClear(): void {
        this.locationAdvancedSearch?.clearAll();
        this.clearInputs();
    }

    protected updateAdvancedSearch(fieldsToSaveBody: any): void {
        this.locationAdvancedSearch?.updateLocalStorage(fieldsToSaveBody);
        this.locationAdvancedSearch?.initAllConfigurations();
    }

    protected getEncodedRawValues(): void {
        let body: any = {};
        this.locationsPropertySearchInputs?.forEach(property => Object.assign(body, property.getFormValue(true)));
        return body;
    }

    protected getRawValues() {
        let body: any = {};
        this.locationsPropertySearchInputs?.forEach(property => Object.assign(body, property.getFormValue(false)));
        return body;
    }

    protected refreshInputConfigurations(): void {
        this.locationsPropertySearchInputs.forEach(input => {
            input.refreshConfiguration();
        });
    }

}