import { Inject, Injectable, forwardRef } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { USER_ME_SUBSCRIPTION_SETS } from '../common/endpoints';
import { SubscriptionSet } from '../model';
import { HttpService } from './http.service';

@Injectable()
export class SubscriptionSetService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }

    getSubscriptionSet(): Promise<SubscriptionSet> {
        return firstValueFrom(this.httpService.get<SubscriptionSet[]>(USER_ME_SUBSCRIPTION_SETS)).then(subs => {
            if (subs.length) {
                return subs[0];
            }
            throw 'Invalid Subscriptions';
        });
    }

}