import { AfterContentInit, Component, ContentChildren, forwardRef, Inject, Input, NgZone, OnDestroy, OnInit, QueryList, ViewChild } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { BehaviorSubject, Subscription } from "rxjs";
import { ErrorMessages, Permissions } from "../../../common/constants";
import { PropertyInfo } from "../../../common/properties";
import { EventDetailsPageDialogComponent } from "../../../dashboard-area/event-details/event-details-page-dialog.component";
import { AlertService } from "../../../dashboard-area/shared/alert.service";
import { CustomMatPaginatorIntl } from "../../../dashboard-area/shared/custom-mat-paginator-intl.service";
import { Alert, AlertAcknowledgeStatus, AlertDefinition, PagedList } from "../../../model";
import { AbstractExportContextService } from "../../../service/abstract-export-context.service";
import { AppService } from "../../../service/app.service";
import { AuthenticationService } from "../../../service/authentication.service";
import { NavigationService } from "../../../service/navigation.service";
import { SocketService } from "../../../service/socket.service";
import { ThingContextService } from "../../../service/thing-context.service";
import { MessageComponent, ModalComponent } from "../../../shared/component";
import { ButtonActionValue } from "../../../shared/custom-table";
import { AdvancedSearchLayoutType, BulkControl, ClickOnRowBehaviour, ListWidgetV2Component } from "../../../shared/list-widget-v2/list-widget-v2.components";
import { SearchTargetType } from "../../../shared/search-field/search-field.component";
import { COMPONENT_DEFINITION_REF } from "../../../shared/utility/component-definition-token";
import { ErrorUtility } from "../../../utility/error-utility";
import { AlertListModeType, DetailsModeType } from "../../shared/alert-work-session-list";
import { ThingListWidgetV2Service } from "../../thing-list/thing-list-widget-v2.service";
import { ActiveAlertListWidgetV2Service } from "./active-alert-list-widget-v2.service";

@Component({
    selector: 'active-alert-list-widget-v2',
    template: require('./active-alert-list-widget-v2.component.html'),
    styles: [require('../../../shared/list-widget-v2/list-widget-v2.css')],
    providers: [ActiveAlertListWidgetV2Service, ThingListWidgetV2Service, AlertService, { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl }]
})
export class ActiveAlertListWidgetV2Component extends ListWidgetV2Component<Alert> implements OnInit, AfterContentInit, OnDestroy {

    @Input() searchFields: string[] = ["name", "title", "description", "thing.name", "thingDefinition.name"];

    @Input() enableActions = true;

    @Input() detailsMode: DetailsModeType = DetailsModeType.POPUP;

    @Input() includeSubThingsAlerts: boolean;

    @Input() mode: AlertListModeType = AlertListModeType.TABLE;

    @Input() expandable: boolean = true;

    @ContentChildren(COMPONENT_DEFINITION_REF) private columnComponents: QueryList<any>;

    @ViewChild('saveMessage') saveMessage: MessageComponent;

    @ViewChild('confirmClear') confirmClear: ModalComponent;

    private defaultProperties: { [name: string]: PropertyInfo } = {
        severity: { label: 'alertDefinitionSeverityProperty', path: 'severity', defaultFilter: null, defaultSorting: null },
        category: { label: 'alertDefinitionCategoryProperty', path: 'category', defaultFilter: null, defaultSorting: null },
        name: { label: 'alertDefinitionNameProperty', path: 'name', defaultFilter: null, defaultSorting: null },
        title: { label: 'alertDefinitionTitleProperty', path: 'title', defaultFilter: null, defaultSorting: null },
        description: { label: 'alertDefinitionDescriptionProperty', path: 'description', defaultFilter: null, defaultSorting: null },
        date: { label: 'alertDateProperty', path: 'date', defaultFilter: null, defaultSorting: null },
        duration: { label: 'alertDurationProperty', path: 'duration', defaultFilter: null, defaultSorting: null }
    };
    private alertSubscription: Subscription;
    private alertsSubscriptionId: number;

    alertDefinitions: AlertDefinition[] = [];
    message: string = 'updateAlertsMessage';
    manualClear: boolean;
    initCompleted: boolean;
    hidden: boolean;
    bulkControls: BulkControl[] = [];

    constructor(
        @Inject(forwardRef(() => ActiveAlertListWidgetV2Service)) private activeAlertListWidgetV2Service: ActiveAlertListWidgetV2Service,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => AuthenticationService)) authenticationService: AuthenticationService,
        @Inject(forwardRef(() => AppService)) appService: AppService,
        @Inject(forwardRef(() => ThingContextService)) private thingContextService: ThingContextService,
        @Inject(forwardRef(() => AlertService)) private alertService: AlertService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone,
        @Inject(forwardRef(() => SocketService)) private socketService: SocketService,
        @Inject(forwardRef(() => AbstractExportContextService)) exportService: AbstractExportContextService,
        @Inject(forwardRef(() => MatDialog)) dialog: MatDialog
    ) {
        super(appService, authenticationService, exportService, null, dialog, null);
    }

    ngOnInit() {
        this.checkIfHidden();
        this.checkIsMobile();
        if (this.isMobile) {
            this.mode = AlertListModeType.LIST;
        }
        this.writePermission = this.authenticationService.hasPermission(Permissions.WRITE_ALERT_STATUS);
        this.manualClear = this.authenticationService.hasPermission(Permissions.CLEAR_ALERT);
        this.handleAdvancedSearchLayoutType("active-alert-list-popup-advanced-search", SearchTargetType.ACTIVE_ALERTS);
        if (this.exportEnabled) {
            this.subscribeToExportServices();
        }
        this.bulkControls = [
            { icon: "bookmark_check", iconClass: "material-symbols-outlined", action: 'SAVE_ALL', title: 'takeOverProperty', visible: this.writePermission },
            { icon: "upcoming", iconClass: "material-symbols-outlined", action: 'DENY_ALL', title: 'markAsOpenProperty', visible: this.writePermission },
            { icon: "archive", iconClass: "material-symbols-outlined", action: 'MANUAL_CLEAR', title: 'clearProperty', visible: this.manualClear }
        ];
        this.preserveSelectedBetweenPages = true;
    }

    private checkIfHidden(): void {
        if (!this.thingContextService.getCurrentThing()) {
            if (this.authenticationService.isOrganizationUser() || this.authenticationService.isPartnerUser()) {
                this.hidden = !this.authenticationService.hasFeature('multipleThingAggregationForOrgPartner');
            } else {
                this.hidden = !this.authenticationService.hasFeature('multipleThingAggregationForCustomer');
            }
        }
    }

    ngAfterContentInit(): void {
        this.displayedColumns = this.activeAlertListWidgetV2Service.getVisibleColumns(this.columnComponents.toArray(), this.defaultProperties, 'Alert');
        this.descriptions = this.activeAlertListWidgetV2Service.getColumnDescriptions(this.columnComponents.toArray());
        this.sort = [];
        this.elements = [];
        this.alertService.getAlertDefinitions(true).then(alerts => {
            this.alertDefinitions = alerts;
            this.initCompleted = true;
            const storedFieldsValues = localStorage.getItem(this.queryFieldRef || 'alertAdvancedSearchFieldsValues');
            const savedFieldsValues = storedFieldsValues ? JSON.parse(storedFieldsValues) : null;
            if (!savedFieldsValues && !this.query && !this.queryFieldRef && this.advancedSearchLayout != AdvancedSearchLayoutType.POPUP) {
                this.getAlertList();
            }
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeFromSubscriptions();
        this.unsubscribeFormExport();
    }

    private unsubscribeFromSubscriptions(): void {
        if (this.alertSubscription) {
            this.alertSubscription.unsubscribe();
            this.alertSubscription = null;
        }
        if (this.alertsSubscriptionId) {
            this.socketService.delete(this.alertsSubscriptionId);
            this.alertsSubscriptionId = null;
        }
    }

    private getAlertList(): void {
        this.unsubscribeFromSubscriptions();
        this.alertSubscription = this.subscribeToActiveAlerts().subscribe(pagedList => {
            if (pagedList) {
                this.error = null;
                this.activeAlertListWidgetV2Service.updatePageListContent(pagedList.content, this.alertDefinitions, this.displayedColumns, false, this.detailsMode, this.clickOnRowBehaviour);
                this.updateElementList(pagedList);
            }
        })
    }

    private subscribeToActiveAlerts(): BehaviorSubject<PagedList<Alert>> {
        let subject = new BehaviorSubject(null);
        this.activeAlertListWidgetV2Service.getPagedList(this.pageIndex, this.pageSize, this.sort, null, this.searchFields, this.advancedSearchBody, null, this.includeSubThingsAlerts)
            .then(pagedList => {
                this.zone.run(() => subject.next(pagedList));
                const topic = this.alertService.getAlertsTopic();
                this.alertsSubscriptionId = this.socketService.subscribe({
                    topic,
                    callback: () => {
                        this.activeAlertListWidgetV2Service.getPagedList(this.pageIndex, this.pageSize, this.sort, null, this.searchFields, this.advancedSearchBody, null, this.includeSubThingsAlerts).then(pagedList => this.zone.run(() => subject.next(pagedList))).catch(err => this.error = ErrorUtility.getMessage(err));;
                    }
                });
            }
            ).catch(err => this.error = ErrorUtility.getMessage(err));
        return subject;
    }

    updateElementList(pagedList: PagedList<Alert>): void {
        if (this.isMobile) {
            this.elements = [...this.elements, ...pagedList.content];
        } else {
            this.elements = pagedList.content;
        }
        this.fillerRowCount = pagedList.numberOfElements;
        this.dataSource = new MatTableDataSource<Alert>(this.elements);
        this.length = pagedList.totalElements;
        this.pageSize = pagedList.size;
        this.pageIndex = pagedList.number;
        this.totalPages = pagedList.totalPages;
        this.loaded = true;
    }

    refreshList(data?: { pageIndex: number, pageSize: number, advancedSearchBody: any, sort: string[] }): void {
        this.loaded = false;
        if (data) {
            this.pageIndex = data.pageIndex;
            this.pageSize = data.pageSize;
            this.advancedSearchBody = data.advancedSearchBody;
            this.sort = data.sort;
        }
        this.getAlertList();
    }

    export(): void {
        const params = this.activeAlertListWidgetV2Service.getParams(this.searchFields, this.advancedSearchBody, true, this.includeSubThingsAlerts);
        this.activeAlertListWidgetV2Service.downloadCSV(params, this.exportService.resolveExportFileNamePlaceholders(this.exportFileName, this.advancedSearchBody?.startTimestamp, this.advancedSearchBody?.endTimestamp));
    }

    goToAlertDetails(id: string): void {
        if (this.detailsMode == DetailsModeType.PAGE) {
            this.navigationService.navigateTo(['/dashboard/event_details', id]);
        } else if (this.detailsMode == DetailsModeType.POPUP) {
            this.openDialog(id);
        }
    }

    goToDetail(alert: Alert): void {
        if (this.clickOnRowBehaviour == ClickOnRowBehaviour.OPEN_CONTEXT_OBJECT) {
            this.navigationService.goToThingDetailPage(alert.thing?.id);
        } else if (alert['showDetailsButton']) {
            this.goToAlertDetails(alert.id);
        }
    }

    execButtonAction(actionValue: ButtonActionValue): void {
        if (actionValue.action == 'showDetails') {
            this.goToAlertDetails(actionValue.value);
        }
    }

    saveAll(): void {
        this.alertService.saveAckDeny(this.selectedElements.map(t => { return t.id; }), AlertAcknowledgeStatus.ACKNOWLEDGED).then(() => {
            this.saveMessage.show();
            this.error = null;
            this.refreshElementList();
        }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.UPDATE_DATA_ERROR))
            .then(() => this.selectedElements = []);
    }
    denyAll(): void {
        this.alertService.saveAckDeny(this.selectedElements.map(t => { return t.id; }), AlertAcknowledgeStatus.DENIED).then(() => {
            this.saveMessage.show();
            this.error = null;
            this.refreshElementList();
        }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.UPDATE_DATA_ERROR))
            .then(() => this.selectedElements = []);
    }

    clearAlerts(): void {
        this.confirmClear.hide();
        this.selectedElements.forEach(ca => {
            if (ca.alertDefinitionId && ca.thing?.id) {
                this.alertService.clearManualAlerts(ca.thing.id, ca.alertDefinitionId).then(() => this.refreshElementList())
                    .catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.UPDATE_DATA_ERROR))
            }
        });
    }

    openConfirmClear(): void {
        this.confirmClear.show();
    }

    cancelClear(): void {
        this.confirmClear.hide();
    }

    executeAlertAdvancedSearch(advancedSearchBody: any): void {
        this.elements = [];
        this.executeAdvancedSearch(advancedSearchBody);
    }

    infiniteScrollMobile($event): void {
        if (this.isMobile) {
            const el = <any>($event.srcElement || $event.target);
            if (Math.trunc(el.scrollHeight - el.scrollTop) === el.clientHeight) {
                this.pageIndex++;
                this.getAlertList();
            }
        }
    }

    protected subscribeToExportServices(): void {
        this.exportId = "active-alert-list-" + this.getNextId();
        this.exportService.subscribeToExport(this.exportId, this.title || "Alerts").subscribe(() => this.export());
        this.subscribeToExportVisibility();
    }

    private openDialog(id: string): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.minWidth = '25%';
        dialogConfig.data = { id: id };
        this.dialog.open(EventDetailsPageDialogComponent, dialogConfig);
    }

    performBulkAction(action: string): void {
        switch (action) {
            case "SAVE_ALL":
                this.saveAll();
                break;
            case "DENY_ALL":
                this.denyAll();
                break;
            case "MANUAL_CLEAR":
                this.openConfirmClear();
                break;
            default:
                break;
        }
    }

}