import { DOCUMENT } from "@angular/common";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Component, EventEmitter, forwardRef, HostListener, Inject, Input, OnDestroy, Output } from "@angular/core";
import { firstValueFrom, Subscription } from "rxjs";
import * as svgPanZoom from "svg-pan-zoom";
import { ErrorMessages } from "../../../common/constants";
import { ProductModel, ProductModelTechnicalScheme, ProductModelTechnicalSchemeContentType } from "../../../model";
import { ExternalCatalogService, SvgClickEventData, SvgClickEventDataType, SvgObject } from "../../../service/external-catalog.service";
import { FlatTreeNode, FlatTreeService } from "../../../service/flat-tree.service";
import { ProductModelPartService } from "../../../service/product-model-part.service";
import { ProductModelService } from "../../../service/product-model.service";
import { TreeNode } from "../../../shared/component/tree-list/tree-list.component";
import { LoaderPipe } from "../../../shared/pipe";
import { ErrorUtility } from "../../../utility/error-utility";

@Component({
    selector: 'product-model-technical-scheme-selector',
    template: require('./product-model-technical-scheme-selector.component.html'),
    styles: [require('./product-model-technical-scheme-selector.component.css')]
})
export class ProductModelTechnicalSchemeSelectorComponent implements OnDestroy {

    @Input() set productModelId(value: string) {
        this._productModelId = value;
        this.selectedProductModelPartId = null;
        if (value) {
            this.init();
        } else {
            this.loaded = true;
        }
    }

    @Input() productModelPartId: string;

    @Output() productModelPartSelected = new EventEmitter();

    @Output() openSparePartDefinitionDetails = new EventEmitter();

    error: string;
    productModel: ProductModel;
    loaded: boolean;
    _productModelId: string;
    selectedProductModelPartId: string;
    visibleSchemes: ProductModelTechnicalScheme[];
    svgContainerPrefix: string = 'svg-container-';
    svgSchemeLoading: boolean;
    svgSchemeContent: { [tabIndex: number]: string } = {};
    productModelPartFlatTree: FlatTreeNode[];
    showProductModelPartMenu: boolean;
    breadcrumbTokens: FlatTreeNode[];

    private panZoom: SvgPanZoom.Instance;
    private externalCatalogSubscription: Subscription;
    private svgPositionMap: { [position: string]: SvgObject };

    constructor(
        @Inject(forwardRef(() => ProductModelService)) private productModelService: ProductModelService,
        @Inject(forwardRef(() => ProductModelPartService)) private productModelPartService: ProductModelPartService,
        @Inject(forwardRef(() => HttpClient)) private httpClient: HttpClient,
        @Inject(DOCUMENT) private document: Document,
        @Inject(forwardRef(() => LoaderPipe)) private loaderPipe: LoaderPipe,
        @Inject(forwardRef(() => ExternalCatalogService)) private externalCatalogService: ExternalCatalogService,
        @Inject(forwardRef(() => FlatTreeService)) private flatTreeService: FlatTreeService
    ) {
        this.subscribeToExternalCatalogService();
    }

    private init(): void {
        this.loaded = false;
        Promise.all([
            this.productModelService.getById(this._productModelId),
            this.productModelPartService.getRecursivelyAllProductModelParts(0, [], this.getExtraParams())
        ]).then(results => {
            this.productModel = results[0];
            const partialTree = this.productModelPartService.fillTreeNodes(results[1]);
            const newRoot: TreeNode = { id: null, name: this.productModel.name, description: this.productModel.description, children: partialTree, element: results[0] };
            this.productModelPartFlatTree = this.flatTreeService.getFlatTree([newRoot]);
            this.showProductModelPartMenu = partialTree?.length > 0;
            let selectedNode: FlatTreeNode;
            if (this.productModelPartId) {
                selectedNode = this.productModelPartFlatTree.find(node => node.id === this.productModelPartId);
                if (selectedNode) {
                    this.selectedProductModelPartId = this.productModelPartId;
                    this.visibleSchemes = selectedNode.element?.technicalSchemes;
                } else {
                    this.error = "Invalid model part";
                }
            } else {
                this.visibleSchemes = this.productModel.technicalSchemes;
                selectedNode = this.productModelPartFlatTree[0];
            }
            this.updateSelectedProductModelPartId(selectedNode);
            this.loaded = true;
            this.initSvgSchemes();
        }).catch(err => {
            this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR);
            this.loaded = true;
        });
    }

    private getExtraParams(): HttpParams {
        return new HttpParams().set('productModelId', this._productModelId);
    }

    updateSelectedProductModelPartId(node: FlatTreeNode): void {
        this.selectedProductModelPartId = node.id;
        this.visibleSchemes = node.element?.technicalSchemes;
        this.productModelPartSelected.emit({ id: this.selectedProductModelPartId, hasTechnicalSchemes: this.visibleSchemes?.length > 0 });
        this.initSvgSchemes();
        this.buildBreadcrumbTokens(node);
    }

    resetSelectedProductModelPartId(): void {
        this.selectedProductModelPartId = null;
        this.visibleSchemes = this.productModel.technicalSchemes;
        this.productModelPartSelected.emit({ id: this.selectedProductModelPartId, hasTechnicalSchemes: this.visibleSchemes?.length > 0 });
        this.initSvgSchemes();
        this.buildBreadcrumbTokens(this.productModelPartFlatTree[0]);
    }

    private initSvgSchemes(): void {
        this.destroyPanZoom();
        this.externalCatalogService.removeAllEventListeners();
        this.svgSchemeContent = {};
        if (this.visibleSchemes?.length) {
            this.buildTabTooltips();
            this.externalCatalogService.getSvgPositionMap(this._productModelId, this.selectedProductModelPartId).then(map => {
                this.svgPositionMap = map;
                this.schemeTabIndexChanged(0);
            });
        }
    }

    private loadSvg(schemeUrl: string, index: number): Promise<any> {
        return firstValueFrom(this.httpClient.get(schemeUrl, { headers: new HttpHeaders().append('Skip-Auth', 'true'), responseType: "text" })).then(text => {
            this.svgSchemeContent[index] = text;
        }).catch(err => this.svgSchemeContent[index] = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
    }

    schemeTabIndexChanged(index: number): void {
        this.destroyPanZoom();
        this.externalCatalogService.removeAllEventListeners();
        const scheme = this.visibleSchemes[index];
        if (scheme.contentType == ProductModelTechnicalSchemeContentType.SVG && scheme.imageUrl) {
            if (this.svgSchemeContent[index]) {
                this.buildSvg(index);
            } else {
                this.svgSchemeLoading = true;
                this.loadSvg(scheme.imageUrl, index).then(() => {
                    this.svgSchemeLoading = false;
                    this.buildSvg(index);
                });
            }
        }
    }

    private buildSvg(index: number): void {
        setTimeout(() => {
            const container = this.document.getElementById(this.svgContainerPrefix + index);
            container.innerHTML = this.svgSchemeContent[index];
            if (container.getElementsByTagName('svg') && container.getElementsByTagName('svg')[0]) {
                let svg = container.getElementsByTagName('svg')[0];
                svg.setAttribute('id', 'svg');
                svg.setAttribute('class', 'svg-element');
                this.applySchemeTransformer(svg);
                this.initPanZoom();
            }
        });
    }

    private initPanZoom(): void {
        const svg = this.document.getElementById('svg');
        this.panZoom = svgPanZoom(svg, {
            panEnabled: true,
            controlIconsEnabled: true,
            zoomEnabled: true,
            mouseWheelZoomEnabled: true,
            zoomScaleSensitivity: 0.6,
            fit: true,
            center: true
        });
    }

    private destroyPanZoom(): void {
        this.panZoom?.destroy();
        this.panZoom = null;
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        if (this.panZoom) {
            this.panZoom.resize();
            this.panZoom.fit();
            this.panZoom.center();
        }
    }

    private applySchemeTransformer(svg: SVGSVGElement): void {
        if (this.loaderPipe.isCustomFilter('svgProductSchemaTransformer')) {
            this.loaderPipe.transform($(svg), 'svgProductSchemaTransformer', null, { productModelId: this._productModelId, productModelPartId: this.selectedProductModelPartId, svgPositionMap: this.svgPositionMap });
        } else {
            const svgElements = svg.getElementsByTagName('text');
            Array.from(svgElements).forEach(el => {
                if (el.textContent && this.svgPositionMap && this.svgPositionMap[el.textContent]) {
                    this.externalCatalogService.addSvgClickableElement(el, { clickableClass: null, clickableObject: this.svgPositionMap[el.textContent] })
                }
            });
        };
    }

    private subscribeToExternalCatalogService(): void {
        this.externalCatalogSubscription = this.externalCatalogService.getSvgClickEventSubject().subscribe(event => {
            this.handleSvgEvent(event);
        });
    }

    private handleSvgEvent(event: SvgClickEventData): void {
        switch (event.eventType) {
            case SvgClickEventDataType.CHANGE_PRODUCT_MODEL_PART:
                const selectedNode = this.productModelPartFlatTree.find(node => node.id === event.elementId);
                if (selectedNode && !this.productModelPartId) {
                    this.updateSelectedProductModelPartId(selectedNode);
                }
                break;
            case SvgClickEventDataType.OPEN_SPARE_PART_DEFINITION_DETAILS:
                this.openSparePartDefinitionDetails.emit({ id: event.elementId, quantity: event.quantity });
                break;
        }
    }

    ngOnDestroy(): void {
        this.destroyPanZoom();
        this.externalCatalogService.removeAllEventListeners();
        if (this.externalCatalogSubscription) {
            this.externalCatalogSubscription.unsubscribe();
            this.externalCatalogSubscription = null;
        }

    }

    private buildTabTooltips(): void {
        setTimeout(() => {
            this.visibleSchemes?.forEach((scheme, index) => {
                const tabElement = this.document.getElementById('technical-scheme-tab-' + index);
                if (tabElement && scheme.previewUrl) {
                    (<any>$(tabElement)).tooltip({ title: `<img class="technical-scheme-image-preview" src="${scheme.previewUrl}">`, customClass: 'technical-scheme-image-preview-container', html: true, placement: 'bottom' });
                }
            });
        });
    }

    private buildBreadcrumbTokens(currentNode: FlatTreeNode): void {
        this.breadcrumbTokens = this.findParentNodesRecursively(currentNode, [currentNode]).reverse();
    }

    private findParentNodesRecursively(childNode: FlatTreeNode, nodes: FlatTreeNode[]): FlatTreeNode[] {
        if (childNode.level > 0) {
            let childIndex = (this.productModelPartFlatTree.findIndex(n => childNode.id == n.id));
            let parentCandidates = this.productModelPartFlatTree.slice(0, childIndex).filter(n => n.expandable && n.level == (childNode.level - 1));
            if (parentCandidates.length) {
                const parent = parentCandidates[parentCandidates.length - 1];   // the parent is the last one
                nodes.push(parent);
                return this.findParentNodesRecursively(parent, nodes);
            }
        }
        return nodes;
    }

}