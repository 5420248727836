import { Injectable } from "@angular/core";
import * as _ from 'lodash';
import { isEmpty } from '../../common/helper';
import { CustomPropertyDefinition } from "../../model";

@Injectable()
export class AdvancedSearchService {

    mergeThingProperties(allProperties: CustomPropertyDefinition[]): CustomPropertyDefinition[] {
        let thingProperties = _.cloneDeep(allProperties);
        let filteredProperties: CustomPropertyDefinition[] = [];
        thingProperties.forEach(vp => {
            if (vp.searchable) {
                let index = filteredProperties.findIndex(fp => fp.name == vp.name);
                if (index < 0) {
                    filteredProperties.push(vp);
                } else if (filteredProperties[index].type == vp.type && filteredProperties[index].id != vp.id) {

                    // merges values
                    if (vp.values?.length) {
                        filteredProperties[index].values?.length ? filteredProperties[index].values = filteredProperties[index].values.concat(vp.values) : filteredProperties[index].values = vp.values;
                    } else if (vp.dictionary?.length) {
                        filteredProperties[index].dictionary?.length ? filteredProperties[index].dictionary = filteredProperties[index].dictionary.concat(vp.dictionary) : filteredProperties[index].dictionary = vp.dictionary;
                    } else if (vp.ranges?.length) {
                        if (isEmpty(filteredProperties[index].minValue) || isEmpty(vp.minValue)) {
                            filteredProperties[index].minValue = null;
                        } else if (vp.minValue < filteredProperties[index].minValue) {
                            filteredProperties[index].minValue = vp.minValue;
                        }
                        if (isEmpty(filteredProperties[index].maxValue) || isEmpty(vp.maxValue)) {
                            filteredProperties[index].maxValue = null;
                        } else if (vp.maxValue > filteredProperties[index].maxValue) {
                            filteredProperties[index].maxValue = vp.maxValue;
                        }
                        if (filteredProperties[index].ranges?.length) {
                            const newRanges = vp.ranges.filter(el => !filteredProperties[index].ranges.some(range => range.to == el.to))
                            filteredProperties[index].ranges = filteredProperties[index].ranges.concat(newRanges).sort((a, b) => {
                                if (a.to === b.to) {
                                    return 0;
                                }
                                if (a.to === null) {
                                    return 1;
                                }
                                if (b.to === null) {
                                    return -1;
                                }
                                return a.to < b.to ? -1 : 1;
                            });
                        } else {
                            filteredProperties[index].ranges = vp.ranges;
                        }
                    }
                }
            }
        });
        return filteredProperties;
    }
}