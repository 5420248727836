import { Component, forwardRef, Inject, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FieldService } from '../../../service/field.service';
import { EventAdvancedSearchAddPropertiesDialog } from '../../advanced-search/event-advanced-search/event-advanced-search-add-properties-dialog.component';
import { EventAdvancedSearchComponent } from '../../advanced-search/event-advanced-search/event-advanced-search.component';
import { AbstractSearchFieldComponent } from '../abstract-search-field.component';
import { SearchFieldService } from '../search-field.service';
import { EventsPropertySearchInputComponent } from './events-property-search-input.component';

@Component({
    selector: 'events-search-field',
    template: require('./events-search-field.component.html'),
    styles: [require('../search-field.component.css')],
})
export class EventsSearchFieldComponent extends AbstractSearchFieldComponent {

    @Input() isHistorical: boolean;

    @ViewChild(EventAdvancedSearchComponent) eventAdvancedSearch: EventAdvancedSearchComponent;

    @ViewChild(EventAdvancedSearchAddPropertiesDialog) addPropertiesDialog: EventAdvancedSearchAddPropertiesDialog;

    @ViewChildren(EventsPropertySearchInputComponent) private eventsPropertySearchInputs: QueryList<EventsPropertySearchInputComponent>;

    constructor(
        @Inject(forwardRef(() => FieldService)) fieldService: FieldService,
        @Inject(forwardRef(() => SearchFieldService)) searchFieldService: SearchFieldService
    ) {
        super(fieldService, searchFieldService)
    }

    openAddPropertiesDialog(advancedSearchProperties: { name: string, label: string }[]): void {
        this.close();
        this.advancedSearchProperties = advancedSearchProperties;
        setTimeout(() => this.addPropertiesDialog.open(), 400);
    }

    closeAddPropertiesDialog(updatedSearchFields: string[]): void {
        if (updatedSearchFields) {
            this.eventAdvancedSearch.updateSearchFields(updatedSearchFields);
        }
        setTimeout(() => this.open(), 400);
    }

    performClear(): void {
        this.eventAdvancedSearch?.clearAll();
        this.clearInputs();
    }

    protected updateAdvancedSearch(fieldsToSaveBody: any): void {
        this.eventAdvancedSearch?.updateLocalStorage(fieldsToSaveBody);
        this.eventAdvancedSearch?.initAllConfigurations();
    }

    protected getEncodedRawValues(): void {
        let body: any = {};
        this.eventsPropertySearchInputs?.forEach(property => Object.assign(body, property.getFormValue(true)));
        return body;
    }

    protected getRawValues() {
        let body: any = {};
        this.eventsPropertySearchInputs?.forEach(property => Object.assign(body, property.getFormValue(false)));
        return body;
    }

    protected refreshInputConfigurations(): void {
        this.eventsPropertySearchInputs.forEach(input => {
            input.refreshConfiguration();
        });
    }

}