import { HttpParams } from '@angular/common/http';
import { Component, forwardRef, Inject, Input, NgZone, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { ErrorMessages } from '../../common/constants';
import { BulkUpdate } from '../../model/bulk-update';
import { AuthenticationService } from '../../service/authentication.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { CustomTableColumn, CustomTableService } from '../../shared/custom-table';
import { BulkUpdateCounterPipe, DateFormatterPipe, LocalizationPipe } from '../../shared/pipe';
import { ErrorUtility } from '../../utility/error-utility';
import { BulkUpdateStatusService } from './bulk-update-status.service';

@Component({
    selector: 'bulk-update-status-widget',
    template: require('./bulk-update-status-widget.component.html'),
    providers: [BulkUpdateStatusService, BulkUpdateCounterPipe]
})
export class BulkUpdateStatusWidgetComponent implements OnInit {

    @Input() title: string;

    @Input() showHeader: boolean = true;

    @Input() refreshIntervalMillis: number = 30000;

    @Input() description: string;

    loaded: boolean;
    error: string;
    readPermission: boolean;
    writePermission: boolean;
    bulkUpdates: BulkUpdate[] = [];
    dataSource = new MatTableDataSource<BulkUpdate>([]);
    displayedColumns: CustomTableColumn[];
    expandedRows: boolean[];

    private intervalId: any;
    private labels: string[];
    private bulkUpdatesParams: HttpParams;

    constructor(
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => BulkUpdateStatusService)) private bulkUpdateStatusService: BulkUpdateStatusService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService
    ) { }

    ngOnInit(): void {
        this.labels = [this.localizationPipe.transform("things updated"), this.localizationPipe.transform("unavailable"), this.localizationPipe.transform("errors")];
        this.title = this.title || 'bulkUpdateProperty';
        this.setBulkUpdatesParams();
        this.setDisplayedColumns();
        this.refreshTable();
        this.startRefreshing();
    }

    startRefreshing(): void {
        this.zone.runOutsideAngular(() => {
            this.intervalId = setInterval(() => {
                this.zone.run(() => this.refreshTable())
            }, this.refreshIntervalMillis)
        });
    }

    private refreshTable(): void {
        this.bulkUpdateStatusService.getBulkUpdates(this.bulkUpdatesParams).then(bulkUpdates => {
            this.loaded = true;
            if (!_.isEqual(this.bulkUpdates, bulkUpdates)) {
                this.loaded = false;
                if (this.bulkUpdates.length != bulkUpdates.length) {
                    this.setExpandedRows(bulkUpdates.length);
                }
                this.bulkUpdates = this.updateBulkUpdatesStatus(bulkUpdates);
                this.dataSource = new MatTableDataSource<BulkUpdate>(this.bulkUpdates);
                setTimeout(() => {
                    this.loaded = true;
                }, 0.2);
            }
        }).catch(err => {
            this.loaded = false;
            this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR);
            console.log(err);
        });
    }

    private updateBulkUpdatesStatus(bulkUpdates: BulkUpdate[]): BulkUpdate[] {
        bulkUpdates.forEach(bu => {
            if (bu.state == 'RUNNING' && bu.scheduleTimestamp == Number.MAX_SAFE_INTEGER) {
                bu.state = 'SUSPENDED';
            } else if (bu.state == 'RUNNING' && bu.scheduleTimestamp > Date.now()) {
                bu.state = 'SCHEDULED';
            }
        });
        return bulkUpdates;
    }

    private setDisplayedColumns(): void {
        this.displayedColumns = [
            CustomTableService.newPipedColumn('dateTime', 'dateProperty', 'dateTime', DateFormatterPipe).withArgument(this.authenticationService.getUser().timezone),
            CustomTableService.newPipedColumn('name', 'operationProperty', 'name', 'bulkUpdateLocalize'),
            CustomTableService.newPipedColumn('type', 'typeProperty', 'type', 'underscoreRemover'),
            CustomTableService.newPipedColumn('state', 'statusProperty', 'state', 'underscoreRemover'),
            CustomTableService.newBulkUpdateCountColumn('progress', 'progressProperty', 'itemStateCounts', BulkUpdateCounterPipe, this.labels),
            CustomTableService.newTimerColumn('duration', 'testDurationProperty', 'creationTimestamp', 'lastUpdateTimestamp')
        ];
    }

    stopRefreshing(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    private setExpandedRows(size: number): void {
        this.expandedRows = new Array(size);
        this.expandedRows.fill(false);
    }

    ngOnDestroy(): void {
        this.stopRefreshing();
    }

    changeBulkUpdateState(bulkUpdate: BulkUpdate, state: string): void {
        const body = {
            state: state
        };
        this.bulkUpdateStatusService.updateBulkUpdate(bulkUpdate.id, body).then(() => {
            this.refreshTable();
        }).catch(err => {
            console.log(err);
            this.refreshTable();
        });
    }

    downloadBulkUpdate(bulkUpdate: BulkUpdate): void {
        this.bulkUpdateStatusService.downloadBulkUpdateCSV(bulkUpdate.id);
    }

    updateScheduleBulkUpdate(bulkUpdate: BulkUpdate, removeSchedule: boolean): void {
        const body = {
            scheduleTimestamp: removeSchedule ? Number.MAX_SAFE_INTEGER : bulkUpdate.scheduleTimestamp,
            state: "RUNNING"
        };
        this.bulkUpdateStatusService.updateBulkUpdate(bulkUpdate.id, body).then(() => {
            this.refreshTable();
            this.startRefreshing();
        }).catch(err => {
            console.log(err);
            this.refreshTable();
            this.startRefreshing();
        });
    }

    private setBulkUpdatesParams(): void {
        this.bulkUpdatesParams = new HttpParams();
        if (this.contextService.getCurrentLocation() != null) {
            this.bulkUpdatesParams = this.bulkUpdatesParams.set('locationId', this.contextService.getCurrentLocation().id);
        } else if (this.contextService.getCurrentCustomer() != null) {
            this.bulkUpdatesParams = this.bulkUpdatesParams.set('customerId', this.contextService.getCurrentCustomer().id);
        } else if (this.contextService.getCurrentPartner() != null) {
            this.bulkUpdatesParams = this.bulkUpdatesParams.set('partnerId', this.contextService.getCurrentPartner().id);
        } else if (this.authenticationService.isLocationUser()) {
            this.bulkUpdatesParams = this.bulkUpdatesParams.set('locationId', this.authenticationService.getUser().locationId);
        } else if (this.authenticationService.isCustomerUser()) {
            this.bulkUpdatesParams = this.bulkUpdatesParams.set('customerId', this.authenticationService.getUser().customerId);
        } else if (this.authenticationService.isPartnerUser()) {
            this.bulkUpdatesParams = this.bulkUpdatesParams.set('partnerId', this.authenticationService.getUser().partnerId);
        } else if (this.authenticationService.isOrganizationUser()) {
            this.bulkUpdatesParams = this.bulkUpdatesParams.set('organizationId', this.authenticationService.getUser().organizationId);
        }
    }
}
