import { Component, forwardRef, Inject } from '@angular/core';
import { FieldService } from '../../../service/field.service';
import { AbstractSearchFieldComponent } from '../abstract-search-field.component';
import { SearchFieldService } from '../search-field.service';

@Component({
    selector: 'actions-search-field',
    template: require('./actions-search-field.component.html'),
    styles: [require('../search-field.component.css')],
})
export class ActionsSearchFieldComponent extends AbstractSearchFieldComponent {

    constructor(
        @Inject(forwardRef(() => FieldService)) fieldService: FieldService,
        @Inject(forwardRef(() => SearchFieldService)) searchFieldService: SearchFieldService
    ) {
        super(fieldService, searchFieldService)
    }

    openAddPropertiesDialog(advancedSearchProperties: { name: string, label: string }[]): void {
    }

    closeAddPropertiesDialog(updatedSearchFields: string[]): void {
    }

    performClear(): void {
        this.clearInputs();
    }

    protected updateAdvancedSearch(fieldsToSaveBody: any): void {
    }

    protected getEncodedRawValues(): void {
        let body: any = {};
        return body;
    }

    protected getRawValues() {
        let body: any = {};
        return body;
    }

    protected refreshInputConfigurations(): void {
        this.filtered = false;
    }
}