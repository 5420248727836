import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { ErrorMessages } from '../../common/constants';
import { ActionDefinition, ActionStatusCount } from '../../model';
import { ActionContextService } from '../../service/action-context.service';
import { AuthenticationService } from '../../service/authentication.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';
import { CustomTableColumn, CustomTableService, StyleMap } from '../../shared/custom-table';
import { ErrorUtility } from '../../utility/error-utility';
import { GroupedActionListService } from './grouped-action-list.service';

@Component({
    selector: 'grouped-action-list-widget',
    template: require('./grouped-action-list.component.html'),
    providers: [GroupedActionListService]
})

export class GroupedActionListComponent implements OnInit {

    @Input() title: string;

    @Input() topic: string[];

    @Input() type: string[];

    @Input() template: string;

    @Input() status: string[];

    @Input() description: string;

    error: string;
    loaded: boolean;
    readPermission: boolean;
    actionStatusCounts: ActionStatusCount[];
    dataSource = new MatTableDataSource<ActionStatusCount>([]);
    id = 'GroupedActionList_' + GroupedActionListService.GROUP_ACTION_LIST_WIDGET_ID++;
    displayedColumns: CustomTableColumn[] = [];

    private actionDefinitions: ActionDefinition[];
    private styleMap: StyleMap = {
        'LOW': { 'border-left': 'solid 5px #00a65a' },
        'NORMAL': { 'border-left': 'solid 5px #f39c12' },
        'HIGH': { 'border-left': 'solid 5px #dd4b39' }
    };
    private subscription: Subscription;

    constructor(
        @Inject(forwardRef(() => GroupedActionListService)) private groupedActionListService: GroupedActionListService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => ActionContextService)) private actionContextService: ActionContextService
    ) { }

    ngOnInit() {
        this.readPermission = this.authenticationService.isCustomerUser() || this.authenticationService.isLocationUser()
            || ((this.authenticationService.isOrganizationUser() || this.authenticationService.isPartnerUser()) && !!this.contextService.getCurrentCustomer() && !this.contextService.getCurrentLocation() && !this.thingContextService.getCurrentThing());
        if (this.readPermission) {
            this.displayedColumns = this.getDisplayedColumns();
            this.groupedActionListService.getAllActionDefinition().then(actionDefinitions => {
                this.actionDefinitions = actionDefinitions;
                this.getActionGroupList();
            }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
            this.subscription = this.actionContextService.getRefreshEventSubject().subscribe(() => this.getActionGroupList());
        }
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }

    private getActionGroupList(): void {
        let body = [];
        body['topics'] = this.topic;
        body['actionDefinitionIds'] = this.getActionDefinitionIdsFromNames();
        body['states'] = this.status;
        this.groupedActionListService.getActionStatusCount(body).then(actionStatusCounts => {
            this.actionStatusCounts = this.updateRowClass(actionStatusCounts);
            this.dataSource = new MatTableDataSource<ActionStatusCount>(this.actionStatusCounts);
            this.loaded = true;
        }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
    }

    private getActionDefinitionIdsFromNames(): string[] {
        let ids = [];
        if (this.type && this.actionDefinitions?.length) {
            this.type.forEach(actionName => {
                const actionDef = this.actionDefinitions.find(definition => actionName == definition.name)
                if (actionDef) {
                    ids.push(actionDef.id);
                }
            });
        }
        return ids;
    }

    private getDisplayedColumns(): CustomTableColumn[] {
        let columns = [
            CustomTableService.newSimpleColumn('empty', '', 'empty').withStyleByVariable(this.styleMap, 'priority'),
            CustomTableService.newImageColumn('icon', '', 'iconUrl'),
            CustomTableService.newSimpleColumn('name', 'actionNameProperty', 'name'),
            CustomTableService.newSimpleColumn('count', 'totalActionsProperty', 'count'),
            CustomTableService.newSimpleColumn('expiredCount', 'overdueActionsProperty', 'expiredCount')
        ];
        return columns;
    }

    buildQuery(group: ActionStatusCount): string {
        let queryObjects: string[] = [];
        const actionDefinitionValue = `['${group.actionDefinitionId}']`;
        queryObjects.push(`{ 'property': 'actionDefinitionIds', 'predicate': 'eq', 'value': ${actionDefinitionValue} }`);
        if (this.status?.length) {
            const statusValue = `['${this.status.join(`','`)}']`;
            queryObjects.push(`{ 'property': 'status', 'predicate': 'eq', 'value': ${statusValue} }`);
        }
        return '[' + queryObjects.join(',') + ']';
    }

    private updateRowClass(actionStatusCounts: ActionStatusCount[]): ActionStatusCount[] {
        if (actionStatusCounts?.length > 0) {
            actionStatusCounts.forEach(as => {
                as['rowClass'] = as.priority;
            });
        }
        return actionStatusCounts;
    }

    getActionDefinition(group: ActionStatusCount): ActionDefinition {
        const actionDef = this.actionDefinitions.find(ad => ad.id == group.actionDefinitionId);
        return actionDef || null;
    }
}