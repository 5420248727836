import { Component, forwardRef, Inject, NgZone, OnDestroy, OnInit, QueryList, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { NavigationStart, Router } from '@angular/router';
import * as _ from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SOCKET_TOPIC_THING_LIST } from '../../../../common/endpoints';
import { PropertyInfo } from '../../../../common/properties';
import { CustomMatPaginatorIntl } from '../../../../dashboard-area/shared/custom-mat-paginator-intl.service';
import { Location, Tag, Thing, ThingDataItem } from '../../../../model';
import { AppService } from '../../../../service/app.service';
import { AuthenticationService } from '../../../../service/authentication.service';
import { PropertyService } from '../../../../service/property.service';
import { RefreshableWidget, RefresherWidgetService } from '../../../../service/refresher-widget.service';
import { AbstractContextService } from '../../../../shared/class/abstract-context-service.class';
import { AbstractThingContextService } from '../../../../shared/class/abstract-thing-context-service.class';
import { CompositePartComponent, MetricDetailComponent, PropertyComponent } from '../../../../shared/component';
import { CustomTableComponent } from '../../../../shared/custom-table';
import { TagService } from '../../../../shared/tags/tag.service';
import { ErrorUtility } from '../../../../utility/error-utility';
import { ThingListWidgetV2Service } from '../../../../widget/thing-list/thing-list-widget-v2.service';
import { ListWidgetV2Component } from '../../../list-widget-v2/list-widget-v2.components';

@Component({
    selector: 'thing-selection-input-dialog',
    template: require('./thing-selection-input-dialog.component.html'),
    styles: [require('../../../list-widget-v2/list-widget-v2.css')],
    providers: [ThingListWidgetV2Service, TagService, { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl }]
})
export class ThingSelectionInputDialogComponent extends ListWidgetV2Component<Thing> implements OnInit, OnDestroy, RefreshableWidget {

    @ViewChild(CustomTableComponent) customTable: CustomTableComponent;

    things: Thing[];
    location: Location;
    currentThing: Thing;
    maxSelectableElements: number = 100;
    queryId: string;
    propertySearchFields: string[] = [];

    private thingsSubjects: { [thingId: string]: BehaviorSubject<ThingDataItem[]> } = {};
    private metricNames = new Set<string>();
    private unregisterFn: Function;
    private tags: Tag[];
    private columnComponents: QueryList<MetricDetailComponent | CompositePartComponent | PropertyComponent>;
    private selectedThingIdsInitCompleted: boolean;
    private routerEventSubscription: Subscription;

    constructor(
        @Inject(forwardRef(() => ThingListWidgetV2Service)) private thingListWidgetService: ThingListWidgetV2Service,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => AuthenticationService)) authenticationService: AuthenticationService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => PropertyService)) private propertyService: PropertyService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone,
        @Inject(forwardRef(() => RefresherWidgetService)) private refresherWidgetService: RefresherWidgetService,
        @Inject(forwardRef(() => AppService)) appService: AppService,
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<ThingSelectionInputDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        @Inject(forwardRef(() => Router)) private router: Router
    ) {
        super(appService, authenticationService, null, null, null, null);
        this.columnComponents = data.columnComponents;
        this.queryId = data.queryId;
        this.query = data.query;
        this.propertySearchFields = data.propertySearchFields;
    }

    private defaultProperties: { [name: string]: PropertyInfo } = {
        name: { label: 'thingNameProperty', path: 'name', defaultFilter: null, defaultSorting: null },
        serialNumber: { label: 'serialNumberProperty', path: 'serialNumber', defaultFilter: null, defaultSorting: null },
        gpsPosition: { label: 'gpsPositionProperty', path: 'gpsPosition', defaultFilter: null, defaultSorting: null },
        serviceLevel: { label: 'serviceLevelProperty', path: 'serviceLevel', defaultFilter: 'defaultServiceLevel', defaultSorting: 'service-level-sorting' },
        "customer.name": { label: 'customerNameProperty', path: 'customer.name', defaultFilter: null, defaultSorting: null },
        "location.name": { label: 'locationNameProperty', path: 'location.name', defaultFilter: null, defaultSorting: null }
    };

    ngOnInit(): void {
        this.location = this.contextService.getCurrentLocation();
        this.unregisterFn = this.refresherWidgetService.register(this);
        this.currentThing = this.thingContextService.getCurrentThing();
        this.preserveSelectedBetweenPages = true;
        this.pageSize = 10;
        this.fillerRowCount = 10;
        this.subscribeToRouterEvents();
    }

    ngAfterContentInit(): void {
        this.tags = this.contextService.getTagObjects();
        this.displayedColumns = this.thingListWidgetService.getVisibleColumns(this.columnComponents.toArray(), this.defaultProperties, 'Thing');
        this.descriptions = this.thingListWidgetService.getColumnDescriptions(this.columnComponents.toArray());
        this.metricNames = this.thingListWidgetService.getMetricNames(this.columnComponents);
        this.sort = this.thingListWidgetService.setDefaultSort(this.displayedColumns);
    }

    private getThingList(): void {
        if (!this.selectedThingIdsInitCompleted) {
            const savedFieldsValues = localStorage.getItem(this.queryId) ? JSON.parse(localStorage.getItem(this.queryId)) : null;
            this.selectedElements = savedFieldsValues?.selectedThingIds?.map(id => { return { id: id } }) || [];
            this.selectedThingIdsInitCompleted = true;
        }
        this.thingListWidgetService.getPagedList(this.pageIndex, this.pageSize, this.sort, this.metricNames, null, this.advancedSearchBody, this.location, true, false, this.currentThing, false).then(pagedList => {
            this.things = pagedList.content;
            this.error = null;
            this.subscribeToThings();
            this.thingListWidgetService.addTags(pagedList.content, this.tags || []);
            this.updateElementList(pagedList);
        }).catch(err => this.error = ErrorUtility.getMessage(err));
    }

    private subscribeToThings(): void {
        for (let thing of this.things) {
            let thingSubject = this.propertyService.subscribeToThingProperties(thing.id);
            this.thingsSubjects[thing.id] = thingSubject;
            thingSubject.subscribe((thingEvent: ThingDataItem[]) => this.updateProperties(thing.id, thingEvent));
        }
    }

    private updateProperties(thingId: string, thingEvent: ThingDataItem[]): void {
        if (thingEvent) {
            let thing = this.things.find(t => t.id == thingId);
            for (let thingDataItem of thingEvent) {
                _.set(thing, PropertyService.getFieldName(thingDataItem), thingDataItem.value);
            }
            this.zone.run(() => this.dataSource.data = this.things);
        }
    }

    ngOnDestroy(): void {
        if (this.unregisterFn) {
            this.unregisterFn();
        }
        this.unsubscribeAll();
        if (this.routerEventSubscription) {
            this.routerEventSubscription.unsubscribe();
            this.routerEventSubscription = null;
        }
    }

    private unsubscribeAll(): void {
        for (let thingId in this.thingsSubjects) {
            this.propertyService.usubscribeFromThingProperties(thingId);
        }
        this.thingsSubjects = {};
    }

    getRefreshTopic(): string {
        let userId = this.authenticationService.getUser().id;
        return SOCKET_TOPIC_THING_LIST.replace('{userId}', userId);
    }

    refresh(): void {
        setTimeout(() => {
            this.zone.run(() => {
                this.loaded = false;
                this.unsubscribeAll();
                this.getThingList();
            });
        }, 1000);
    }

    refreshList(data?: { pageIndex: number, pageSize: number, advancedSearchBody: any, sort: string[] }): void {
        if (data) {
            this.pageIndex = data.pageIndex;
            this.pageSize = data.pageSize;
            this.advancedSearchBody = data.advancedSearchBody;
            this.sort = data.sort;
        }
        this.normalizeAdvancedSearchBody();
        this.unsubscribeAll();
        this.getThingList();
    }

    goToDetail(): void {
        /* do nothing */
    }

    subscribeToExportServices(): void {
        /* do nothing */
    }

    confirm(): void {
        const thingIds = this.getSelectedElementIds();
        this.dialogRef.close(thingIds);
    }

    private normalizeAdvancedSearchBody(): any {
        if (this.advancedSearchBody) {
            delete this.advancedSearchBody.selectedThingIds;
        }
    }

    private subscribeToRouterEvents(): void {
        this.routerEventSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.dialogRef.close();
            }
        });
    }

}

