import { Directive, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { NgForm } from '@angular/forms';
import { User } from "../../model";
import { DynamicModalComponent } from "../../shared/component";

let nextId = 0;

@Directive()
export abstract class AbstractAdvancedSearchAddPropertiesDialog {

    @Input() properties: { name: string, label: string }[] = [];

    @Output() saveAction = new EventEmitter<string[]>();

    @Output() closeAction = new EventEmitter();

    @ViewChild(DynamicModalComponent) dialog: DynamicModalComponent;

    @ViewChild('propertiesForm') propertiesForm: NgForm;

    id: string;
    error: string;

    protected selectedProperties: string[];
    protected user: User;

    protected abstract updateSearchFields(): void;

    protected abstract save(): void;

    ngOnInit() {
        this.updateSearchFields();
        this.id = 'advanced-search-add-properties-dialog-' + nextId++;
    }

    close(): void {
        this.dialog.close();
    }

    open(): void {
        this.updateSearchFields();
        this.error = null;
        this.resetStatus();
        this.dialog.open();
    }

    resetStatus(): void {
        const rawValues = this.propertiesForm.form.getRawValue();
        Object.keys(rawValues).forEach(key => {
            this.propertiesForm.controls[key].reset(this.isPropertyChecked(key));
        });
    }

    isPropertyChecked(name: string): boolean {
        return this.selectedProperties ? this.selectedProperties.some(el => el == name) : false;
    }

    emitCloseAction(): void {
        this.closeAction.emit();
    }

}