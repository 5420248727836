import { animate, state, style, transition, trigger } from "@angular/animations";
import { SelectionModel } from "@angular/cdk/collections";
import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { AfterViewInit, Component, ContentChild, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, forwardRef } from "@angular/core";
import { Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AppService } from "../../service/app.service";
import { CustomTableColumn } from "./custom-table-column/custom-table-column";

@Component({
    selector: 'custom-table',
    template: require('./custom-table.component.html'),
    styles: [require('./custom-table.component.css')],
    animations: [
        trigger('detailExpand', [
            state('void', style({ height: '0', minHeight: '0', visibility: 'hidden' })),
            state('*', style({ height: '*', visibility: 'visible' })),
            transition('void <=> *', animate('1000ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
        trigger('rotateArrow', [
            state('closed', style({ transform: 'rotate(0)' })),
            state('open', style({ transform: 'rotate(180deg)' })),
            transition('open <=> closed', animate('1000ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ])
    ],
})
export class CustomTableComponent implements OnInit, AfterViewInit {

    @Input() dataSource: MatTableDataSource<any>;

    @Input() displayedColumns: CustomTableColumn[];

    @Input() leftArrow: boolean;

    @Input() alwaysExpandCondition: { path: string, condition: string, value?: any };

    @Input() initiallyExpandedRows: boolean[] = [];

    @Input() sort: any[] = ['', ''];

    @Input() enableDragAndDrop: boolean;

    @Input() enableSelect: boolean;

    @Input() descriptions: string[] = [];

    @Input() useExternalArrowEvent: boolean;

    @Input() elementExpandableCondition: { path: string, condition: string, value?: any };

    @Input() clickableList: boolean;

    @Input() enabledAdvancedSelect: boolean;

    @Input() selectedElements: any[] = [];

    @Input() maxSelectableElements: number;

    @Output() rowClickAction = new EventEmitter();

    @Output() rowClickIndexAction = new EventEmitter();

    @Output() buttonAction = new EventEmitter();

    @Output() sortAction = new EventEmitter();

    @Output() dragAndDropAction = new EventEmitter();

    @Output() elementSelectedAction = new EventEmitter();

    @Output() arrowAction = new EventEmitter();

    @ContentChild(TemplateRef) templateRef: TemplateRef<any>;

    arrowStatuses: string[];
    columns: string[];
    isExpandable: boolean;
    selection = new SelectionModel<any>(true, []);
    disableAnimations: boolean;

    constructor(
        @Inject(forwardRef(() => AppService)) private appService: AppService
    ) { }

    ngOnInit(): void {
        this.arrowStatuses = new Array(this.dataSource.data.length).fill('closed');
        this.initiallyExpandedRows.forEach((e, i) => {
            if (e) {
                this.arrowStatuses[i] = 'open';
            }
        });
        this.columns = this.displayedColumns.map(c => c.name);
        this.disableAnimations = !this.appService.isMobile();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.isExpandable = this.templateRef != undefined;
            if (this.isExpandable || this.useExternalArrowEvent) {
                this.addArrow();
            }
            if (this.enableDragAndDrop) {
                this.columns = ['dragHandle'].concat(this.columns);
            }
            if (this.enableSelect) {
                this.selection = new SelectionModel<any>(true, []);
                this.columns = ['select'].concat(this.columns);
            }
        });
    }

    private addArrow(): void {
        if (this.leftArrow) {
            this.columns = ['openArrow'].concat(this.columns);
        } else {
            this.columns.push('openArrow');
        }
    }

    changeStatus(index: number): void {
        if (!this.isAlwaysExpanded(index)) {
            this.arrowStatuses[index] = this.arrowStatuses[index] == 'closed' ? 'open' : 'closed';
            if (this.initiallyExpandedRows) {
                this.initiallyExpandedRows[index] = !this.initiallyExpandedRows[index];
            }
            this.arrowAction.emit({ status: this.arrowStatuses[index], index: index });
        }
    }

    getStyleByValue(column: CustomTableColumn, element: any): object {
        const style = column.style;
        const styleVariable = column.styleVariable;
        const value = element[styleVariable || column.path];
        if (!style) {
            return null;
        }
        if (style["_any"]) {
            return style["_any"];
        }
        if (style[value]) {
            return style[value];
        } else {
            return style["_default"];
        }
    }

    isAlwaysExpanded(index: number): boolean {
        let element = this.dataSource.data[index];
        if (!this.alwaysExpandCondition) {
            return false;
        }
        let value = element[this.alwaysExpandCondition.path];
        let condition = this.alwaysExpandCondition.condition;
        return this.checkCondition(value, condition, this.alwaysExpandCondition.value);
    }

    clickRow(event: any, element: any, index: number, column: CustomTableColumn): void {
        if (column?.path?.includes("technicalDescription")) {
            event.stopPropagation();
        } else {
            if (this.clickableList) {
                event.stopPropagation();
            }
            this.rowClickAction.emit(element);
            this.rowClickIndexAction.emit(index);
        }
    }

    sortChange(sort: Sort): void {
        this.sortAction.emit(sort);
    }

    onListDrop(event: CdkDragDrop<any[]>): void {
        this.dragAndDropAction.emit(event);
    }

    handleIsAllSelected() {
        if (this.enabledAdvancedSelect) {
            return this.isAllAdvancedSelected();
        } else {
            return this.selection.hasValue() && this.isAllSelected()
        }
    }

    private isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    private isAllAdvancedSelected(): boolean {
        return this.selectedElements.length > 0 && this.dataSource.data.every(dataEl => this.selectedElements.some(el => el.id == dataEl.id));
    }


    handleElementToggle($event: any, row: any): void {
        if ($event) {
            if (this.enabledAdvancedSelect) {
                this.elementAdvancedToggle(row);
            } else {
                this.elementToggle(row);
            }
        }
    }

    private elementToggle(row: any): void {
        this.selection.toggle(row);
        this.elementSelectedAction.emit(this.selection.selected);
    }

    private elementAdvancedToggle(row: any) {
        if (this.isSelected(row)) {
            const index = this.selectedElements.findIndex(el => el.id == row.id);
            this.selectedElements.splice(index, 1);
        } else {
            this.selectedElements.push(row);
        }
        this.elementSelectedAction.emit(this.selectedElements);
    }

    handleMasterToggle(): void {
        if (this.enabledAdvancedSelect) {
            this.masterAdvancedToggle();
        } else {
            this.masterToggle();
        }
    }

    private masterToggle(): void {
        if (this.isAllSelected()) {
            this.selection.clear();
        } else {
            this.selection.select(...this.dataSource.data);
        }
        this.elementSelectedAction.emit(this.selection.selected);
    }

    private masterAdvancedToggle(): void {
        if (this.isAllAdvancedSelected()) {
            this.dataSource.data.forEach(dataEl => {
                this.elementAdvancedToggle(dataEl);
            });
        } else {
            this.dataSource.data.forEach(dataEl => {
                if (!this.isSelected(dataEl)) {
                    this.selectedElements.push(dataEl);
                }
            });
        }
        this.elementSelectedAction.emit(this.selectedElements);
    }

    isElementExpandable(index: number): boolean {
        if (!this.elementExpandableCondition) {
            return true;
        }
        let element = this.dataSource.data[index];
        let value = element[this.elementExpandableCondition.path];
        let condition = this.elementExpandableCondition.condition;
        return this.checkCondition(value, condition, this.elementExpandableCondition.value);
    }

    private checkCondition(value: any, condition: string, conditionValue: any): boolean {
        switch (condition) {
            case "IS_NULL":
                return !value;
            case "EQUALS":
                return value == conditionValue;
            case "GREATER":
                return value != null && value > conditionValue;
            default:
                return false;
        }
    }

    getRowLevelStyle(level: number): string {
        return level * 32 + 'px';
    }

    updateArrowStatuses(index: number, elementsToRemove: number, elementsToAdd: string[]): void {
        this.arrowStatuses.splice(index, elementsToRemove, ...elementsToAdd);
    }

    isSelected(row: any): boolean {
        if (this.enabledAdvancedSelect) {
            return this.selectedElements.some(selectedEl => selectedEl.id == row.id);
        } else {
            return this.selection.isSelected(row);
        }
    }

    isSelectElementDisabled(row: any): boolean {
        if (this.enabledAdvancedSelect) {
            return this.maxSelectableElements && !this.isSelected(row) && this.maxSelectableElements <= this.selectedElements?.length;
        } else {
            return this.maxSelectableElements && !this.isSelected(row) && this.maxSelectableElements <= this.selection.selected.length;
        }
    }

    isSelectAllDisabled(): boolean {
        if (!this.maxSelectableElements) {
            return false;
        }
        if (this.enabledAdvancedSelect) {
            const previousPageSelectedElements = this.selectedElements.filter(selectedeEl => !this.dataSource.data.some(el => el.id == selectedeEl.id));
            return !this.handleIsAllSelected() && this.maxSelectableElements < (this.dataSource.data.length + previousPageSelectedElements?.length);
        } else {
            return !this.handleIsAllSelected() && this.maxSelectableElements < this.dataSource.data.length;
        }
    }
}