import { Directive, EventEmitter, forwardRef, Inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { DateRange } from "@angular/material/datepicker";
import * as _ from 'lodash';
import * as moment from "moment";
import { CustomPropertyDefinition } from "../../../model";
import { CustomLabels, CustomLabelService } from "../../../service/custom-label.service";
import { CustomPropertyService, CustomPropertyType } from "../../../service/custom-property.service";
import { FormEditorComponent } from "../../../shared/form-editor/form-editor.component";
import { AdvancedSearchDynamicInputsComponent } from "../../advanced-search/advanced-search-dynamic-inputs.component";
import { SearchFieldService } from "../search-field.service";

@Directive()
export abstract class AbstractPropertySearchInputComponent implements OnInit {

    @Input() property: string;

    @Input() localStorageName: string;

    @Input() rangeSelectionEnabled: boolean;

    @Output() searchAction = new EventEmitter();

    @ViewChild(FormEditorComponent) private formEditor: FormEditorComponent;

    @ViewChild(AdvancedSearchDynamicInputsComponent) private propertiesEditor: AdvancedSearchDynamicInputsComponent;

    thingProperties: CustomPropertyDefinition[];
    customerProperties: CustomPropertyDefinition[];
    locationProperties: CustomPropertyDefinition[];
    thingDefinitionProperties: CustomPropertyDefinition[];
    partnerProperties: CustomPropertyDefinition[];
    defaultProperties: { name: string, label: string }[] = [];
    searchFields: string[];
    advancedSearchConfiguration: any[] = null;

    protected savedFieldsValues: any;
    protected customLabels: CustomLabels;
    protected allThingProperties: CustomPropertyDefinition[];

    protected abstract handleDefaultProperty(): void;
    protected abstract handleValue(body: any, isEncoded: boolean): void;
    protected abstract initConfiguration(): void;

    constructor(
        @Inject(forwardRef(() => CustomPropertyService)) private customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => CustomLabelService)) private customLabelService: CustomLabelService,
        @Inject(forwardRef(() => SearchFieldService)) protected searchFieldService: SearchFieldService
    ) { }

    ngOnInit(): void {
        this.allThingProperties = this.customPropertyService.getCustomPropertyDefinitionByType(CustomPropertyType.Thing).filter(def => def.searchable);
        this.handleThingProperties();
        this.customerProperties = this.customPropertyService.getCustomPropertyDefinitionByType(CustomPropertyType.Customer).filter(def => def.searchable);
        this.locationProperties = this.customPropertyService.getCustomPropertyDefinitionByType(CustomPropertyType.Location).filter(def => def.searchable);
        this.thingDefinitionProperties = this.customPropertyService.getCustomPropertyDefinitionByType(CustomPropertyType.ThingDefinition).filter(def => def.searchable);
        this.partnerProperties = this.customPropertyService.getCustomPropertyDefinitionByType(CustomPropertyType.Partner).filter(def => def.searchable);
        this.savedFieldsValues = localStorage.getItem(this.localStorageName) ? JSON.parse(localStorage.getItem(this.localStorageName)) : null;
        this.initConfiguration();
    }

    protected handleThingProperties(): void {
        this.thingProperties = _.cloneDeep(this.allThingProperties);
    }

    emitSearch(): void {
        this.searchAction.emit();
    }

    getFormValue(isEncoded: boolean): any {
        if (this.formEditor) {
            return this.handleValue(this.formEditor?.getObjectValue(), isEncoded);
        } else if (this.propertiesEditor) {
            return isEncoded ? this.propertiesEditor?.getEncodedBody() : this.propertiesEditor?.getBody();
        } else {
            return {};
        }
    }

    protected useDefaultProperty(): void {
        this.customLabelService.getCustomLabels().then(customLabels => {
            this.customLabels = customLabels;
            this.handleDefaultProperty();
        }).catch(() => this.handleDefaultProperty());
    }

    refreshConfiguration(): void {
        let tmp;
        this.savedFieldsValues = localStorage.getItem(this.localStorageName) ? JSON.parse(localStorage.getItem(this.localStorageName)) : null;
        if (this.searchFields?.length) {
            tmp = _.cloneDeep(this.searchFields);
            this.searchFields = null;
            setTimeout(() => this.searchFields = tmp, 200);
        } else if (this.advancedSearchConfiguration?.length) {
            tmp = _.cloneDeep(this.advancedSearchConfiguration[0]);
            this.advancedSearchConfiguration = null;
            if (this.property == 'period') {
                tmp['value'] = this.getDateRange();
            } else {
                tmp['value'] = this.searchFieldService.getValueFromFieldsValues(tmp['name'], this.savedFieldsValues);
            }
            setTimeout(() => this.advancedSearchConfiguration = [tmp], 200);
        }
    }

    protected getDateRange(): { range: DateRange<moment.Moment>, rangeName: string } {
        const rangeName = this.searchFieldService.getValueFromFieldsValues('rangeName', this.savedFieldsValues);
        if (rangeName) {
            return { range: null, rangeName: rangeName };
        } else {
            const start = this.searchFieldService.getValueFromFieldsValues('startTimestamp', this.savedFieldsValues);
            const end = this.searchFieldService.getValueFromFieldsValues('endTimestamp', this.savedFieldsValues);
            if (start && end) {
                return { range: new DateRange(moment(start), moment(end)), rangeName: null };
            }
        }
        return null;
    }

}