import { AfterContentInit, Component, ContentChildren, EventEmitter, forwardRef, Inject, Input, OnInit, Output, QueryList } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Permissions } from '../../common/constants';
import { PropertyInfo } from '../../common/properties';
import { CustomMatPaginatorIntl } from '../../dashboard-area/shared/custom-mat-paginator-intl.service';
import { Customer } from '../../model';
import { AbstractExportContextService } from '../../service/abstract-export-context.service';
import { AddButtonContextService, AddButtonResource } from '../../service/add-button-context.service';
import { AppService } from '../../service/app.service';
import { AuthenticationService } from '../../service/authentication.service';
import { NavigationService } from '../../service/navigation.service';
import { BulkDeleteElementType } from '../../shared/component/bulk-delete-dialog/bulk-delete-dialog.component';
import { CustomTableService } from '../../shared/custom-table';
import { AdvancedSearchLayoutType, BulkControl, ColumnComponentDisplayMode, ListWidgetV2Component } from '../../shared/list-widget-v2/list-widget-v2.components';
import { CapitalizePipe } from '../../shared/pipe/index';
import { SearchTargetType } from '../../shared/search-field/search-field.component';
import { COMPONENT_DEFINITION_REF } from "../../shared/utility/component-definition-token";
import { ErrorUtility } from '../../utility/error-utility';
import { SnackbarService } from '../shared/snackbar.service';
import { CustomerListWidgetV2Service } from './customer-list-widget-v2.service';

@Component({
    selector: 'customer-list-widget-v2',
    template: require('./customer-list-widget-v2.component.html'),
    styles: [require('../../shared/list-widget-v2/list-widget-v2.css')],
    providers: [CustomerListWidgetV2Service, { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl }]
})
export class CustomerListWidgetV2Component extends ListWidgetV2Component<Customer> implements OnInit, AfterContentInit {

    @Input() searchFields: string[] = ["name", "code"];

    @Input() enableActions = true;

    @Input() selectableList: boolean;

    @Input() detailsPath: string;

    @Output() elementSelectedAction = new EventEmitter();

    @ContentChildren(COMPONENT_DEFINITION_REF) private columnComponents: QueryList<any>;

    enableImport: boolean;
    bulkControls: BulkControl[] = [];

    private metricNames = new Set<string>();

    private defaultProperties: { [name: string]: PropertyInfo } = {
        name: { label: 'customerNameProperty', path: 'name', defaultFilter: null, defaultSorting: null },
        code: { label: 'codeProperty', path: 'code', defaultFilter: null, defaultSorting: null },
        type: { label: 'customerTypeProperty', path: 'type', defaultFilter: CapitalizePipe, defaultSorting: null },
        country: { label: 'countryProperty', path: 'country', defaultFilter: null, defaultSorting: null }
    };

    constructor(
        @Inject(forwardRef(() => CustomerListWidgetV2Service)) private customerListV2Service: CustomerListWidgetV2Service,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => AuthenticationService)) authenticationService: AuthenticationService,
        @Inject(forwardRef(() => AppService)) appService: AppService,
        @Inject(forwardRef(() => AbstractExportContextService)) exportService: AbstractExportContextService,
        @Inject(forwardRef(() => AddButtonContextService)) addButtonService: AddButtonContextService,
        @Inject(forwardRef(() => MatDialog)) dialog: MatDialog,
        @Inject(forwardRef(() => SnackbarService)) snackbarService: SnackbarService
    ) {
        super(appService, authenticationService, exportService, addButtonService, dialog, snackbarService);
    }

    ngOnInit() {
        this.importTitle = 'importCustomersTitle';
        this.csvExample = `code,name,type,country,timezone,properties.<property_name>,location.name,location.country,location.timezone,location.gpsPosition,location.properties.<property_name>\nC123456,C1,PERSONAL,London,Europe/London,P1,L1,United Kingdom,Europe/London,"51.509865,-0.118092",P2\nC123457,C2,BUSINESS,London,Europe/London,P1,L2,United Kingdom,Europe/London,"51.509865,-0.118092",P2`;
        this.checkIsMobile();
        this.readPermission = this.authenticationService.hasPermission(Permissions.READ_CUSTOMER);
        this.writePermission = this.authenticationService.hasPermission(Permissions.WRITE_CUSTOMER);
        if (this.selectableList) {
            this.enableImport = false;
            this.showAddButton = false;
        } else {
            this.enableImport = this.authenticationService.hasPermission(Permissions.IMPORT_CUSTOMERS);
            this.showAddButton = this.writePermission && this.enableActions;
        }
        this.handleAdvancedSearchLayoutType("customer-list-popup-advanced-search", SearchTargetType.CUSTOMERS);
        if (this.exportEnabled) {
            this.subscribeToExportServices();
        }
        if (this.showAddButton) {
            this.subscribeToAddButtonVisibility(AddButtonResource.CUSTOMER);
        }
        this.setBulkControls();
        this.preserveSelectedBetweenPages = true;
    }

    private setBulkControls(): void {
        this.bulkControls = [
            { icon: "delete", iconClass: "material-symbols-outlined", action: 'BULK_DELETE', title: 'bulkDeleteProperty', visible: this.authenticationService.hasPermission(Permissions.DELETE_CUSTOMER) }
        ];
        this.bulkControlsEnabled = this.bulkControlsEnabled && this.bulkControls.some(control => control.visible);
    }

    ngAfterContentInit(): void {
        this.initVisibleColumns();
        const storedFieldsValues = localStorage.getItem(this.queryFieldRef || 'customerAdvancedSearchFieldsValues');
        const savedFieldsValues = storedFieldsValues ? JSON.parse(storedFieldsValues) : null;
        if (!savedFieldsValues && !this.query && !this.queryFieldRef && this.advancedSearchLayout != AdvancedSearchLayoutType.POPUP) {
            this.getCustomerList();
        }
    }

    protected initVisibleColumns(): void {
        this.metricNames = this.customerListV2Service.getMetricNames(this.columnComponents);
        if (this.selectableList) {
            this.displayedColumns = [CustomTableService.newSimpleColumn('name', 'nameProperty', 'name').withSortField('name')];
        } else {
            this.columnComponentsArray = this.customerListV2Service.getColumnComponentsArray(this.columnComponents.toArray(), this.defaultProperties);
            this.displayedColumns = this.customerListV2Service.getVisibleColumns(this.columnComponentsArray, this.defaultProperties, 'Customer', this.id);
            this.editTableColumnsEnabled = !!this.id && this.columnComponentsArray.filter(c => c.displayMode != ColumnComponentDisplayMode.ALWAYS_HIDDEN).length > 1;
        }
        this.descriptions = this.customerListV2Service.getColumnDescriptions(this.columnComponents.toArray());
        if (!this.sort) {
            this.sort = this.customerListV2Service.setDefaultSort(this.displayedColumns);
        }
    }

    goToDetail(customer: Customer): void {
        if (this.selectableList) {
            this.elementSelectedAction.emit(customer);
        } else {
            let link;
            if (this.detailsPath) {
                link = ['/dashboard/customer_details', customer?.id, this.detailsPath];
            } else {
                link = ['/dashboard/customer_details', customer?.id];
            }
            this.navigationService.navigateTo(link);
        }
    }

    addCustomer(): void {
        this.navigationService.navigateTo(['/dashboard/customer_details/add']);
    }

    private getCustomerList(): void {
        this.customerListV2Service.getPagedList(this.pageIndex, this.pageSize, this.sort, this.metricNames, this.searchFields, this.advancedSearchBody).then(pagedList => {
            this.updateElementList(pagedList);
            this.error = null;
        }).catch(err => this.error = ErrorUtility.getMessage(err));
    }

    refreshList(data?: { pageIndex: number, pageSize: number, advancedSearchBody: any, sort: string[] }): void {
        if (data) {
            this.pageIndex = data.pageIndex;
            this.pageSize = data.pageSize;
            this.advancedSearchBody = data.advancedSearchBody;
            this.sort = data.sort;
        }
        this.getCustomerList();
    }

    export(): void {
        const params = this.customerListV2Service.getParams(this.metricNames, this.searchFields, this.advancedSearchBody, true);
        this.customerListV2Service.downloadCSV(params, this.exportService.resolveExportFileNamePlaceholders(this.exportFileName));
    }

    import(data: FormData): void {
        this.customerListV2Service.import(data).then(() => {
            this.error = null;
            this.getCustomerList();
        }).catch(err => this.error = ErrorUtility.getMessage(err));
    }

    protected subscribeToExportServices(): void {
        this.exportId = "customer-list-" + this.getNextId();
        this.exportService.subscribeToExport(this.exportId, this.title || "Customers").subscribe(() => this.export());
        this.subscribeToExportVisibility();
    }

    openBulkControl(action: string): void {
        const params = this.customerListV2Service.getParams(this.metricNames, this.searchFields, this.advancedSearchBody, false);
        switch (action) {
            case 'BULK_DELETE':
                this.openBulkDeleteDialog(params, BulkDeleteElementType.CUSTOMER);
                break;
            default:
                return null;
        }
    }
}