import { Component, forwardRef, Inject, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { firstValueFrom } from 'rxjs';
import { SERVICE_LEVELS, THING_DEFINITIONS } from '../../../common/endpoints';
import { CustomPropertyDefinition, ProductModel, ServiceLevel, ThingDefinition, ThingInventoryManagementType } from '../../../model';
import { AuthenticationService } from '../../../service/authentication.service';
import { ContextService } from '../../../service/context.service';
import { CustomPropertyService, CustomPropertyType } from '../../../service/custom-property.service';
import { FieldService } from '../../../service/field.service';
import { HttpService } from '../../../service/http.service';
import { ProductModelService } from '../../../service/product-model.service';
import { ServiceLevelService } from '../../../service/service-level.service';
import { AbstractContextService } from '../../../shared/class/abstract-context-service.class';
import { LocalizationPipe } from '../../../shared/pipe';
import { AdvancedSearchService } from '../advanced-search.service';
import { AbstractAdvancedSearchComponent } from './../abstract-advanced-search.component';
import { ThingAdvancedSearchAddPropertiesDialog } from './thing-advanced-search-add-properties-dialog.component';

@Component({
    selector: 'thing-advanced-search',
    template: require('./thing-advanced-search.component.html'),
    styles: [require('./thing-advanced-search.component.css')],
    providers: [AdvancedSearchService]
})
export class ThingAdvancedSearchComponent extends AbstractAdvancedSearchComponent implements OnInit {

    @ViewChild(ThingAdvancedSearchAddPropertiesDialog) addPropertiesDialog: ThingAdvancedSearchAddPropertiesDialog;

    addCustomerSearchField: boolean;
    tags: { value: string, label: string }[];
    thingDefinitionTypes: { value: string, label: string }[];
    productModelTypes: { value: string, label: string, thingDefinitionId: string }[];
    serviceLevelTypes: { value: string, label: string }[];
    thingDefinitionProperties: CustomPropertyDefinition[];

    defaultProperties: { name: string, label: string }[] = [
        { name: 'name', label: 'thingNameProperty' },
        { name: 'serialNumber', label: 'serialNumberProperty' },
        { name: 'customer.code', label: 'customerCodeProperty' },
    ];

    private userCustomerId: string;
    private searchDataInitialized: boolean;
    private initialVisibleProperties: CustomPropertyDefinition[];
    private allProperties: CustomPropertyDefinition[];
    private showThingDefinitions: boolean;
    private showProductModels: boolean;

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => CustomPropertyService)) private customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => LocalizationPipe)) localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => FieldService)) fieldService: FieldService,
        @Inject(forwardRef(() => AdvancedSearchService)) private advancedSearchService: AdvancedSearchService,
        @Inject(forwardRef(() => ProductModelService)) private productModelService: ProductModelService
    ) { super(localizationPipe, fieldService) }

    ngOnInit(): void {
        this.localStorageKey = this.queryId || 'thingAdvancedSearchFieldsValues';
        this.savedFieldsValues = localStorage.getItem(this.localStorageKey) ? JSON.parse(localStorage.getItem(this.localStorageKey)) : null;
        this.allProperties = this.customPropertyService.getCustomPropertyDefinitionByType(CustomPropertyType.Thing).filter(def => def.searchable);
        this.customerProperties = this.customPropertyService.getCustomPropertyDefinitionByType(CustomPropertyType.Customer).filter(def => def.searchable);
        this.locationProperties = this.customPropertyService.getCustomPropertyDefinitionByType(CustomPropertyType.Location).filter(def => def.searchable);
        this.thingDefinitionProperties = this.customPropertyService.getCustomPropertyDefinitionByType(CustomPropertyType.ThingDefinition).filter(def => def.searchable);
        const userFields = _.cloneDeep(this.authenticationService.getUser().thingsSearchFields);
        this.searchFields = this.getSearchFields(userFields);
        this.userCustomerId = this.authenticationService.getUser().customerId || (this.contextService.getCurrentCustomer() ? this.contextService.getCurrentCustomer().id : null)
            || (this.authenticationService.isLocationUser() ? (ContextService.getCustomerFromLocation(this.authenticationService.getUser().location) ? ContextService.getCustomerFromLocation(this.authenticationService.getUser().location).id : null) : null);
        this.addCustomerSearchField = (this.authenticationService.isOrganizationUser() || this.authenticationService.isPartnerUser()) && !this.userCustomerId;
        this.showThingDefinitions = [ThingInventoryManagementType.BY_THING_DEFINITION, ThingInventoryManagementType.BY_THING_DEFINITION_AND_MODEL].includes(this.authenticationService.getTenant().thingInventoryManagement);
        this.showProductModels = [ThingInventoryManagementType.BY_MODEL, ThingInventoryManagementType.BY_THING_DEFINITION_AND_MODEL].includes(this.authenticationService.getTenant().thingInventoryManagement)
        this.filterThingsSearchFields();
        let savedFieldThingDefinitionIds: string[];
        let queryThingDefinitionIds: string[];
        if (this.savedFieldsValues) {
            savedFieldThingDefinitionIds = this.savedFieldsValues['thingDefinitions'] ? this.savedFieldsValues['thingDefinitions'] : [];
        }
        if (this.query && this.query.length) {
            queryThingDefinitionIds = this.query.find(el => el.property == 'thingDefinitions')?.value || [];
        }
        const visibleProperties = this.getVisibleProperties(queryThingDefinitionIds || savedFieldThingDefinitionIds);
        this.updateVisibleProperties(visibleProperties);
        this.getThingProperties(queryThingDefinitionIds && queryThingDefinitionIds.length > 0);
        this.getSearchData().then(() => {
            if (this.query && this.query.length) {
                this.getEncodedQueryFields();
            }
            if (this.queryFieldRef) {
                this.subscribeToQueryFieldRef();
            } else {
                if ((this.savedFieldsValues && this.controlsEnabled) || this.alwaysExpanded) {
                    this.showHideAdvancedSearch().then(() => this.waitForAdvancedSearchRenderedAndPerformSearch());
                } else if (this.encodedQueryFields) {
                    this.loadData(null, this.encodedQueryFields);
                }
            }
        });
        this.initSimpleSearchActions();
    }

    private filterThingsSearchFields(): void {
        if (this.userCustomerId) {
            this.searchFields = this.searchFields.filter(el => !el.includes('customer.'));
        }
    }

    advancedSearch($event?): void {
        this.simpleSearchKey = null;
        const rawValue = this.advancedSearchEditor.getObjectValue();
        const key = this.advancedSearchBarEditor.getObjectValue()['key'];
        let customer = null;
        if (this.addCustomerSearchField) {
            customer = rawValue['customer'] || null; // empty to null
        }
        let tags = null;
        if (rawValue['tags']) {
            tags = this.getTagsIds(rawValue['tags']);
        }
        let thingDefinitionIds = null;
        if (this.showThingDefinitions) {
            thingDefinitionIds = rawValue['thingDefinitions'];
            thingDefinitionIds = thingDefinitionIds && thingDefinitionIds.length ? thingDefinitionIds : null;
        }
        let serviceLevelIds = rawValue['serviceLevels'];
        serviceLevelIds = serviceLevelIds?.length ? serviceLevelIds : null;
        let productModelIds = null;
        if (this.showProductModels) {
            productModelIds = rawValue['productModels'];
            productModelIds = productModelIds && productModelIds.length ? productModelIds : null;
        }
        const fields = {
            key: key,
            thingDefinitions: thingDefinitionIds,
            customer: customer,
            tags: tags,
            serviceLevels: serviceLevelIds,
            productModels: productModelIds
        };
        const fieldsToSave = {
            key: key,
            thingDefinitions: thingDefinitionIds,
            customer: customer,
            tags: rawValue['tags'],
            serviceLevels: serviceLevelIds,
            productModels: productModelIds
        };
        let encodedBody = Object.assign({}, fields, this.propertiesInputs ? this.propertiesInputs.getEncodedBody() : null);
        let fieldsToSaveBody = Object.assign({}, fieldsToSave, this.propertiesInputs ? this.propertiesInputs.getBody() : null);
        if (this.query && this.query.length) {
            encodedBody = Object.assign({}, encodedBody, this.encodedQueryFields);
            fieldsToSaveBody = this.removeQueryFields(fieldsToSaveBody);
        }
        this.handleSearchFieldSelectionInputs(encodedBody, fieldsToSaveBody);
        this.updateLocalStorage(fieldsToSaveBody);
        const encodedBodyValues = Object.keys(encodedBody).map(el => encodedBody[el]);
        if (encodedBodyValues.some(el => el != null)) {
            this.loadData(key, encodedBody);
        } else {
            this.loadData();
        }
        if ($event) {
            const eventObject = $event.currentTarget;
            eventObject.blur();
        }
    }

    openAddMorePropertiesDialog(): void {
        if (this.useExternalAddPropertiesDialog) {
            this.emitOpenExternalAddPropertiesDialogAction(this.advancedSearchAddibleProperties);
        } else {
            this.addPropertiesDialog.open();
        }
    }

    private getThingProperties(useFilteredProperties: boolean): void {
        this.advancedSearchAddibleProperties = [
            { name: 'name', label: 'thingNameProperty', },
            { name: 'serialNumber', label: 'serialNumberProperty' },
        ];
        if (useFilteredProperties && this.properties) {
            this.updateAdvancedSearchAddibleProperties(this.properties, '', null);
        } else if (this.allProperties) {
            this.updateAdvancedSearchAddibleProperties(this.allProperties, '', null);
        }
        if (this.locationProperties) {
            this.updateAdvancedSearchAddibleProperties(this.locationProperties, 'location.properties.', 'Location');
        }
        const isCustomer = this.authenticationService.getUser().customerId || (this.contextService.getCurrentCustomer() ? this.contextService.getCurrentCustomer().id : null);
        if (!isCustomer) {
            this.advancedSearchAddibleProperties.push({ name: 'customer.code', label: 'customerCodeProperty' });
            if (this.customerProperties) {
                this.updateAdvancedSearchAddibleProperties(this.customerProperties, 'customer.properties.', 'Customer');
            }
        }
        if (this.thingDefinitionProperties) {
            this.updateAdvancedSearchAddibleProperties(this.thingDefinitionProperties, 'thingDefinition.properties.', null);
        }
        if (this.query?.length && this.query.some(q => q.property == 'location.name')) {
            this.advancedSearchAddibleProperties.push({ name: 'location.name', label: 'locationNameProperty' });
        }
    }

    protected initConfigurations(): Promise<void> {
        let advancedSearchBarConfiguration = [];
        advancedSearchBarConfiguration.push({ name: 'key', type: 'SEARCH', value: this.getValue('key') || this.simpleSearchKey });
        this.advancedSearchBarConfiguration = advancedSearchBarConfiguration;
        return this.getSearchData().then(() => {
            let advancedSearchConfiguration = [];
            if (this.addCustomerSearchField) {
                advancedSearchConfiguration.push({ name: 'customer', label: 'customerProperty', type: 'CUSTOMER_SEARCH', value: this.getValue('customer'), disabled: this.isQueryField('customer'), defaultValue: this.isQueryField('customer') ? this.getValue('customer') : null });
            }
            if (this.showThingDefinitions) {
                advancedSearchConfiguration.push({ name: 'thingDefinitions', label: 'thingDefinitionsTabItem', type: 'STRING', selectionMode: 'MAT_SELECTION', values: this.thingDefinitionTypes, value: this.getValue('thingDefinitions'), multipleSelection: true, disabled: this.isQueryField('thingDefinitions'), defaultValue: this.isQueryField('thingDefinitions') ? this.getValue('thingDefinitions') : null, placeholder: "All Thing Definitions" });
            }
            if (this.showProductModels && this.productModelTypes.length) {
                advancedSearchConfiguration.push({ name: 'productModels', label: 'productModelsProperty', type: 'STRING', selectionMode: 'MAT_SELECTION', values: this.productModelTypes, value: this.getValue('productModels'), multipleSelection: true, disabled: this.isQueryField('productModels'), defaultValue: this.isQueryField('productModels') ? this.getValue('productModels') : null, placeholder: "All Models" });
            }
            if (this.tags?.length) {
                advancedSearchConfiguration.push({ name: 'tags', label: 'tagsProperty', type: 'STRING', selectionMode: 'MAT_SELECTION', values: this.tags, value: this.getValue('tags'), multipleSelection: true, disabled: this.isQueryField('tags'), defaultValue: this.isQueryField('tags') ? this.getValue('tags') : null, placeholder: "All Tags" });

            }
            if (this.serviceLevelTypes?.length) {
                advancedSearchConfiguration.push({ name: 'serviceLevels', label: 'serviceLevelsProperty', type: 'STRING', selectionMode: 'MAT_SELECTION', values: this.serviceLevelTypes, value: this.getValue('serviceLevels'), multipleSelection: true, disabled: this.isQueryField('serviceLevels'), defaultValue: this.isQueryField('serviceLevels') ? this.getValue('serviceLevels') : null, placeholder: "All Service Levels" });
            }
            this.fieldsPerRow = advancedSearchConfiguration.length % 2 ? 3 : 2;
            this.advancedSearchConfiguration = advancedSearchConfiguration;
        });
    }

    private getSearchData(): Promise<void> {
        if (!this.searchDataInitialized) {
            this.searchDataInitialized = true;
            let promises = [];
            if (this.showThingDefinitions) {
                promises.push(firstValueFrom(this.httpService.get<ThingDefinition[]>(THING_DEFINITIONS)).then(thingDefinitions => {
                    this.thingDefinitionTypes = thingDefinitions.map((td: ThingDefinition) => { return { value: td.id, label: td.name } });
                }).catch(() => this.thingDefinitionTypes = []));
            }
            if (this.showProductModels) {
                promises.push(this.productModelService.getProductModelsAssociatedToThings().then(productModels => {
                    this.productModelTypes = productModels.map((pm: ProductModel) => { return { value: pm.id, label: pm.name, thingDefinitionId: pm.thingDefinitionId } });
                }).catch(() => this.productModelTypes = []));
            }
            promises.push(firstValueFrom(this.httpService.get<ServiceLevel[]>(SERVICE_LEVELS)).then(serviceLevels => {
                this.serviceLevelTypes = serviceLevels.sort(ServiceLevelService.compare).map((sl: ServiceLevel) => { return { value: sl.id, label: sl.name } });
            }));
            return Promise.all(promises).then(_ => {
                this.tags = this.contextService.getTagObjects()?.length ? this.contextService.getTagObjects().map(t => { return { value: t.name, label: t.name } }) : [];
            });
        }
        return Promise.resolve();
    }

    getEncodedQueryFields(): void {
        let fields = [];
        const validProperties = ['connectionStatus', 'connectionStatusLastUpdateTimestamp', 'cloudStatus', 'cloudStatusLastUpdateTimestamp', 'gpsPosition', 'location.gpsPosition', 'serviceLevel.name', 'thingDefinition.name', 'productModel.name'];
        this.query.forEach(el => {
            if (validProperties.includes(el.property) || this.advancedSearchAddibleProperties.some(p => p.name == el.property || "properties." + p.name == el.property)) {
                let propDef: CustomPropertyDefinition = null;
                if (this.properties.some(prop => el.property == "properties." + prop.name)) {
                    propDef = this.properties.find(prop => el.property == "properties." + prop.name);
                } else if (el.property.startsWith('customer.properties.')) {
                    propDef = this.customerProperties.find(prop => el.property == "customer.properties." + prop.name);
                } else if (el.property.startsWith('location.properties.')) {
                    propDef = this.locationProperties.find(prop => el.property == "location.properties." + prop.name);
                } else if (el.property.startsWith('thingDefinition.properties.')) {
                    propDef = this.thingDefinitionProperties.find(prop => el.property == "thingDefinition.properties." + prop.name);
                }
                fields[el.property] = this.getQueryValueWithSuffixes(el.value, el.predicate, propDef);
            } else {
                fields[el.property] = el.value;
            }
        });
        if (fields['tags']) {
            fields['tags'] = this.getTagsIds(fields['tags']);
        } else if (fields['customerTags']) {
            fields['tags'] = this.getCustomerTagsIds(fields['customerTags']);
        } else if (fields['locationTags']) {
            fields['tags'] = this.getLocationTagsIds(fields['locationTags']);
        } else if (fields['partnerTags']) {
            fields['tags'] = this.getPartnerTagsIds(fields['partnerTags']);
        }
        this.encodedQueryFields = fields;
    }

    getTagsIds(tagNames: string[]) {
        const tags = this.contextService.getTagObjects();
        return tagNames.map(tag => tags.find(t => t.name == tag)?.id).filter(t => t);
    }

    getCustomerTagsIds(tagNames: string[]) {
        const tags = this.contextService.getCustomerTagObjects();
        return tagNames.map(tag => tags.find(t => t.name == tag)?.id).filter(t => t);
    }

    getLocationTagsIds(tagNames: string[]) {
        const tags = this.contextService.getLocationTagObjects();
        return tagNames.map(tag => tags.find(t => t.name == tag)?.id).filter(t => t);
    }

    getPartnerTagsIds(tagNames: string[]) {
        const tags = this.contextService.getPartnerTagObjects();
        return tagNames.map(tag => tags.find(t => t.name == tag)?.id).filter(t => t);
    }

    private getVisibleProperties(thingDefinitionIds: string[]): CustomPropertyDefinition[] {
        if (thingDefinitionIds && thingDefinitionIds.length) {
            return _.cloneDeep(this.allProperties.filter(p => !p.thingDefinition || thingDefinitionIds.includes(p.thingDefinition.id)));
        } else {
            return _.cloneDeep(this.allProperties);
        }
    }

    private updateVisibleProperties(results: CustomPropertyDefinition[]): void {
        if (results) {
            this.properties = this.advancedSearchService.mergeThingProperties(results);
            if (!this.initialVisibleProperties) {
                this.initialVisibleProperties = _.cloneDeep(this.properties);
            }
        }
    }

    onSelectionUpdate(fieldName: string): void {
        this.updateInputs(fieldName);
        this.updateDynamicInputs(fieldName);
    }

    private updateInputs(fieldName: string): void {
        if (fieldName == 'thingDefinitions' && this.advancedSearchEditor) {
            const rawValues = this.advancedSearchEditor.getObjectValue();
            const thingDefintionIds: string[] = _.get(rawValues, 'thingDefinitions') || [];
            const configurations = _.cloneDeep(this.advancedSearchConfiguration);
            if (this.showProductModels) {
                let configuration = configurations.find(el => el.name == 'productModels')
                if (configuration) {
                    if (thingDefintionIds.length) {
                        const filteredValues = this.productModelTypes.filter(type => thingDefintionIds.includes(type.thingDefinitionId));
                        if (filteredValues.length) {
                            configuration.values = filteredValues;
                            const selectedValues = [];
                            if (rawValues['productModels']?.length) {
                                rawValues['productModels'].forEach(id => {
                                    if (filteredValues.some(fv => fv.value == id)) {
                                        selectedValues.push(id);
                                    }
                                });
                            }
                            configuration.value = selectedValues;
                        } else {
                            configurations.splice(configurations.indexOf(el => el.name == 'productModels'), 1);
                        }
                    } else {
                        configuration.values = this.productModelTypes;
                        configuration.value = rawValues['productModels'];
                    }
                } else {
                    if (thingDefintionIds.length) {
                        const filteredValues = this.productModelTypes.filter(type => thingDefintionIds.includes(type.thingDefinitionId));
                        if (filteredValues.length) {
                            configurations.push({ name: 'productModels', label: 'productModelsProperty', type: 'STRING', selectionMode: 'MAT_SELECTION', values: filteredValues, multipleSelection: true, placeholder: "All Models" });
                        }
                    } else if (this.productModelTypes.length) {
                        configurations.push({ name: 'productModels', label: 'productModelsProperty', type: 'STRING', selectionMode: 'MAT_SELECTION', values: this.productModelTypes, multipleSelection: true, placeholder: "All Models" });
                    }
                }
            }
            configurations.forEach(conf => {
                if (conf.name != 'productModels') {
                    conf.value = rawValues[conf.name];
                }
            });
            this.advancedSearchConfiguration = configurations;
        }

    }

    private updateDynamicInputs(fieldName: string): void {
        if (fieldName == "thingDefinitions") {
            const rawValues = this.advancedSearchEditor.getObjectValue();
            let thingDefinitionIds = rawValues['thingDefinitions'];
            this.dynamicInputsLoaded = false;
            const visibleProperties = this.getVisibleProperties(thingDefinitionIds)
            this.updateVisibleProperties(visibleProperties);
            this.savedFieldsValues = [];
            setTimeout(() => this.dynamicInputsLoaded = true, 200);
        }
    }

    protected resetInitialVisibleProperties(): void {
        if (this.initialVisibleProperties) {
            this.properties = _.cloneDeep(this.initialVisibleProperties);
            this.dynamicInputsLoaded = false;
            setTimeout(() => this.dynamicInputsLoaded = true, 200);
        }
    }

    private handleSearchFieldSelectionInputs(encodedBody: any, fieldsToSaveBody: any): void {
        if (this.savedFieldsValues?.selectedThingIds) {
            encodedBody.selectedThingIds = this.savedFieldsValues?.selectedThingIds;
            fieldsToSaveBody.selectedThingIds = this.savedFieldsValues?.selectedThingIds;
        }
    }

    private initSimpleSearchActions(): void {
        this.simpleSearchActions = [
            {
                name: 'EDIT_TABLE_COLUMNS_EVENT',
                title: 'editColumnsProperty',
                visible: this.editTableColumnsEnabled
            }
        ];
    }

    performSimpleSearchAction(action: string): void {
        switch (action) {
            case "EDIT_TABLE_COLUMNS_EVENT":
                this.emitEditTableColumnsAction();
                break;
            default:
                break;
        }
    }
}