import { Injectable, ViewContainerRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ControlBarData } from '../dashboard-area/control-bar/control-bar.component';

@Injectable()
export class ControlBarService {

    private vcRef: ViewContainerRef;
    private controlsBarTabContents: string[];
    private contentSubject: Subject<string> = new Subject();
    private buttonsDataSubject: Subject<ControlBarData> = new Subject();
    private editEventSubject: Subject<boolean> = new Subject();
    private backEventSubject: Subject<boolean> = new Subject();
    private previousEventSubject: Subject<boolean> = new Subject();
    private nextEventSubject: Subject<boolean> = new Subject();

    constructor(
    ) {
    }

    init(vcRef: ViewContainerRef): void {
        this.vcRef = vcRef;
    }

    getVcRef(): ViewContainerRef {
        return this.vcRef;
    }

    setControlsBarTabContents(controlsBarTabContents: string[]) {
        this.controlsBarTabContents = controlsBarTabContents;
    }

    getControlsBarTabContent(index: number): string {
        if (this.controlsBarTabContents?.length > index && this.controlsBarTabContents[index]) {
            return this.controlsBarTabContents[index];
        } else {
            return "";
        }
    }

    updateContentSubject(content: string): void {
        this.contentSubject.next(content);
    }

    getContentSubject(): Observable<string> {
        return this.contentSubject.asObservable();
    }

    updateButtonsDataSubject(newValue: ControlBarData): void {
        this.buttonsDataSubject.next(newValue);
    }

    getButtonsDataSubject(): Observable<ControlBarData> {
        return this.buttonsDataSubject.asObservable();
    }

    reset(): void {
        this.setControlsBarTabContents(null);
        this.updateButtonsDataSubject({ isExportPdfButtonVisible: false, isEditButtonVisible: false, isBackButtonVisible: false, urlPath: "default", isNextButtonVisible: false, isPreviousButtonVisible: false, resource: null });
        this.updateContentSubject(null);
    }

    updateEditEventSubject(pressed: boolean) {
        this.editEventSubject.next(pressed);
    }

    getEditEventSubject(): Observable<boolean> {
        return this.editEventSubject.asObservable();
    }

    updateBackEventSubject(pressed: boolean) {
        this.backEventSubject.next(pressed);
    }

    getBackEventSubject(): Observable<boolean> {
        return this.backEventSubject.asObservable();
    }

    updatePreviousEventSubject(pressed: boolean) {
        this.previousEventSubject.next(pressed);
    }

    getPreviousEventSubject(): Observable<boolean> {
        return this.previousEventSubject.asObservable();
    }

    updateNextEventSubject(pressed: boolean) {
        this.nextEventSubject.next(pressed);
    }

    getNextEventSubject(): Observable<boolean> {
        return this.nextEventSubject.asObservable();
    }
}