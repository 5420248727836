import { HttpParams } from '@angular/common/http';
import { Component, forwardRef, Inject, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom, Subscription } from 'rxjs';
import { PageTitles } from '../../common/constants';
import { USER_HISTORICAL_WORK_SESSIONS_V2, USER_WORK_SESSIONS_V2 } from '../../common/endpoints';
import { PagedList, WorkSession } from '../../model';
import { AuthenticationService } from '../../service/authentication.service';
import { BreadcrumbService } from '../../service/breadcrumb.service';
import { ControlBarService } from '../../service/control-bar.service';
import { HttpService } from '../../service/http.service';
import { NavigationService } from '../../service/navigation.service';
import { TitleService } from '../../service/title.service';
import { TemplateLoaderService } from '../template-loader/template-loader.service';

@Component({
    selector: 'work-session-detail-page',
    template: `
        <router-outlet></router-outlet>
    `
})
export class WorkSessionDetailsPageComponent implements OnInit {

    backVisible: boolean;
    templateName: string;
    workSession: WorkSession;

    private controlBarSubscriptions: Subscription[] = [];
    private nextWorkSession: WorkSession;
    private previousWorkSession: WorkSession;

    constructor(
        @Inject(forwardRef(() => BreadcrumbService)) private breadcrumbService: BreadcrumbService,
        @Inject(forwardRef(() => TitleService)) private titleService: TitleService,
        @Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef,
        @Inject(forwardRef(() => TemplateLoaderService)) private templateLoaderService: TemplateLoaderService,
        @Inject(forwardRef(() => ActivatedRoute)) private activatedRoute: ActivatedRoute,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => ControlBarService)) private controlBarService: ControlBarService,
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }

    ngOnInit(): void {
        this.subscribeToControlBar();
        this.backVisible = !this.navigationService.isEmpty();
        this.activatedRoute.data.subscribe(data => {
            this.workSession = data.workSession;
            this.templateName = this.workSession.templateName;
            this.titleService.setPageTitle(PageTitles.PLACEHOLDER, this.workSession.name);
            this.buildBreadCrumb();
            this.loadDashboard();
            this.controlBarService.updateButtonsDataSubject({ isExportPdfButtonVisible: true, isEditButtonVisible: false, isBackButtonVisible: this.backVisible, urlPath: this.workSession?.id, isNextButtonVisible: false, isPreviousButtonVisible: false, resource: 'Work Session' });
            this.computeNextAndPrevious();
        });
    }

    private buildBreadCrumb() {
        let thing = this.workSession.thing;
        if (this.authenticationService.isLocationUser()) {
            this.breadcrumbService.newBuilder().addThings(null).addThing(thing).addWorkSession(this.workSession).build();
        } else if (this.authenticationService.isCustomerUser()) {
            this.breadcrumbService.newBuilder().addLocations(null).addLocation(thing.location).addThings(thing.location).addThing(thing).addWorkSession(this.workSession).build();
        } else {
            this.breadcrumbService.newBuilder().addCustomers().addCustomer(thing.customer).addLocations(thing.customer).addLocation(thing.location).addThings(thing.location).addThing(thing).addWorkSession(this.workSession).build();
        }
    }

    back(): void {
        this.navigationService.simpleBack();
    }

    private loadDashboard(): void {
        this.templateLoaderService.loadUserTemplate(this.vcRef, this.templateName, this.workSession.thing.thingDefinition.id);
    }

    ngOnDestroy(): void {
        this.controlBarService.reset();
        this.unsubscribeFromControlBar();
    }

    subscribeToControlBar(): void {
        this.controlBarSubscriptions.push(this.controlBarService.getBackEventSubject().subscribe(() => this.back()));
        this.controlBarSubscriptions.push(this.controlBarService.getPreviousEventSubject().subscribe(() => this.goToDetails(this.previousWorkSession)));
        this.controlBarSubscriptions.push(this.controlBarService.getNextEventSubject().subscribe(() => this.goToDetails(this.nextWorkSession)));
    }

    unsubscribeFromControlBar(): void {
        if (this.controlBarSubscriptions && this.controlBarSubscriptions.length) {
            this.controlBarSubscriptions.forEach(subscription => {
                subscription.unsubscribe();
            });
            this.controlBarSubscriptions = [];
        }
    }

    private computeNextAndPrevious(): void {
        let promises = [];
        promises.push(this.getWorkSessionPagedList(true, false));
        promises.push(this.getWorkSessionPagedList(false, false));
        promises.push(this.getWorkSessionPagedList(true, true));
        promises.push(this.getWorkSessionPagedList(false, true));
        Promise.all(promises).then(results => {
            const activeNextWs = results[0].content?.length ? results[0].content[0] : null;
            const activePreviousWs = results[1].content?.length ? results[1].content[0] : null;
            const historicalNextWs = results[2].content?.length ? results[2].content[0] : null;
            const historicalPreviousWs = results[3].content?.length ? results[3].content[0] : null;
            this.nextWorkSession = activeNextWs && (!historicalNextWs || activeNextWs.startTimestamp < historicalNextWs.startTimestamp) ? activeNextWs : historicalNextWs;
            this.previousWorkSession = activePreviousWs && (!historicalPreviousWs || activePreviousWs.startTimestamp > historicalPreviousWs.startTimestamp) ? activePreviousWs : historicalPreviousWs;
            this.controlBarService.updateButtonsDataSubject({ isExportPdfButtonVisible: true, isEditButtonVisible: false, isBackButtonVisible: this.backVisible, urlPath: this.workSession?.id, isNextButtonVisible: !!this.nextWorkSession, isPreviousButtonVisible: !!this.previousWorkSession, resource: 'Work Session' });
        }).catch(() => { /* do nothing */ });
    }

    private getWorkSessionPagedList(isNext: boolean, isHistorical: boolean): Promise<PagedList<WorkSession>> {
        let params = new HttpParams();
        params = params.set('page', 0);
        params = params.set('size', 1);
        params = params.set('thingId', this.workSession.thing.id);
        if (isNext) {
            params = params.set('sort', 'startTimestamp,asc');
            params = params.set('minStartTimestamp', this.workSession.startTimestamp + 1);
        } else {
            params = params.set('sort', 'startTimestamp,desc');
            params = params.set('maxStartTimestamp', this.workSession.startTimestamp - 1);
        }
        const endpoint = isHistorical ? USER_HISTORICAL_WORK_SESSIONS_V2 : USER_WORK_SESSIONS_V2;
        return firstValueFrom(this.httpService.get<PagedList<WorkSession>>(endpoint, params));
    }

    private goToDetails(workSession: WorkSession): void {
        if (workSession) {
            const url = workSession.endTimestamp ? '/dashboard/historical_work_session_details' : '/dashboard/active_work_session_details';
            this.navigationService.navigateToEdit([url, workSession.id]);
        }
    }
}