import { AfterContentInit, Component, ContentChildren, forwardRef, Inject, Input, OnInit, QueryList } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Permissions } from '../../common/constants';
import { PropertyInfo } from '../../common/properties';
import { CustomMatPaginatorIntl } from '../../dashboard-area/shared/custom-mat-paginator-intl.service';
import { Partner } from '../../model';
import { AbstractExportContextService } from '../../service/abstract-export-context.service';
import { AddButtonContextService, AddButtonResource } from '../../service/add-button-context.service';
import { AppService } from '../../service/app.service';
import { AuthenticationService } from '../../service/authentication.service';
import { NavigationService } from '../../service/navigation.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { BulkDeleteElementType } from '../../shared/component/bulk-delete-dialog/bulk-delete-dialog.component';
import { AdvancedSearchLayoutType, BulkControl, ColumnComponentDisplayMode, ListWidgetV2Component } from '../../shared/list-widget-v2/list-widget-v2.components';
import { SearchTargetType } from '../../shared/search-field/search-field.component';
import { COMPONENT_DEFINITION_REF } from "../../shared/utility/component-definition-token";
import { ErrorUtility } from '../../utility/error-utility';
import { SnackbarService } from '../shared/snackbar.service';
import { PartnerListWidgetV2Service } from './partner-list-widget-v2.service';

@Component({
    selector: 'partner-list-widget-v2',
    template: require('./partner-list-widget-v2.component.html'),
    styles: [require('../../shared/list-widget-v2/list-widget-v2.css')],
    providers: [PartnerListWidgetV2Service, { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl }]
})
export class PartnerListWidgetV2Component extends ListWidgetV2Component<Partner> implements OnInit, AfterContentInit {

    @Input() searchFields: string[] = ["name", "code"];

    @Input() enableActions = true;

    @Input() detailsPath: string;

    @ContentChildren(COMPONENT_DEFINITION_REF) private columnComponents: QueryList<any>;

    private defaultProperties: { [name: string]: PropertyInfo } = {
        name: { label: 'partnerNameProperty', path: 'name', defaultFilter: null, defaultSorting: null },
        code: { label: 'codeProperty', path: 'code', defaultFilter: null, defaultSorting: null }
    };
    private includeParentsOnly;

    showIncludeAllPartners: boolean;
    includeAllPartners: boolean;
    bulkControls: BulkControl[] = [];

    constructor(
        @Inject(forwardRef(() => PartnerListWidgetV2Service)) private partnerListV2Service: PartnerListWidgetV2Service,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => AuthenticationService)) authenticationService: AuthenticationService,
        @Inject(forwardRef(() => AppService)) appService: AppService,
        @Inject(forwardRef(() => AbstractExportContextService)) exportService: AbstractExportContextService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => AddButtonContextService)) addButtonService: AddButtonContextService,
        @Inject(forwardRef(() => MatDialog)) dialog: MatDialog,
        @Inject(forwardRef(() => SnackbarService)) snackbarService: SnackbarService
    ) {
        super(appService, authenticationService, exportService, addButtonService, dialog, snackbarService);
    }

    ngOnInit() {
        this.checkIsMobile();
        this.writePermission = this.authenticationService.hasPermission(Permissions.WRITE_PARTNER);
        const currentPartner = this.contextService.getCurrentPartner();
        this.showAddButton = this.writePermission && this.enableActions && ((this.authenticationService.isOrganizationUser() && !currentPartner) || (this.authenticationService.isPartnerUser() && !this.authenticationService.getUser().partner.parentPartnerId));
        this.handleAdvancedSearchLayoutType("partner-list-popup-advanced-search", SearchTargetType.PARTNERS);
        if (this.exportEnabled) {
            this.subscribeToExportServices();
        }
        this.includeParentsOnly = this.authenticationService.isOrganizationUser() && !this.contextService.getCurrentPartner();
        if (this.showAddButton) {
            this.subscribeToAddButtonVisibility(AddButtonResource.PARTNER);
        }
        this.showIncludeAllPartners = this.authenticationService.isOrganizationUser() && !this.contextService.getCurrentPartner();
        this.setBulkControls();
        this.preserveSelectedBetweenPages = true;
    }

    private setBulkControls(): void {
        this.bulkControls = [
            { icon: "delete", iconClass: "material-symbols-outlined", action: 'BULK_DELETE', title: 'bulkDeleteProperty', visible: this.authenticationService.hasPermission(Permissions.DELETE_PARTNER) }
        ];
        this.bulkControlsEnabled = this.bulkControlsEnabled && this.bulkControls.some(control => control.visible);
    }

    ngAfterContentInit(): void {
        this.initVisibleColumns();
        const storedFieldsValues = localStorage.getItem(this.queryFieldRef || 'partnerAdvancedSearchFieldsValues');
        const savedFieldsValues = storedFieldsValues ? JSON.parse(storedFieldsValues) : null;
        if (!savedFieldsValues && !this.query && !this.queryFieldRef && this.advancedSearchLayout != AdvancedSearchLayoutType.POPUP) {
            this.getPartnerList();
        }
    }

    protected initVisibleColumns(): void {
        this.columnComponentsArray = this.partnerListV2Service.getColumnComponentsArray(this.columnComponents.toArray(), this.defaultProperties);
        this.displayedColumns = this.partnerListV2Service.getVisibleColumns(this.columnComponentsArray, this.defaultProperties, 'Partner', this.id);
        this.descriptions = this.partnerListV2Service.getColumnDescriptions(this.columnComponents.toArray());
        if (!this.sort) {
            this.sort = this.partnerListV2Service.setDefaultSort(this.displayedColumns);
        }
        this.editTableColumnsEnabled = !!this.id && this.columnComponentsArray.filter(c => c.displayMode != ColumnComponentDisplayMode.ALWAYS_HIDDEN).length > 1;
    }

    goToDetail(partner: Partner): void {
        let link;
        if (this.detailsPath) {
            link = ['/dashboard/partner_details', partner?.id, this.detailsPath];
        } else {
            link = ['/dashboard/partner_details', partner?.id];
        }
        this.navigationService.navigateTo(link);
    }

    addPartner(): void {
        this.navigationService.navigateTo(['/dashboard/partner_details/add']);
    }

    private getPartnerList(): void {
        this.partnerListV2Service.getPagedList(this.pageIndex, this.pageSize, this.sort, this.searchFields, this.advancedSearchBody, this.includeParentsOnly, this.includeAllPartners).then(pagedList => {
            this.updateElementList(pagedList);
            this.error = null;
        }).catch(err => this.error = ErrorUtility.getMessage(err));
    }

    refreshList(data?: { pageIndex: number, pageSize: number, advancedSearchBody: any, sort: string[] }): void {
        if (data) {
            this.pageIndex = data.pageIndex;
            this.pageSize = data.pageSize;
            this.advancedSearchBody = data.advancedSearchBody;
            this.sort = data.sort;
        }
        this.getPartnerList();
    }

    export(): void {
        const params = this.partnerListV2Service.getParams(this.searchFields, this.advancedSearchBody, this.includeParentsOnly, true, this.includeAllPartners);
        this.partnerListV2Service.downloadCSV(params, this.exportService.resolveExportFileNamePlaceholders(this.exportFileName));
    }

    protected subscribeToExportServices(): void {
        this.exportId = "partner-list-" + this.getNextId()
        this.exportService.subscribeToExport(this.exportId, this.title || "Partners").subscribe(() => this.export());
        this.subscribeToExportVisibility();
    }

    openBulkControl(action: string): void {
        const params = this.partnerListV2Service.getParams(this.searchFields, this.advancedSearchBody, this.includeParentsOnly, false, this.includeAllPartners);
        switch (action) {
            case 'BULK_DELETE':
                this.openBulkDeleteDialog(params, BulkDeleteElementType.PARTNER);
                break;
            default:
                return null;
        }
    }
}