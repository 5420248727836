
export class DataUtility {

    static downsampleData(data: any[], limit: number, timeField: string, valueField: string) {
        if (limit >= data.length || limit === 0) {
            return data; // Nothing to do
        }

        let every = (data.length - 2) / (limit - 2); // Bucket size. Leave room for start and end data points
        let a = 0; // Initially a is the first point in the triangle
        let sampledIndex = 0;
        let sampled = [data[a]]; // Always add the first point
        for (let i = 0; i < limit - 2; i++) {
            // Calculate point average for next bucket (containing c)
            let avgX = 0;
            let avgY = 0;
            let avgRangeStart = Math.floor((i + 1) * every) + 1;
            let avgRangeEnd = Math.floor((i + 2) * every) + 1;
            avgRangeEnd = avgRangeEnd < data.length ? avgRangeEnd : data.length;

            let avgRangeLength = avgRangeEnd - avgRangeStart;
            for (; avgRangeStart < avgRangeEnd; avgRangeStart++) {
                avgX += data[avgRangeStart][timeField] * 1; // * 1 enforces Number (value may be Date)
                avgY += data[avgRangeStart][valueField] * 1;
            }
            avgX /= avgRangeLength;
            avgY /= avgRangeLength;

            // Get the range for this bucket
            let rangeOffs = Math.floor((i + 0) * every) + 1;
            let rangeTo = Math.floor((i + 1) * every) + 1;

            // Point a
            let pointAX = data[a][timeField] * 1; // enforce Number (value may be Date)
            let pointAY = data[a][valueField] * 1;
            let nextA = -1;
            let maxArea = -1;
            let maxAreaPoint = -1;
            for (; rangeOffs < rangeTo; rangeOffs++) {
                // Calculate triangle area over three buckets
                let area = Math.abs((pointAX - avgX) * (data[rangeOffs][valueField] - pointAY) - (pointAX - data[rangeOffs][timeField]) * (avgY - pointAY)) * 0.5;
                if (area > maxArea) {
                    maxArea = area;
                    maxAreaPoint = data[rangeOffs];
                    nextA = rangeOffs; // Next a is this b
                }
            }

            sampled[sampledIndex++] = maxAreaPoint; // Pick this point from the bucket
            a = nextA; // This a is the next a (chosen b)
        }

        sampled[sampledIndex++] = data[data.length - 1]; // Always add last
        return sampled;
    }
}