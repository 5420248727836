import { Component, forwardRef, Input } from '@angular/core';
import { COMPONENT_DEFINITION_REF } from '../../../shared/utility/component-definition-token';

@Component({
    selector: 'search-input',
    template: '',
    providers: [{ provide: COMPONENT_DEFINITION_REF, useExisting: forwardRef(() => SearchInputComponent) }]
})
export class SearchInputComponent {

    @Input() type: SearchInputType;

    @Input() property: string;

}

export enum SearchInputType {
    ADVANCED = "ADVANCED",
    KEY = "KEY",
    PROPERTY = "PROPERTY",
    CLEAR = "CLEAR"
}