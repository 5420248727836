import { HttpParams } from "@angular/common/http";
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild, forwardRef } from "@angular/core";
import { FormControl, NgForm } from "@angular/forms";
import { DateRange } from "@angular/material/datepicker";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { Moment } from "moment";
import { Subscription, take } from "rxjs";
import { ErrorMessages } from "../../common/constants";
import { DataExportTimestampFormat, Location, Metric, Thing } from "../../model";
import { AuthenticationService } from "../../service/authentication.service";
import { ContextService } from "../../service/context.service";
import { CustomLabelService } from "../../service/custom-label.service";
import { DataExportConfigurationProperties, DataExportConfigurationService } from "../../service/data-export-configuration.service";
import { DateRangeName, DateRangeService } from "../../service/date-range.service";
import { UserLocationService } from "../../service/user-location.service";
import { UserThingService } from "../../service/user-thing.service";
import { AbstractContextService } from "../../shared/class/abstract-context-service.class";
import { AbstractThingContextService } from "../../shared/class/abstract-thing-context-service.class";
import { PreselectedRangeComponent } from "../../shared/component/daterange-picker/preselected-range.component";
import { ButtonActionValue, CustomTableColumn, CustomTableService } from "../../shared/custom-table";
import { LocalizationPipe } from "../../shared/pipe";
import { ErrorUtility } from "../../utility/error-utility";
import { DataExportWidgetAddThingDefinitionDialog } from "./data-export-widget-add-thing-definition-dialog.component";
import { DataExportWidgetService } from "./data-export-widget.service";

@Component({
    selector: 'data-export-widget-schedule-page',
    template: require('./data-export-widget-schedule-page.component.html'),
    styles: [require('./data-export-widget-schedule-page.component.css')]
})
export class DataExportWidgetSchedulePageComponent extends PreselectedRangeComponent implements OnInit, OnDestroy {

    @Input() configuration: DataExportConfigurationProperties[];

    @Output() cancelAction = new EventEmitter();

    @ViewChild('scheduleForm') scheduleForm: NgForm;

    exportData: DataExportDataElement[] = [];
    error: string;
    range: DateRange<Moment>;
    maxDaysBack: number;
    locations: Location[] = [];
    things: Thing[] = [];
    contextCustomerId: string;
    locationControl = new FormControl({ value: null, disabled: true });
    thingControl = new FormControl({ value: null, disabled: true });
    displayedColumns: CustomTableColumn[];
    dataSource = new MatTableDataSource<DataExportDataElement>([]);
    contextThingId: string;
    maximumPeriodMessage: string;
    visibleRanges: string[];
    showMaxPeriodChanged: boolean;
    hideDateRangePicker: boolean;
    timestampFormats: { value: DataExportTimestampFormat, label: string }[] = [];
    isThingSelected: boolean;

    private contextLocationId: string;
    private locationSub: Subscription;
    private defaultFileName: string = "${thing.serialNumber}_${thing.name}";
    private maximumPeriodDefaultMessage: string = "Maximum period ${maxMonths} months";
    private invalidPeriodConfiguration: boolean;
    private timezone: string;

    constructor(
        @Inject(forwardRef(() => DataExportWidgetService)) private dataExportWidgetService: DataExportWidgetService,
        @Inject(forwardRef(() => DateRangeService)) protected dateRangeService: DateRangeService,
        @Inject(forwardRef(() => CustomLabelService)) private labelService: CustomLabelService,
        @Inject(forwardRef(() => MatSnackBar)) private snackBar: MatSnackBar,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => UserLocationService)) private userLocationService: UserLocationService,
        @Inject(forwardRef(() => UserThingService)) private userThingService: UserThingService,
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog,
        @Inject(forwardRef(() => DataExportConfigurationService)) private dataExportConfigurationService: DataExportConfigurationService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) {
        super(dateRangeService);
    }

    ngOnInit() {
        this.defaultPeriodValue = DateRangeName.THIS_MONTH;
        let value = this.getPeriod();
        this.range = new DateRange(value.range.start, value.range.end);
        this.checkContext();
        this.updateTableColumns();
        this.initSubscriptions();
        this.filterPeriods = this.filterPeriods || [DateRangeName.TODAY, DateRangeName.YESTERDAY, DateRangeName.LAST_24_HOURS, DateRangeName.LAST_7_DAYS, DateRangeName.LAST_30_DAYS, DateRangeName.THIS_MONTH, DateRangeName.LAST_MONTH, DateRangeName.LAST_12_MONTHS, DateRangeName.THIS_YEAR, 'CUSTOM'];
        this.updateDateLimit(true);
        this.timezone = this.authenticationService.getUser()?.timezone;
        this.initTimestampFormats();
    }

    private getDisplayedColumns(): CustomTableColumn[] {
        let displayedColumns: CustomTableColumn[] = [];
        if (!this.contextThingId && !this.isThingSelected) {
            displayedColumns.push(CustomTableService.newSimpleColumn('name', 'nameProperty', 'thingDefinitionName'));
        }
        displayedColumns.push(
            CustomTableService.newSimpleColumn('metrics', 'metricsTabItem', 'metricNamesLabel'),
            CustomTableService.newButtonColumn('delete', '', 'id', 'float-right', 'deleteButton').withMatIcon('delete').withMatIconClass('material-symbols-outlined').withStyle({ '_any': { 'font-size': '20px', 'color': '#ff0000', 'width': '10px' } }).withStickyEndColumn()
        );
        return displayedColumns;
    }

    ngOnDestroy(): void {
        if (this.locationSub) {
            this.locationSub.unsubscribe();
        }
    }

    private checkContext(): void {
        if (this.thingContextService.getCurrentThing()) {
            const contextThing = this.thingContextService.getCurrentThing();
            this.contextThingId = contextThing.id;
            this.contextLocationId = contextThing.locationId;
            this.contextCustomerId = contextThing.customerId;
        } else if (this.contextService.getCurrentLocation()) {
            const contextLocation = this.contextService.getCurrentLocation();
            this.contextLocationId = contextLocation.id;
            this.contextCustomerId = ContextService.getCustomerFromLocation(contextLocation)?.id;
        } else if (this.contextService.getCurrentCustomer()) {
            this.contextCustomerId = this.contextService.getCurrentCustomer().id;
        }
    }

    private initTimestampFormats(): void {
        this.timestampFormats.push({ value: DataExportTimestampFormat.ISO_8601, label: 'ISO (UTC)' });
        if (this.timezone && this.timezone != 'UTC') {
            this.timestampFormats.push({ value: DataExportTimestampFormat.ISO_8601_OFFSET, label: 'ISO (' + this.timezone + ')' });
        }
        this.timestampFormats.push({ value: DataExportTimestampFormat.EPOCH_MILLIS, label: 'Milliseconds' });
    }

    loadLocationList(customerId: string): void {
        this.locations = [];
        this.locationControl.reset();
        if (customerId) {
            this.userLocationService.getRecursivelyAllLocations(null, [], customerId).then(locations => {
                this.locations = locations;
                if (this.contextLocationId) {
                    this.locationControl.setValue(this.contextLocationId);
                    this.locationControl.disable();
                } else {
                    this.locationControl.enable();
                }
            });
        } else {
            this.locationControl.disable();
        }
    }

    private initSubscriptions(): void {
        this.locationSub = this.locationControl.valueChanges.subscribe(locationId => {
            this.things = [];
            this.thingControl.reset();
            if (locationId) {
                this.userThingService.getRecursivelyAllThings(null, [], locationId).then(things => {
                    this.things = things;
                    this.thingControl.enable();
                });
            } else {
                this.thingControl.disable();
            }
            this.isThingSelected = false;
            this.resetExportData();
            this.updateTableColumns();
        });
    }

    selectedThingChanged(thingId: string): void {
        this.isThingSelected = !!thingId;
        this.resetExportData();
        this.updateTableColumns();
    }

    selectPeriod(range: DateRange<Moment>) {
        this.range = range;
        this.updateDateLimit();
    }

    onCancel() {
        this.cancelAction.emit();
    }

    scheduleBulkDataExport(): void {
        const formValues = this.scheduleForm.form.getRawValue();
        let metricsList: string[] = [];
        let thingDefList: string[] = [];
        this.exportData.forEach(el => {
            thingDefList.push(el.thingDefinitionId);
            el.exportMetrics.forEach(m => {
                metricsList.push(m.id);
            });
        });
        let body = {
            name: formValues.name,
            thingFileName: formValues.thingFileName ? formValues.thingFileName : this.defaultFileName,
            hashThingFileName: formValues.hashThingFileName ? formValues.hashThingFileName : null,
            thingDefinitionIds: thingDefList,
            metricIds: metricsList,
            startTimestamp: this.range.start.valueOf(),
            endTimestamp: this.range.end.valueOf(),
            timestampFormat: formValues.timestampFormat
        }
        if (this.contextThingId) {
            body['thingId'] = this.contextThingId;
            body['locationId'] = this.contextLocationId;
            body['customerId'] = this.contextCustomerId;
        } else {
            if (this.thingControl.value) {
                body['thingId'] = this.thingControl.value;
            }
            if (this.locationControl.value) {
                body['locationId'] = this.locationControl.value;
            }
            if (formValues.customer) {
                body['customerId'] = formValues.customer;
            }
        }
        if (this.contextService.getCurrentPartner()) {
            body['partnerId'] = this.contextService.getCurrentPartner().id;
        }
        if (body['timestampFormat'] == DataExportTimestampFormat.ISO_8601_OFFSET) {
            body['timezone'] = this.timezone;
        }
        this.dataExportWidgetService.scheduleBulkDataExport(body).then(() => {
            this.error = null;
            this.showSnackbar("dataExportScheduledProperty");
            this.onCancel();
        }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.SAVE_DATA_ERROR));
    }

    private showSnackbar(text: string): void {
        this.labelService.getCustomLabel(text)
            .then(message => {
                this.snackBar.open(this.localizationPipe.transform(message), '', {
                    duration: 4000,
                    panelClass: 'notification-info'
                });
            });
    }

    isFormValid(): boolean {
        if (this.invalidPeriodConfiguration) {
            return false;
        }
        if (this.scheduleForm) {
            const formValues = this.scheduleForm.form.getRawValue();
            return this.exportData?.length > 0 && this.range && formValues.name;
        } else {
            return false;
        }
    }

    openAddThingDefinitionDialog(index: number): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '25%';
        dialogConfig.panelClass = "data-export-add-thing-definition-dialog";
        const selectedElement = (index != null) ? this.exportData[index] : null;
        const excludedThingDefIds = selectedElement ?
            (this.exportData.filter(data => data.thingDefinitionId != selectedElement.thingDefinitionId).map(data => { return data.thingDefinitionId })) :
            (this.exportData.map(data => { return data.thingDefinitionId }));
        const selectedThing = this.thingControl.value ? this.things.find(t => t.id == this.thingControl.value) : null;
        dialogConfig.data = {
            selectedElement: selectedElement,
            params: this.getDialogParams(),
            currentThing: this.thingContextService.getCurrentThing() || selectedThing,
            excludedThingDefIds: excludedThingDefIds
        }
        dialogConfig.autoFocus = false;
        dialogConfig.maxWidth = '428px';
        dialogConfig.disableClose = true;
        this.dialog.open(DataExportWidgetAddThingDefinitionDialog, dialogConfig).afterClosed().pipe(take(1)).subscribe(result => {
            if (result) {
                if (index != null) {
                    this.exportData[index] = result;
                    this.dataSource = new MatTableDataSource<DataExportDataElement>(this.exportData);
                } else {
                    this.exportData.push(result);
                    this.dataSource = new MatTableDataSource<DataExportDataElement>(this.exportData);
                }
                this.updateDateLimit();
            }
        });
    }

    execButtonAction(actionValue: ButtonActionValue): void {
        switch (actionValue.action) {
            case 'delete':
                this.exportData.splice(actionValue.index, 1);
                this.dataSource = new MatTableDataSource<DataExportDataElement>(this.exportData);
                this.updateDateLimit();
                break;
        }
    }

    private updateDateLimit(init?: boolean): void {
        let metricsList: string[] = [];
        this.exportData.forEach(el => {
            el.exportMetrics.forEach(m => {
                metricsList.push(m.id);
            });
        });
        const configurationProperty = this.configuration.find(conf => metricsList.length <= conf.maxMetrics || conf.maxMetrics == null);
        const monthLimit = configurationProperty?.maxMonths != null ? configurationProperty.maxMonths : 0;
        if (monthLimit == 0) {
            this.error = "Invalid maxPeriod configuration";
            this.invalidPeriodConfiguration = true;
            return;
        }
        const maxPeriodChanged = (monthLimit * 30) != this.maxDaysBack;
        this.maxDaysBack = monthLimit * 30;
        this.maximumPeriodMessage = this.localizationPipe.transform(this.maximumPeriodDefaultMessage).replace('${maxMonths}', monthLimit.toString());
        this.showMaxPeriodChanged = !init && maxPeriodChanged;
        if (maxPeriodChanged) {
            this.hideDateRangePicker = true;
            this.visibleRanges = this.dataExportConfigurationService.updateVisibleRanges(monthLimit, this.allowedPeriods, this.filterPeriods);
            setTimeout(() => this.hideDateRangePicker = false, 10);
        }
    }

    private getDialogParams(): HttpParams {
        let params = new HttpParams();
        const formValues = this.scheduleForm.form.getRawValue();
        if (formValues.customer) {
            params = params.set('customerId', formValues.customer);
        }
        if (this.locationControl.value) {
            params = params.set('locationId', this.locationControl.value);
        }
        if (this.thingControl.value) {
            params = params.set('selectedThingId', this.thingControl.value);
        }
        return params;
    }

    private resetExportData(): void {
        this.exportData = [];
        this.dataSource = new MatTableDataSource<DataExportDataElement>(this.exportData);
    }

    private updateTableColumns(): void {
        this.displayedColumns = this.getDisplayedColumns();
    }

} export class DataExportDataElement {
    thingDefinitionId: string;
    thingDefinitionName: string;
    exportMetrics: Metric[];
    metricNamesLabel: string
}