import { AfterViewInit, Component, EventEmitter, forwardRef, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { IOT_CONNECTOR_TYPE, Permissions } from '../../common/constants';
import { THING_CONNECTION_TOKENS } from '../../common/endpoints';
import { isEmpty } from '../../common/helper';
import { ConnectionMappingType } from '../../model/connection-mapping';
import { PLUGIN_CONNECTOR_MAP } from '../../model/index';
import { AuthenticationService } from '../../service/authentication.service';
import { FormCheckerService } from '../../service/form-checker.service';
import { HttpService } from '../../service/http.service';
import { MessageComponent } from '../../shared/component';
import { FormChecked } from '../../shared/interface/form-checked.interface';
import { HttpUtility } from '../../utility';
import { ConnectionTokensService } from './connection-tokens.service';
import { LocalizationPipe } from '../../shared/pipe/localization.pipe';
import { CustomLabelService } from '../../service/custom-label.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'connection-tokens-import',
    template: require('./connection-tokens-import.component.html')
})

export class ConnectionTokensImportComponent implements OnInit, AfterViewInit, FormChecked {

    static readonly THING_CONNECTION_TOKEN_IMPORT_FORM_KEY = 'THING_CONNECTION_TOKEN_IMPORT_FORM_KEY';

    error: string;
    writePermission: boolean;
    connectionMappingTypes: { value: string, label: string }[];
    payloadFormats: { value: string, label: string }[];
    csvLoaded: boolean;
    csv: File;
    importInProgress: boolean;
    fileInputs: any;
    currentConnectorType: ConnectionMappingType;
    ConnectionMappingType: any = ConnectionMappingType;
    multipleRegister: boolean = true;
    passwordVisible: string = 'password';

    @Output() cancelAction: EventEmitter<void> = new EventEmitter;

    @Output() saveAction: EventEmitter<string> = new EventEmitter;

    @ViewChild('importForm') form: FormGroup;

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => AuthenticationService)) private authService: AuthenticationService,
        @Inject(forwardRef(() => FormCheckerService)) private formCheckerService: FormCheckerService,
        @Inject(forwardRef(() => ConnectionTokensService)) private thingConnectionTokenService: ConnectionTokensService,
        @Inject(forwardRef(() => HttpUtility)) private httpUtility: HttpUtility,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => CustomLabelService)) private labelService: CustomLabelService,
        @Inject(forwardRef(() => MatSnackBar)) private snackBar: MatSnackBar
    ) { }

    ngOnInit(): void {
        this.csv = null;
        this.csvLoaded = false;
        this.importInProgress = false;
        this.payloadFormats = this.thingConnectionTokenService.initPayloadFormat();
        this.writePermission = this.authService.hasPermission(Permissions.WRITE_THING_CONNECTION_TOKENS);
        this.formCheckerService.registerForm(this);
        this.initConnectionMappingTypes();
    }

    private initConnectionMappingTypes(): void {
        this.connectionMappingTypes = [IOT_CONNECTOR_TYPE.SEMIOTY_THING_CONNECTOR];
        let plugins: string[] = this.authService.getEnabledPlugins().map(p => p.name);
        if (plugins.indexOf('Everyware Cloud') > -1) {
            this.connectionMappingTypes.push(PLUGIN_CONNECTOR_MAP.get('Everyware Cloud'));
        }
        if (plugins.indexOf('HTTP Connector') > -1) {
            this.connectionMappingTypes.push(PLUGIN_CONNECTOR_MAP.get('HTTP Connector'));
        }
        this.currentConnectorType = ConnectionMappingType.SEMIOTY_THING_CONNECTOR;
    }

    ngAfterViewInit(): void {
        const opt = {
            buttonBefore: true,
            disabled: !this.writePermission,
            btnClass: "btn-default"
        };
        this.fileInputs = $(':file') as any;
        this.fileInputs.filestyle(opt);
    }

    ngOnDestroy(): void {
        this.formCheckerService.unregisterForm(this.getFormKey());
    }

    loadCsv(event): void {
        const files: FileList = <any>(event.srcElement || event.target).files;
        if (files.length > 0) {
            this.csvLoaded = true;
            this.csv = files.item(0);
        } else {
            this.csvLoaded = false;
            this.csv = null;
        }
    }

    import(): void {
        this.importInProgress = true;
        const url = THING_CONNECTION_TOKENS + '/import';
        const context = 'Import thing connection tokens';
        const data = new FormData();
        data.set('file', this.csv, this.csv.name);
        data.set('connectionMappingType', this.form.value.connectionMappingType);
        data.set('description', this.form.value.description);
        if (this.currentConnectorType == ConnectionMappingType.SEMIOTY_THING_CONNECTOR) {
            if (this.payloadFormats.length > 1) {
                data.set('payloadFormat', this.form.value.payloadFormat);
            } else {
                data.set('payloadFormat', 'JSON');
            }
        } else if (this.currentConnectorType == ConnectionMappingType.EDC) {
            data.set('baseRestUrl', this.form.value.baseRestUrl);
            data.set('mqttBrokerUrl', this.form.value.mqttBrokerUrl);
        }
        firstValueFrom(this.httpService.post(url, data, null, context))
            .then(() => {
                this.error = null;
                this.importInProgress = false;
                this.csv = null;
                this.csvLoaded = false;
                this.fileInputs.filestyle('clear');
                this.form.controls['connectionMappingType'].setValue('');
                this.form.controls['description'].setValue('');
                this.currentConnectorType = null;
                this.cancel();
            })
            .catch((err) => {
                console.error(err);
                this.error = err.error.message;
                this.importInProgress = false;
            });
    }

    cancel(): void {
        this.cancelAction.emit();
    }

    getFormKey(): string {
        return ConnectionTokensImportComponent.THING_CONNECTION_TOKEN_IMPORT_FORM_KEY;
    }

    isDirty(): boolean {
        if (!this.writePermission) {
            return false;
        }
        if (this.form.value.connectionMappingType || this.csvLoaded || !isEmpty(this.form.value.description)) {
            return true;
        }
        return false;
    }

    resetStatus(): void {
        this.cancel();
    }

    setCurrentConnectorType(type: string): void {
        switch (type) {
            case IOT_CONNECTOR_TYPE.EDC.value:
                this.currentConnectorType = ConnectionMappingType.EDC;
                break;
            case IOT_CONNECTOR_TYPE.SEMIOTY_THING_CONNECTOR.value:
                this.currentConnectorType = ConnectionMappingType.SEMIOTY_THING_CONNECTOR;
                break;
            case IOT_CONNECTOR_TYPE.HTTP_CONNECTOR.value:
                this.currentConnectorType = ConnectionMappingType.HTTP_CONNECTOR;
                break;
            default:
                this.currentConnectorType = null;
        }
    }

    save(isRandom: string): void {
        if (this.multipleRegister) {
            this.import();
        } else {
            const formValues = this.form.value;
            const body = {
                token: formValues.token,
                username: formValues.username,
                password: formValues.password,
                connectionMappingType: this.currentConnectorType,
                description: formValues.description,
                assetId: formValues.assetId,
                path: formValues.path,
                serialNumber: formValues.serialNumber,
                timestamp: Date.now(),
                simIccid: formValues.simIccid
            };
            if (this.currentConnectorType == 'SEMIOTY_THING_CONNECTOR') {
                if (this.payloadFormats.length > 1) {
                    body["payloadFormat"] = formValues.payloadFormat;
                } else {
                    body["payloadFormat"] = 'JSON';
                }
            } else if (this.currentConnectorType == 'EDC') {
                body["baseRestUrl"] = formValues.baseRestUrl;
                body["mqttBrokerUrl"] = formValues.mqttBrokerUrl;
                body["accountName"] = formValues.accountName;
            }
            this.thingConnectionTokenService.saveThingConnectionToken(body, isRandom).then(token => {
                this.error = null;
                this.showSaveMessage();
                this.saveAction.emit(token.id);
            }).catch(err => {
                console.error(err);
                this.error = err.error.message;
            });
        }
    }

    private showSaveMessage() {
        this.labelService.getCustomLabel('saveThingConnectionTokensMessage')
        .then(message => {
            this.snackBar.open(this.localizationPipe.transform(message), '', {
                duration: 4000,
                panelClass: 'notification-info'
            });
        });
    }

    callRandomize(): void {
        this.save("true");
    }

    setSerialNumber(value: string): void {
        this.form.controls['serialNumber'].setValue(value);
    }

    setToken(value: string): void {
        this.form.controls['token'].setValue(value);
    }

    showPassword(): void {
        this.passwordVisible = this.passwordVisible == 'password' ? 'text' : 'password';
    }

    downloadExampleCsv(): void {
        let csvExample: string;
        switch (this.currentConnectorType) {
            case ConnectionMappingType.EDC:
                csvExample = `token,accountName,username,password,assetId,path,serialNumber
                token1,account1,username1,psw1,asset1,path1,12345
                token2,account2,username2,psw2,asset2,,12346
                token3,account3,username3,psw3,asset3,path3,`;
                break;
            case ConnectionMappingType.SEMIOTY_THING_CONNECTOR:
                csvExample = `token,username,password,assetId,path,serialNumber
                token1,username1,psw1,asset1,path1,12345
                token2,username2,psw2,asset2,,12346
                token3,username3,psw3,asset3,path3,`;
                break;
            default:
                csvExample = '';
        }
        let blob = new Blob([csvExample]);
        this.httpUtility.wrapFileAndDownload({ file: blob, fileName: "csvExample.csv" });
    }
}