import { Component, Inject, ViewChild, forwardRef } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ErrorMessages } from "../../../common/constants";
import { WorkSessionService } from "../../../dashboard-area/shared/work-session.service";
import { Thing, WorkSessionDefinition } from "../../../model";
import { HttpUtility } from "../../../utility";
import { ErrorUtility } from "../../../utility/error-utility";

@Component({
    selector: 'work-session-advanced-search-import-dialog',
    template: require('./work-session-advanced-search-import-dialog.component.html'),
})
export class WorkSessionAdvancedSearchImportDialogComponent {

    @ViewChild('form') form: NgForm;

    error: string;
    csvLoaded: boolean;
    manualWorkSessions: WorkSessionDefinition[];
    currentWorkSession: WorkSessionDefinition;

    private csv: File;
    private currentThing: Thing;

    constructor(
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<WorkSessionAdvancedSearchImportDialogComponent>,
        @Inject(forwardRef(() => HttpUtility)) private httpUtility: HttpUtility,
        @Inject(forwardRef(() => WorkSessionService)) private workSessionService: WorkSessionService,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        this.manualWorkSessions = data.workSessions;
        this.currentThing = data.thing;
    }

    loadCsv(event) {
        const files: FileList = <any>(event.srcElement || event.target).files;
        if (files.length > 0) {
            this.csvLoaded = true;
            this.csv = files.item(0);
        } else {
            this.csvLoaded = false;
            this.csv = null;
        }
    }

    changeWorkSession(wsId): void {
        this.currentWorkSession = this.manualWorkSessions.find(ws => ws.id == wsId);
    }

    downloadExampleCsv(): void {
        let metricNames;
        if (!this.currentWorkSession || !this.currentWorkSession.monitoredMetrics?.length) {
            metricNames = ['metric1', 'metric2', 'metric3']
        } else {
            metricNames = this.currentWorkSession.monitoredMetrics.map(mm => mm.name);
        }
        const csvExample = `timestamp,${metricNames.join(',')}`;
        let blob = new Blob([csvExample]);
        this.httpUtility.wrapFileAndDownload({ file: blob, fileName: "csvExample.csv" });
    }

    save(): void {
        const data = new FormData();
        const rawValues = this.form.form.getRawValue();
        data.set('file', this.csv, this.csv.name);
        data.set('title', rawValues.title);
        data.set('description', rawValues.description);
        data.set('thingId', this.currentThing.id);
        data.set('workSessionDefinitionId', this.currentWorkSession.id);
        this.workSessionService.importHistoricalWorkSession(data).then(_ => {
            this.dialogRef.close(true)
        }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GENERIC_ERROR));
    }
}