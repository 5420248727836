import { Component, forwardRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ControlBarService } from '../../service/control-bar.service';
import { DownloadService } from '../../service/download.service';
import { LocalizationPipe } from '../../shared/pipe';

@Component({
    selector: 'control-bar',
    template: require('./control-bar.component.html'),
    styles: [require('./control-bar.component.css')]
})
export class ControlBarComponent implements OnInit, OnDestroy {

    buttonsData: ControlBarData = {
        isExportPdfButtonVisible: false,
        isEditButtonVisible: false,
        isBackButtonVisible: false,
        urlPath: "default",
        isNextButtonVisible: false,
        isPreviousButtonVisible: false,
        resource: null
    };
    previousLabel: string;
    nextLabel: string;

    private subscription: Subscription;

    constructor(
        @Inject(forwardRef(() => ControlBarService)) private controlBarService: ControlBarService,
        @Inject(forwardRef(() => DownloadService)) public downloadService: DownloadService,
        @Inject(forwardRef(() => LocalizationPipe)) public localizationPipe: LocalizationPipe
    ) { }

    ngOnInit(): void {
        this.subscription = this.controlBarService.getButtonsDataSubject().subscribe(buttonsData => {
            this.buttonsData = buttonsData;
            const resource = this.buttonsData?.resource ? (' ' + this.localizationPipe.transform(this.buttonsData?.resource)) : '';
            this.previousLabel = this.localizationPipe.transform('Previous') + resource;
            this.nextLabel = this.localizationPipe.transform('Next') + resource;
        });
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }

    editButtonClick(): void {
        this.controlBarService.updateEditEventSubject(true);
    }

    backButtonClick(): void {
        this.controlBarService.updateBackEventSubject(true);
    }

    previousButtonClick(): void {
        this.controlBarService.updatePreviousEventSubject(true);
    }

    nextButtonClick(): void {
        this.controlBarService.updateNextEventSubject(true);
    }

}

export class ControlBarData {
    isExportPdfButtonVisible: boolean;
    isEditButtonVisible: boolean;
    isBackButtonVisible: boolean;
    urlPath: string;
    isNextButtonVisible: boolean;
    isPreviousButtonVisible: boolean;
    resource: string;
}