
import { HttpParams } from '@angular/common/http';
import { Component, ContentChildren, Inject, Input, NgZone, OnDestroy, OnInit, PLATFORM_ID, QueryList, forwardRef } from '@angular/core';
import * as moment_tz from 'moment-timezone';
import { Metric, Thing, Value } from '../../model';
import { AuthenticationService } from '../../service/authentication.service';
import { DataService } from '../../service/data.service';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';
import { MetricDetailComponent } from '../../shared/component';
import { LoaderPipe, LocalizationPipe } from '../../shared/pipe';
import { AmChart5Component } from '../amchart5/am-chart5.component';

@Component({
    selector: 'metric-state-diagram-widget',
    template: require('./metric-state-diagram.component.html')
})
export class MetricStateDiagramComponent extends AmChart5Component implements OnInit, OnDestroy {

    @Input() title: string;

    @Input() metricName: string;

    @Input() predicate: string;

    @Input() value: any;

    @Input() showValueTransitions: boolean;

    @Input() stateBarColor: string;

    @Input() stateBarColorFilter: string;

    @Input() stateFilter: string;

    @Input() detailsLabel: string = 'Occurrencies';

    @Input() totalLabel: string = 'Total time';

    @Input() height: string = '500px';

    @Input() description: string;

    @ContentChildren(MetricDetailComponent) additionalMetrics: QueryList<MetricDetailComponent>;

    metric: Metric;
    chartData: DataBar[];
    selectedCategory: ChartCategory;
    locale: string;

    private categories: ChartCategory[] = [];
    private thing: Thing;
    private barColor: any;
    private timezone: string;
    private yAxis: any;
    private intervalId: any;
    private lastValueTimestamp: number;
    private brightness: number = 0.5;

    constructor(
        @Inject(PLATFORM_ID) platformId: Object,
        @Inject(forwardRef(() => NgZone)) zone: NgZone,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => DataService)) private dataService: DataService,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => LoaderPipe)) private loaderPipe: LoaderPipe,
    ) {
        super(platformId, zone);
    }

    ngOnInit(): void {
        this.thing = this.thingContextService.getCurrentThing();
        const user = this.authenticationService.getUser();
        this.timezone = user.timezone || 'UTC';
        this.locale = user.locale || user.language || 'en';
        this.thingContextService.getMetricByName(this.metricName).then(metric => this.metric = metric);
        this.computeCategories();
    }

    private computeCategories() {
        for (let i = 6; i >= 0; i--) {
            const startDate = moment_tz.tz(this.timezone).subtract(i, 'day').startOf('day');
            const endDate = moment_tz.tz(this.timezone).subtract(i, 'day').endOf('day');
            this.categories.push({
                startDate: startDate,
                endDate: endDate,
                label: startDate.locale(this.locale).format('ddd, DD'),
                extendedLabel: startDate.locale(this.locale).format('dddd DD MMMM YYYY')
            });
        }
    }

    getChartId(): string {
        return 'metric-state-diagram';
    }

    initChart(): void {
        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        let chart = this.root.container.children.push(this.am5xy.XYChart.new(this.root, {
            focusable: true,
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX",
            layout: this.root.verticalLayout
        }));

        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        this.yAxis = chart.yAxes.push(
            this.am5xy.CategoryAxis.new(this.root, {
                categoryField: "category",
                renderer: this.am5xy.AxisRendererY.new(this.root, { inversed: true }),
                tooltip: this.am5.Tooltip.new(this.root, {
                    themeTags: ["axis"],
                    animationDuration: 200
                })
            })
        );
        this.yAxis.data.setAll(this.categories.slice().reverse().map(c => { return { category: c.label } }));
        this.yAxis.get("renderer").labels.template.setup = (target) => {
            target.setAll({
                cursorOverStyle: "pointer",
                background: this.am5.Rectangle.new(this.root, {
                    fill: this.am5.color(0x000000),
                    fillOpacity: 0
                }),
                minWidth: 80
            });
        };
        this.yAxis.get("renderer").labels.template.events.on("click", e => {
            this.resetLabelStyle(e.target.parent);
            e.target.set("fontWeight", "bold");
            this.zone.run(() => this.selectedCategory = this.categories.find(c => c.label == e.target.dataItem.dataContext['category']));
        });
        this.yAxis.get("renderer").labels.template.events.on("pointerover", e => {
            e.target.set("textDecoration", "underline");
        });
        this.yAxis.get("renderer").labels.template.events.on("pointerout", e => {
            e.target.set("textDecoration", null);
        });

        let xAxis = chart.xAxes.push(
            this.am5xy.DateAxis.new(this.root, {
                baseInterval: { timeUnit: "minute", count: 1 },
                markUnitChange: false,
                renderer: this.am5xy.AxisRendererX.new(this.root, { strokeOpacity: 0.1 })
            })
        );

        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        let series = chart.series.push(this.am5xy.ColumnSeries.new(this.root, {
            xAxis: xAxis,
            yAxis: this.yAxis,
            openValueXField: "fromDate",
            valueXField: "toDate",
            categoryYField: "category",
            sequencedInterpolation: true
        }));

        series.columns.template.setAll({
            templateField: "columnSettings",
            strokeOpacity: 0,
            tooltipText: this.getTooltipText()
        });

        series.data.processor = this.am5.DataProcessor.new(this.root, {
            dateFields: ["fromDate", "toDate"],
            dateFormat: "HH:mm:ss"
        });

        // Add scrollbars
        chart.set("scrollbarX", this.am5.Scrollbar.new(this.root, {
            orientation: "horizontal"
        }));

        // Data
        this.barColor = this.stateBarColor ? this.am5.Color.fromString(this.stateBarColor) : chart.get("colors").getIndex(0);
        this.getData().then(() => {
            series.data.setAll(this.chartData);

            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            series.appear();
            chart.appear(1000, 100);
        });
        this.scheduleRefresh(series);
    }

    private resetLabelStyle(container: any) {
        for (let elem of container.children.values) {
            if (elem instanceof this.am5xy.AxisLabel) {
                elem.set("fontWeight", "normal");
            }
        }
    }

    private getTooltipText(): string {
        let text = "{tooltipConfig.startLabel}: {openValueX.formatDate('HH:mm:ss')}\n{tooltipConfig.endLabel}: {valueX.formatDate('HH:mm:ss')}\n{tooltipConfig.durationLabel}: {durationFormatted}";
        if (this.showValueTransitions) {
            return "{tooltipConfig.valueLabel}: {tooltipConfig.value}\n" + text;
        }
        return text;
    }

    private getData(): Promise<void> {
        const chartStartDate = this.categories[0].startDate;
        return Promise.all([
            this.dataService.getLastValueByThingIdAndMetricName(this.thing.id, this.metricName, new HttpParams().set('endDate', chartStartDate.valueOf() - 1)),
            this.dataService.getValues(this.metricName, this.thing.id, 10000, new HttpParams().set('startDate', chartStartDate.valueOf()))
        ]).then(resp => {
            let values = resp[1].values.reverse();
            const previousValue = resp[0];
            if (this.isValidValue(previousValue) && (values[0]?.timestamp != chartStartDate.valueOf())) {
                previousValue.timestamp = chartStartDate.valueOf();
                values = [previousValue, ...values];
            }
            this.zone.run(() => {
                if (this.showValueTransitions) {
                    this.computeWithTransitions(values);
                } else {
                    this.compute(values);
                }
                this.computeLastValueTimestamp(values);
            });
        }).catch(() => { /* do nothing */ })
    }

    private compute(values: Value[]): void {
        let dataBar;
        let categoryIndex = 0;
        let chartData: DataBar[] = [];
        for (let val of values) {
            while (val.timestamp > this.categories[categoryIndex].endDate.valueOf()) {
                if (dataBar) {
                    const endMoment = this.categories[categoryIndex].endDate;
                    dataBar.toDate = this.formatHours(endMoment);
                    dataBar.toMoment = endMoment;
                    dataBar.duration = moment_tz.duration(dataBar.toMoment.diff(dataBar.fromMoment));
                    dataBar.durationFormatted = MetricStateDiagramComponent.formatDuration(dataBar.duration);
                    chartData.push(dataBar);
                    dataBar = this.createDataBar(this.categories[categoryIndex + 1].label, this.categories[categoryIndex + 1].startDate, this.barColor);
                }
                categoryIndex++;
            }
            if (dataBar) {
                const endMoment = moment_tz.tz(val.timestamp - 1, this.timezone);
                dataBar.toDate = this.formatHours(endMoment);
                dataBar.toMoment = endMoment;
                dataBar.duration = moment_tz.duration(dataBar.toMoment.diff(dataBar.fromMoment));
                dataBar.durationFormatted = MetricStateDiagramComponent.formatDuration(dataBar.duration);
                if (!this.isValidValue(val)) {
                    chartData.push(dataBar);
                    dataBar = null;
                }
            } else if (this.isValidValue(val)) {
                dataBar = this.createDataBar(this.categories[categoryIndex].label, moment_tz.tz(val.timestamp, this.timezone), this.barColor);
            }
        }
        if (dataBar) {
            const currentTimestamp = moment_tz.tz(this.timezone);
            while (currentTimestamp.isAfter(this.categories[categoryIndex].endDate)) {
                const endMoment = this.categories[categoryIndex].endDate;
                dataBar.toDate = this.formatHours(endMoment);
                dataBar.toMoment = endMoment;
                dataBar.duration = moment_tz.duration(dataBar.toMoment.diff(dataBar.fromMoment));
                dataBar.durationFormatted = MetricStateDiagramComponent.formatDuration(dataBar.duration);
                chartData.push(dataBar);
                dataBar = this.createDataBar(this.categories[categoryIndex + 1].label, this.categories[categoryIndex + 1].startDate, this.barColor);
                categoryIndex++;
            }
            dataBar.toDate = this.formatHours(currentTimestamp);
            dataBar.toMoment = currentTimestamp;
            dataBar.duration = moment_tz.duration(dataBar.toMoment.diff(dataBar.fromMoment));
            dataBar.durationFormatted = MetricStateDiagramComponent.formatDuration(dataBar.duration);
            dataBar.toNow = true;
            chartData.push(dataBar);
        }
        this.computeLastValueTimestamp(values);
        this.chartData = chartData;
    }

    private computeWithTransitions(values: Value[]): void {
        let dataBar;
        let categoryIndex = 0;
        let chartData = [];
        for (let val of values) {
            while (val.timestamp > this.categories[categoryIndex].endDate.valueOf()) {
                if (dataBar) {
                    const endMoment = this.categories[categoryIndex].endDate;
                    dataBar.toDate = this.formatHours(endMoment);
                    dataBar.toMoment = endMoment;
                    dataBar.duration = moment_tz.duration(dataBar.toMoment.diff(dataBar.fromMoment));
                    dataBar.durationFormatted = MetricStateDiagramComponent.formatDuration(dataBar.duration);
                    chartData.push(dataBar);
                    dataBar = this.createDataBar(this.categories[categoryIndex + 1].label, this.categories[categoryIndex + 1].startDate,
                        this.getBarColor(dataBar.tooltipConfig.value), dataBar.tooltipConfig.value);
                }
                categoryIndex++;
            }
            if (dataBar) {
                const endMoment = moment_tz.tz(val.timestamp - 1, this.timezone);
                dataBar.toDate = this.formatHours(endMoment);
                dataBar.toMoment = endMoment;
                dataBar.duration = moment_tz.duration(dataBar.toMoment.diff(dataBar.fromMoment));
                dataBar.durationFormatted = MetricStateDiagramComponent.formatDuration(dataBar.duration);
                chartData.push(dataBar);
                dataBar = null;
            }
            if (this.isValidValue(val)) {
                this.brightness = (this.brightness == 0 ? 0.5 : 0);
                dataBar = this.createDataBar(this.categories[categoryIndex].label, moment_tz.tz(val.timestamp, this.timezone),
                    this.getBarColor(val.value), val.value);
            }
        }
        if (dataBar) {
            const currentTimestamp = moment_tz.tz(this.timezone);
            while (currentTimestamp.isAfter(this.categories[categoryIndex].endDate)) {
                const endMoment = this.categories[categoryIndex].endDate;
                dataBar.toDate = this.formatHours(endMoment);
                dataBar.toMoment = endMoment;
                dataBar.duration = moment_tz.duration(dataBar.toMoment.diff(dataBar.fromMoment));
                dataBar.durationFormatted = MetricStateDiagramComponent.formatDuration(dataBar.duration);
                chartData.push(dataBar);
                dataBar = this.createDataBar(this.categories[categoryIndex + 1].label, this.categories[categoryIndex + 1].startDate,
                    this.getBarColor(dataBar.tooltipConfig.value), dataBar.tooltipConfig.value);
                categoryIndex++;
            }
            dataBar.toDate = this.formatHours(currentTimestamp);
            dataBar.toMoment = currentTimestamp;
            dataBar.duration = moment_tz.duration(dataBar.toMoment.diff(dataBar.fromMoment));
            dataBar.durationFormatted = MetricStateDiagramComponent.formatDuration(dataBar.duration);
            dataBar.toNow = true;
            chartData.push(dataBar);
        }
        this.chartData = chartData;
    }

    static formatDuration(duration: moment_tz.Duration): string {
        return `${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`;
    }

    private isValidValue(val: Value): boolean {
        if (!val) {
            return false;
        }
        const operator = operators[this.predicate];
        return operator(val.value, this.value)
    }

    private createDataBar(category: string, startMoment: moment_tz.Moment, color: any, val?: any): DataBar {
        return {
            category: category,
            fromDate: this.formatHours(startMoment),
            fromMoment: startMoment,
            toDate: null,
            toMoment: null,
            duration: null,
            durationFormatted: null,
            columnSettings: { fill: color },
            tooltipConfig: this.getTooltipConfig(val),
            toNow: false
        };
    }

    private getBarColor(val: any): any {
        if (this.stateBarColorFilter) {
            const colorCode = this.loaderPipe.transform(val, this.stateBarColorFilter, true);
            if (colorCode) {
                return this.am5.Color.fromString(colorCode);
            }
        }
        return this.am5.Color.brighten(this.barColor, this.brightness);
    }


    private getTooltipConfig(val?: any): any {
        return {
            startLabel: this.localizationPipe.transform('start'),
            endLabel: this.localizationPipe.transform('end'),
            valueLabel: this.localizationPipe.transform('value'),
            durationLabel: this.localizationPipe.transform('duration'),
            value: this.stateFilter ? this.loaderPipe.transform(val, this.stateFilter, true, { metric: this.metric }) : val
        };
    }

    private formatHours(moment: moment_tz.Moment): string {
        return moment.locale(this.locale).format('HH:mm:ss');
    }

    private computeLastValueTimestamp(values: Value[]): void {
        const startOfToday = this.categories[6].startDate.valueOf();
        if (!values.length) {
            this.lastValueTimestamp = startOfToday;
        } else {
            this.lastValueTimestamp = Math.max(values[values.length - 1].timestamp, startOfToday);
        }
    }

    private scheduleRefresh(series: any): void {
        this.intervalId = setInterval(() => {
            this.dataService.getValues(this.metricName, this.thing.id, 10000, new HttpParams().set('startDate', this.lastValueTimestamp)).then(resp => {
                const lastValues = resp.values.reverse();
                if (this.showValueTransitions) {
                    this.refreshDataWithTransitions(lastValues);
                } else {
                    this.refreshData(lastValues);
                }
                this.computeLastValueTimestamp(lastValues);
                series.data.setAll(this.chartData);

                // refresh details only if today details is opened
                if (this.selectedCategory?.label == this.categories[6].label) {
                    this.zone.run(() => {
                        this.selectedCategory = null;
                        setTimeout(() => this.selectedCategory = this.categories[6]);
                    });
                }
            });
        }, 30000);
    }

    private refreshData(lastValues: Value[]): void {
        let dataBar = this.chartData.find(d => d.toNow);
        dataBar.toNow = false;
        const category = this.categories[6];
        for (let val of lastValues) {
            if (dataBar) {
                const endMoment = moment_tz.tz(val.timestamp - 1, this.timezone);
                dataBar.toDate = this.formatHours(endMoment);
                dataBar.toMoment = endMoment;
                if (!this.isValidValue(val)) {
                    dataBar = null;
                }
            } else if (this.isValidValue(val)) {
                dataBar = this.createDataBar(category.label, moment_tz.tz(val.timestamp, this.timezone), this.barColor);
                this.chartData.push(dataBar);
            }
        }
        if (dataBar) {
            const currentTimestamp = moment_tz.tz(this.timezone);
            dataBar.toDate = this.formatHours(currentTimestamp);
            dataBar.toMoment = currentTimestamp;
            dataBar.toNow = true;
        }
    }

    private refreshDataWithTransitions(lastValues: Value[]): void {
        let dataBar = this.chartData.find(d => d.toNow);
        dataBar.toNow = false;
        const category = this.categories[6];
        for (let val of lastValues) {
            if (dataBar) {
                const endMoment = moment_tz.tz(val.timestamp - 1, this.timezone);
                dataBar.toDate = this.formatHours(endMoment);
                dataBar.toMoment = endMoment;
                this.chartData.push(dataBar);
                dataBar = null;
            }
            if (this.isValidValue(val)) {
                this.brightness = (this.brightness == 0 ? 0.5 : 0);
                dataBar = this.createDataBar(category.label, moment_tz.tz(val.timestamp, this.timezone), this.getBarColor(val.value), val.value);
                this.chartData.push(dataBar);
            }
        }
        if (dataBar) {
            const currentTimestamp = moment_tz.tz(this.timezone);
            dataBar.toDate = this.formatHours(currentTimestamp);
            dataBar.toMoment = currentTimestamp;
            dataBar.toNow = true;
        }
    }

    closeDetails(): void {
        this.selectedCategory = null;
        this.resetLabelStyle(this.yAxis.labelsContainer);
    }

    ngOnDestroy(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }
}

const operators: { [key: string]: (a: any, b: any) => boolean } = {
    'lt': (a: any, b: any) => a < b,
    'le': (a: any, b: any) => a <= b,
    'eq': (a: any, b: any) => a == b,
    'ne': (a: any, b: any) => a != b,
    'ge': (a: any, b: any) => a >= b,
    'gt': (a: any, b: any) => a > b
}

export class ChartCategory {
    startDate: moment_tz.Moment;
    endDate: moment_tz.Moment;
    label: string;
    extendedLabel: string;
}

export class DataBar {
    category: string;
    fromDate: string;
    fromMoment: moment_tz.Moment;
    toDate: string;
    toMoment: moment_tz.Moment;
    duration: moment.Duration;
    durationFormatted: string;
    columnSettings: any;
    tooltipConfig: any;
    toNow: boolean;
}