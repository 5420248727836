import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'suffixAndRound' })
export class SuffixAndRoundPipe implements PipeTransform {

    transform(value: any, suffix: string = "", showSign: boolean = false): string {
        if (value != null && value != undefined) {
            let roundedValue = value.toFixed(2) + suffix;
            if (showSign && value > 0) {
                return '+' + roundedValue;
            }
            return roundedValue;
        }
        return '-';
    }
}
