import { Component, forwardRef, Inject, OnInit } from "@angular/core";
import { CountryService } from "../../../service/country.service";
import { CustomLabelService } from "../../../service/custom-label.service";
import { CustomPropertyService } from "../../../service/custom-property.service";
import { SearchFieldService } from "../search-field.service";
import { AbstractPropertySearchInputComponent } from "../search-input/abstract-property-search-input.component";

@Component({
    selector: 'partners-property-search-input',
    template: require('./partners-property-search-input.component.html'),
    providers: [SearchFieldService]
})
export class PartnersPropertySearchInputComponent extends AbstractPropertySearchInputComponent implements OnInit {

    constructor(
        @Inject(forwardRef(() => CustomPropertyService)) customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => CustomLabelService)) customLabelService: CustomLabelService,
        @Inject(forwardRef(() => CountryService)) private countryService: CountryService,
        @Inject(forwardRef(() => SearchFieldService)) protected searchFieldService: SearchFieldService
    ) {
        super(customPropertyService, customLabelService, searchFieldService);
    }

    initConfiguration(): void {
        this.defaultProperties = [
            { name: 'name', label: 'partnerNameProperty' },
            { name: 'code', label: 'partnerCodeProperty' }
        ];
        if (this.property?.indexOf('properties.') > -1 || this.defaultProperties.some(prop => prop.name == this.property)) {
            if (this.property.startsWith("properties.")) {
                this.property = this.property.substring(11);
            }
            this.searchFields = [this.property];
        } else {
            this.useDefaultProperty()
        }
    }

    protected handleDefaultProperty(): void {
        if (this.property == "countries") {
            this.handleCountries();
        }
    }

    private handleCountries(): void {
        const countries = this.countryService.getCountries().map(c => { return { value: c, label: c } });
        if (countries?.length) {
            this.advancedSearchConfiguration = [{ name: 'countries', placeholder: "All Countries", type: 'STRING', selectionMode: 'MAT_SELECTION', multipleSelection: true, values: countries, value: this.searchFieldService.getValueFromFieldsValues('countries', this.savedFieldsValues), hideLabel: true }];
        }
    }

    protected handleValue(body: any, isEncoded: boolean): any {
        return body;
    }

}