import { HttpParams } from '@angular/common/http';
import { Component, forwardRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as _ from 'lodash';
import * as moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { THING_DEFINITIONS } from '../../../common/endpoints';
import { CustomerService } from '../../../dashboard-area/customer/customer.service';
import { WorkSessionService } from '../../../dashboard-area/shared/work-session.service';
import { CustomPropertyDefinition, Thing, ThingDefinition, WorkSessionDefinition } from '../../../model';
import { AuthenticationService } from '../../../service/authentication.service';
import { ContextService } from '../../../service/context.service';
import { CustomPropertyService, CustomPropertyType } from '../../../service/custom-property.service';
import { CustomDateRange, DateRangeService } from '../../../service/date-range.service';
import { FieldService } from '../../../service/field.service';
import { HttpService } from '../../../service/http.service';
import { AbstractContextService } from '../../../shared/class/abstract-context-service.class';
import { AbstractThingContextService } from '../../../shared/class/abstract-thing-context-service.class';
import { FormOption } from '../../../shared/form-editor/form-field-type/form-option.interface';
import { LocalizationPipe } from '../../../shared/pipe';
import { AbstractAdvancedSearchComponent } from '../abstract-advanced-search.component';
import { WorkSessionAdvancedSearchAddPropertiesDialog } from './work-session-advanced-search-add-properties-dialog.component';
import { WorkSessionAdvancedSearchImportDialogComponent } from './work-session-advanced-search-import-dialog.component';

@Component({
    selector: 'work-session-advanced-search',
    template: require('./work-session-advanced-search.component.html'),
    styles: [require('../thing-advanced-search/thing-advanced-search.component.css')],
    providers: [CustomerService]
})
export class WorkSessionAdvancedSearchComponent extends AbstractAdvancedSearchComponent implements OnInit {

    @Input() isHistorical: boolean;

    @Input() manualWorkSessions: WorkSessionDefinition[] = [];

    @ViewChild(WorkSessionAdvancedSearchAddPropertiesDialog) addPropertiesDialog: WorkSessionAdvancedSearchAddPropertiesDialog;

    private addCustomerSearchField: boolean;
    private searchDataInitialized: boolean;
    private workSessionDefinitionsTree: any[];
    private thingDefinitionTypes: { value: string, label: string }[];
    private workSessionDefinitionGroups: { groupId: string, groupLabel: string, groupValues: FormOption[] }[] = [];
    private defaultCustomDateRange: CustomDateRange;
    private currentThing: Thing;

    defaultProperties: { name: string, label: string }[] = [];
    thingDefinitionProperties: CustomPropertyDefinition[];

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => LocalizationPipe)) localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => FieldService)) fieldService: FieldService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => CustomerService)) private customerService: CustomerService,
        @Inject(forwardRef(() => WorkSessionService)) private workSessionService: WorkSessionService,
        @Inject(forwardRef(() => DateRangeService)) private dateRangeService: DateRangeService,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => CustomPropertyService)) private customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog,
    ) { super(localizationPipe, fieldService) }

    ngOnInit(): void {
        this.localStorageKey = this.queryId || (this.isHistorical ? 'historicalWorkSessionAdvancedSearchFieldsValues' : 'workSessionAdvancedSearchFieldsValues');
        this.savedFieldsValues = localStorage.getItem(this.localStorageKey) ? JSON.parse(localStorage.getItem(this.localStorageKey)) : null;
        const customerId = this.authenticationService.getUser().customerId || (this.contextService.getCurrentCustomer() ? this.contextService.getCurrentCustomer().id : null)
            || (this.authenticationService.isLocationUser() ? (ContextService.getCustomerFromLocation(this.authenticationService.getUser().location) ? ContextService.getCustomerFromLocation(this.authenticationService.getUser().location).id : null) : null);
        this.addCustomerSearchField = ((this.authenticationService.isOrganizationUser() || this.authenticationService.isPartnerUser()) && !customerId) || this.authenticationService.getUser().hostCustomers?.length > 0;
        const userFields = _.cloneDeep(this.authenticationService.getUser().workSessionsSearchFields);
        this.searchFields = this.getSearchFields(userFields);
        this.thingDefinitionProperties = this.customPropertyService.getCustomPropertyDefinitionByType(CustomPropertyType.ThingDefinition).filter(def => def.searchable);
        this.currentThing = this.thingContextService.getCurrentThing();
        this.getWorkSessionProperties();
        if (this.isHistorical) {
            this.defaultCustomDateRange = this.dateRangeService.getCustomDateRangeByName("LAST_7_DAYS");
            if (this.enableImport) {
                this.enableImport = this.enableImport && !!this.currentThing;
            }
        }
        if (this.query && this.query.length) {
            this.getEncodedQueryFields();
        }
        if (this.queryFieldRef) {
            this.subscribeToQueryFieldRef();
        } else {
            if (this.savedFieldsValues || this.alwaysExpanded) {
                this.showHideAdvancedSearch().then(() => this.waitForAdvancedSearchRenderedAndPerformSearch());
            } else if (this.encodedQueryFields) {
                this.loadData(null, this.encodedQueryFields);
            }
        }
        this.initSimpleSearchActions();
    }

    advancedSearch($event?): void {
        this.simpleSearchKey = null;
        const rawValue = this.advancedSearchEditor.getObjectValue();
        const key = this.advancedSearchBarEditor.getObjectValue()['key'];
        const rangeValue: { range: DateRange<moment.Moment>, rangeName: string } = rawValue['period'];
        const range: DateRange<moment.Moment> = rangeValue?.rangeName ? this.dateRangeService.getCustomDateRangeByName(rangeValue.rangeName)?.range : rangeValue?.range;
        const fields = {
            key: key,
            thingDefinitions: rawValue['thingDefinitionIds'],
            customer: rawValue['customer'] || null,
            startTimestamp: range?.start?.valueOf(),
            endTimestamp: range?.end?.valueOf(),
            workSessionDefinitions: rawValue['workSessionDefinitionIds']

        };
        let fieldsToSave = {
            key: key,
            thingDefinitions: rawValue['thingDefinitionIds'],
            customer: rawValue['customer'],
            startTimestamp: range?.start?.valueOf(),
            endTimestamp: range?.end?.valueOf(),
            workSessionDefinitions: rawValue['workSessionDefinitionIds']
        };
        if (rangeValue?.rangeName) {
            fieldsToSave['rangeName'] = rangeValue?.rangeName;
        } else {
            fieldsToSave['startTimestamp'] = range?.start?.valueOf();
            fieldsToSave['endTimestamp'] = range?.end?.valueOf();
        }
        let encodedBody = Object.assign({}, fields, this.propertiesInputs ? this.propertiesInputs.getEncodedBody() : null);
        let fieldsToSaveBody = Object.assign({}, fieldsToSave, this.propertiesInputs ? this.propertiesInputs.getBody() : null);
        if (this.query && this.query.length) {
            encodedBody = Object.assign({}, encodedBody, this.encodedQueryFields);
            fieldsToSaveBody = this.removeQueryFields(fieldsToSaveBody);
        }
        this.updateLocalStorage(fieldsToSaveBody);
        const encodedBodyValues = Object.keys(encodedBody).map(el => encodedBody[el]);
        if (encodedBodyValues.some(el => el != null)) {
            this.loadData(key, encodedBody);
        } else {
            this.loadData();
        }
        if ($event) {
            const eventObject = $event.currentTarget;
            eventObject.blur();
        }
    }

    protected initConfigurations(): Promise<void> {
        let advancedSearchBarConfiguration = [];
        advancedSearchBarConfiguration.push({ name: 'key', type: 'SEARCH', value: this.getValue('key') || this.simpleSearchKey });
        this.advancedSearchBarConfiguration = advancedSearchBarConfiguration;
        return this.getSearchData().then(() => {
            let advancedSearchConfiguration = [];
            if (this.addCustomerSearchField) {
                advancedSearchConfiguration.push({ name: 'customer', label: 'customerProperty', type: 'CUSTOMER_SEARCH', value: this.getValue('customer'), disabled: this.isQueryField('customer'), defaultValue: this.isQueryField('customer') ? this.getValue('customer') : null });
            }
            advancedSearchConfiguration.push({ name: 'period', 'label': 'periodProperty', type: 'PERIOD', value: this.getDateRange() || { range: null, rangeName: this.defaultCustomDateRange?.name }, defaultValue: this.isQueryField('startTimestamp') && this.isQueryField('endTimestamp') ? this.getDateRange() : null });
            if (this.thingDefinitionTypes.length > 1 && !this.thingContextService.getCurrentThing()) {
                advancedSearchConfiguration.push({ name: 'thingDefinitionIds', label: 'thingDefinitionsTabItem', type: 'STRING', selectionMode: 'MAT_SELECTION', values: this.thingDefinitionTypes, value: this.getValue('thingDefinitions'), multipleSelection: true, disabled: this.isQueryField('thingDefinitions'), defaultValue: this.isQueryField('thingDefinitions') ? this.getValue('thingDefinitions') : null, placeholder: "All Thing Definitions" });
            }
            if (this.workSessionDefinitionsTree && this.workSessionDefinitionsTree.length) {
                this.workSessionDefinitionGroups = this.workSessionDefinitionsTree.map(el => { return { groupId: el.id, groupLabel: el.label, groupValues: el.children.map(child => { return { value: child.id, label: child.label } }) } });
            }
            if (this.workSessionDefinitionGroups && this.workSessionDefinitionGroups.length) {
                advancedSearchConfiguration.push({ name: 'workSessionDefinitionIds', label: 'workSessionDefinitionTypeProperty', type: 'STRING', selectionMode: 'MAT_SELECTION', values: [], value: this.getValue('workSessionDefinitions'), multipleSelection: true, enableMatSelectGroups: true, matSelectGroupValues: this.workSessionDefinitionGroups, disabled: this.isQueryField('workSessionDefinitions'), defaultValue: this.isQueryField('workSessionDefinitions') ? this.getValue('workSessionDefinitions') : null, placeholder: "All Work Session Types" });
            }
            this.fieldsPerRow = 3;
            this.advancedSearchConfiguration = advancedSearchConfiguration;
            setTimeout(() => this.updateWorkSessionDefinitionGroup('thingDefinitionIds'), 10);
        });
    }

    private getSearchData(): Promise<void> {
        if (!this.searchDataInitialized) {
            this.searchDataInitialized = true;
            let promises = [];
            promises.push(firstValueFrom(this.httpService.get<ThingDefinition[]>(THING_DEFINITIONS)).catch(() => []));
            promises.push(this.workSessionService.getWorkSessionDefinitionTypes(this.currentThing).catch(() => []));
            if (this.addCustomerSearchField) {
                if (this.authenticationService.getUser().hostCustomers?.length) {
                    const customerIds = this.authenticationService.getUser().hostCustomers.map(hc => { return hc.customerId });
                    promises.push(Promise.all(customerIds.map(id => { return this.customerService.getCustomerById(id) })).catch(() => []));
                } else {
                    let partnerParam = null;
                    if (this.contextService.getCurrentPartner()) {
                        partnerParam = new HttpParams().set('partnerId', this.contextService.getCurrentPartner().id);
                    }
                }
            }
            return Promise.all(promises).then(results => {
                this.thingDefinitionTypes = results[0].map((td: ThingDefinition) => { return { value: td.id, label: td.name } });
                this.workSessionDefinitionsTree = results[1];
            });
        }
        return Promise.resolve();
    }

    getEncodedQueryFields(): void {
        let fields = [];
        this.query.forEach(el => {
            if (el.property == 'group' || el.property == 'name' || this.advancedSearchAddibleProperties.some(p => p.name == el.property || "properties." + p.name == el.property)) {
                let propDef: CustomPropertyDefinition = null;
                if (el.property.startsWith('thingDefinition.properties.')) {
                    propDef = this.thingDefinitionProperties.find(prop => el.property == "thingDefinition.properties." + prop.name);
                }
                fields[el.property] = this.getQueryValueWithSuffixes(el.value, el.predicate, propDef);
            } else {
                fields[el.property] = el.value;
            }
        });
        this.encodedQueryFields = fields;
    }

    private getDateRange(): { range: DateRange<moment.Moment>, rangeName: string } {
        const rangeName = this.getValue('rangeName');
        if (rangeName) {
            return { range: null, rangeName: rangeName };
        } else {
            const start = this.getValue('startTimestamp');
            const end = this.getValue('endTimestamp');
            if (start && end) {
                return { range: new DateRange(moment(start), moment(end)), rangeName: null };
            }
        }
        return null;
    }

    updateWorkSessionDefinitionGroup(name: string): void {
        if (name == 'thingDefinitionIds' && this.advancedSearchEditor) {
            const rawValues = this.advancedSearchEditor.getObjectValue();
            const thingDefintionIds: string[] = _.get(rawValues, 'thingDefinitionIds') || [];
            const configurations = _.cloneDeep(this.advancedSearchConfiguration);
            const groupValues = this.workSessionDefinitionGroups.filter(group => thingDefintionIds.includes(group.groupId));
            if (configurations.find(el => el.name == 'workSessionDefinitionIds')) {
                if (thingDefintionIds && thingDefintionIds.length) {
                    if (groupValues && groupValues.length) {
                        configurations.find(el => el.name == 'workSessionDefinitionIds').matSelectGroupValues = groupValues;
                    } else {
                        configurations.splice(configurations.indexOf(el => el.name == 'workSessionDefinitionIds'), 1);
                    }
                } else {
                    configurations.find(el => el.name == 'workSessionDefinitionIds').matSelectGroupValues = this.workSessionDefinitionGroups;
                }
            } else {
                if (thingDefintionIds && thingDefintionIds.length) {
                    if (groupValues && groupValues.length) {
                        configurations.push({ name: 'workSessionDefinitionIds', label: 'workSessionDefinitionTypeProperty', type: 'STRING', selectionMode: 'MAT_SELECTION', values: [], multipleSelection: true, enableMatSelectGroups: true, matSelectGroupValues: groupValues });
                    }
                } else if (this.workSessionDefinitionGroups && this.workSessionDefinitionGroups.length) {
                    configurations.push({ name: 'workSessionDefinitionIds', label: 'workSessionDefinitionTypeProperty', type: 'STRING', selectionMode: 'MAT_SELECTION', values: [], multipleSelection: true, enableMatSelectGroups: true, matSelectGroupValues: this.workSessionDefinitionGroups });
                }
            }
            configurations.forEach(conf => {
                if (conf.name != 'workSessionDefinitionIds') {
                    conf.value = rawValues[conf.name];
                }
            });
            this.advancedSearchConfiguration = configurations;
        }
    }

    openAddMorePropertiesDialog(): void {
        if (this.useExternalAddPropertiesDialog) {
            this.emitOpenExternalAddPropertiesDialogAction(this.advancedSearchAddibleProperties);
        } else {
            this.addPropertiesDialog.open();
        }
    }

    private getWorkSessionProperties(): void {
        this.advancedSearchAddibleProperties = [];
        if (this.thingDefinitionProperties) {
            this.updateAdvancedSearchAddibleProperties(this.thingDefinitionProperties, 'thingDefinition.properties.', null);
        }
    }

    handleImport(): void {
        if (this.manualWorkSessions?.length) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.minWidth = '25%';
            dialogConfig.maxWidth = '528px';
            dialogConfig.autoFocus = false;
            dialogConfig.data = {
                workSessions: this.manualWorkSessions,
                thing: this.currentThing
            }
            firstValueFrom(this.dialog.open(WorkSessionAdvancedSearchImportDialogComponent, dialogConfig).afterClosed()).then(result => {
                if (result) {
                    this.import.emit();
                }
            })
        };
    }

    private initSimpleSearchActions(): void {
        this.simpleSearchActions = [
            {
                name: 'EDIT_TABLE_COLUMNS_EVENT',
                title: 'editColumnsProperty',
                visible: this.editTableColumnsEnabled
            }
        ];
    }

    performSimpleSearchAction(action: string): void {
        switch (action) {
            case "EDIT_TABLE_COLUMNS_EVENT":
                this.emitEditTableColumnsAction();
                break;
            default:
                break;
        }
    }
}