import { forwardRef, Inject, Injectable, Pipe } from '@angular/core';
import { Properties, PropertyInfo } from '../../common/properties';
import { AuthenticationService } from '../../service/authentication.service';
import { CustomPropertyService, CustomPropertyType } from '../../service/custom-property.service';
import { CompositePartComponent, MetricDetailComponent, PropertyComponent } from '../component';
import { CompositeTableColumn, CustomTableColumn, CustomTableService } from '../custom-table';
import { DefaultCompositePartPipe, DefaultContactsListPipe, DefaultSimpleValuePipe } from '../pipe';
import { ColumnComponentDisplayMode } from './list-widget-v2.components';

let nextId = 0;

@Injectable()
export abstract class AbstractListWidgetV2Service<T> {

    static COMPOSITE_SORT_PREFIX = 'COMPOSITE_SORT:';

    private defaultNullValue: string;

    columnFieldNames: string[] = [];
    columnFieldLabels: string[] = [];

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) protected authenticationService: AuthenticationService,
        @Inject(forwardRef(() => CustomPropertyService)) protected customPropertyService: CustomPropertyService,
    ) {
        this.defaultNullValue = this.authenticationService.getTenant().defaultNullValue;
    }

    protected abstract getPropertyColumn(col: PropertyComponent, columnName: string, defaultType: string): CustomTableColumn;

    protected abstract isColumnVisible(columnName: string): boolean;

    protected abstract getLabel(col: MetricDetailComponent | CompositePartComponent | PropertyComponent, defaultType: string): string;

    protected abstract getCustomPropertyNameAndType(columnName: string): { name: string, type: CustomPropertyType };

    getMetricNames(columnComponents: any): Set<string> {
        const metricNames = new Set<string>();
        columnComponents.forEach(col => {
            if (col instanceof MetricDetailComponent) {
                metricNames.add(col.name);
            } else if (col instanceof CompositePartComponent) {
                const metricComponents = col.metrics;
                metricComponents.forEach(m => metricNames.add(m.name));
            }
        });
        return metricNames;
    }

    setDefaultSort(displayedColumns: CustomTableColumn[]): string[] {
        let sort: string[] = [];
        for (let i = 0; i < displayedColumns.length; i++) {
            let column = displayedColumns[i];
            if (column instanceof CompositeTableColumn) {
                if (column.sortField) {
                    let sortingCriteria = column.sortField.substring(AbstractListWidgetV2Service.COMPOSITE_SORT_PREFIX.length).split('|');
                    let sortingCriteriaObjects = sortingCriteria.map(c => {
                        let parts = c.split(',');
                        return { field: parts[0], direction: parts[1].toLocaleLowerCase() };
                    });
                    sort = [column.sortField, sortingCriteriaObjects[0].direction];
                    break;
                }
            } else {
                if (column.sortField) {
                    sort = [column.sortField, 'asc'];
                    break;
                }
            }
        }
        return sort
    }

    assignCompositeSort(sort: string[]): string[] {
        let compositeSort = [];
        let sortingCriteria = sort[0].substring(AbstractListWidgetV2Service.COMPOSITE_SORT_PREFIX.length).split('|');
        let sortingCriteriaObjects = sortingCriteria.map(c => {
            let parts = c.split(',');
            return { field: parts[0], direction: parts[1].toLocaleLowerCase() };
        });
        let oppisiteDirection = sort[1] != sortingCriteriaObjects[0].direction;
        sortingCriteriaObjects.forEach((o, i) => {
            compositeSort[2 * i] = o.field;
            compositeSort[2 * i + 1] = oppisiteDirection ? this.getOppisiteDirection(o.direction) : o.direction;
        });
        return compositeSort;
    }

    private getOppisiteDirection(direction): string {
        if (direction == 'desc') {
            return 'asc';
        } else {
            return 'desc';
        }
    }

    getVisibleColumns(columns: (MetricDetailComponent | CompositePartComponent | PropertyComponent)[], defaultProperties: { [name: string]: PropertyInfo }, defaultType: string, localStorageId?: string): CustomTableColumn[] {
        if (!columns || !columns.length) {
            columns = this.getDefaultPropertyComponent(defaultProperties);
        }
        let compositeColumnIndex = 1;
        return this.getColumns(columns.filter(col => {
            if (col instanceof CompositePartComponent && !col.name) {
                col.name = "compositeColumn" + compositeColumnIndex++;
                col.label = col.label || " ";
            }
            return this.isColumnVisible(col.name);
        }), defaultType, localStorageId);
    }

    private getDefaultPropertyComponent(defaultProperties: { [name: string]: PropertyInfo }): PropertyComponent[] {
        const props: PropertyComponent[] = [];
        Object.keys(defaultProperties).forEach(columnName => {
            const columnProperties = defaultProperties[columnName];
            const conf = {
                name: columnName,
                label: columnProperties.label,
                filter: columnProperties.defaultFilter,
                sorting: columnProperties.defaultSorting
            };
            props.push(this.buildPropertyComponent(conf));
        });
        return props;
    }

    private getColumns(columnComponents: (MetricDetailComponent | CompositePartComponent | PropertyComponent)[], defaultType: string, localStorageId: string): CustomTableColumn[] {
        let columnNames = [];
        let results = [];
        this.columnFieldNames = [];
        this.columnFieldLabels = [];
        const localStorageVisibilityMap: { [columnName: string]: boolean } = localStorageId && localStorage.getItem(localStorageId) ? JSON.parse(localStorage.getItem(localStorageId)) : null;
        columnComponents.forEach(col => {
            col.label = this.getLabel(col, defaultType);

            // avoiding double column names
            let columnName = col.name;
            while (columnNames.indexOf(columnName) > -1) {
                columnName = columnName + "2";
                if (col instanceof CompositePartComponent) {
                    col.name = columnName;
                }
            }
            columnNames.push(columnName);
            col.setColumnName(columnName);
            if (col instanceof PropertyComponent && col.exportOnly && !this.columnFieldNames.includes(col.name)) {
                this.columnFieldNames.push(col.name);
                this.columnFieldLabels.push(col.label || col.name);
            } else {
                const isColumnVisible: boolean = localStorageVisibilityMap ? localStorageVisibilityMap[col.getColumnName()] : (col.displayMode == ColumnComponentDisplayMode.VISIBLE);
                if (isColumnVisible) {
                    results.push(this.getColumn(col, columnName, defaultType));
                } else if (col instanceof PropertyComponent && col.includeInExport && !this.columnFieldNames.includes(col.name)) {
                    this.columnFieldNames.push(col.name);
                    this.columnFieldLabels.push(col.label || col.name);
                }
            }
        });
        return results;
    }

    getColumnDescriptions(columns: (MetricDetailComponent | CompositePartComponent | PropertyComponent)[]): string[] {
        if (!columns) {
            return [];
        }
        return columns.map(c => {
            if (c instanceof PropertyComponent) {
                return this.getCustomPropertyDescription(c);
            } else {
                return c.description;
            }
        });
    }

    protected getFilter(col: MetricDetailComponent | CompositePartComponent | PropertyComponent, defaultType: string): string | Pipe {
        if (col.filter) {
            return col.filter;
        }
        if (col instanceof PropertyComponent) {
            if (col.name.indexOf('properties.') > -1) {
                const propNameType = this.getCustomPropertyNameAndType(col.name);
                if (this.customPropertyService.isContactPropertyByNameAndType(propNameType.type, propNameType.name)) {
                    return DefaultContactsListPipe;
                } else {
                    return Properties.getDefaultFilterByName(col.name, defaultType);
                }
            } else {
                return Properties.getDefaultFilterByName(col.name, defaultType);
            }
        }
        if (col instanceof MetricDetailComponent) {
            return DefaultSimpleValuePipe;
        }
        if (col instanceof CompositePartComponent) {
            return DefaultCompositePartPipe;
        }
        return null;
    }

    protected getArgument(col: MetricDetailComponent | CompositePartComponent | PropertyComponent): any {
        if (!col.filter || col instanceof MetricDetailComponent || col instanceof CompositePartComponent) {
            return null;
        }
        if (col instanceof PropertyComponent && col.name.indexOf('properties.') > -1) {
            const propNameType = this.getCustomPropertyNameAndType(col.name);
            return { property: this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(propNameType.type, propNameType.name) }
        }
        return null;
    }

    protected getDefaultValue(column: MetricDetailComponent | CompositePartComponent | PropertyComponent): string {
        return column.useDefaultNullValue ? this.defaultNullValue : null;
    }

    buildPropertyComponent(configuration: { name: string, label: string, filter: string | Function, sorting: string }): PropertyComponent {
        const p = new PropertyComponent();
        p.name = configuration.name;
        p.label = configuration.label;
        p.filter = configuration.filter;
        return p;
    }

    protected getColumn(col: MetricDetailComponent | CompositePartComponent | PropertyComponent, columnName: string, defaultType: string): CustomTableColumn {
        let tableColumn: CustomTableColumn;
        if (col instanceof PropertyComponent) {
            if (!this.columnFieldNames.includes(col.name)) { // avoiding duplicates
                this.columnFieldNames.push(col.name);
                this.columnFieldLabels.push(col.label || col.name);
            }
            tableColumn = this.getPropertyColumn(col, columnName, defaultType);
        } else if (col instanceof MetricDetailComponent) {
            tableColumn = CustomTableService.newMetricColumn(col, this.getLabel(col, defaultType), this.getFilter(col, defaultType)).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
        } else if (col instanceof CompositePartComponent) {
            let sortingCriteria = (col as CompositePartComponent).sortingCriteria;
            let sortField = sortingCriteria ? (AbstractListWidgetV2Service.COMPOSITE_SORT_PREFIX + sortingCriteria) : null;
            let properties = (col as CompositePartComponent).properties;
            if (properties && properties.length) {
                properties.forEach(p => {
                    if (!this.columnFieldNames.includes(p.name)) { // avoiding duplicates
                        this.columnFieldNames.push(p.name);
                        this.columnFieldLabels.push(p.label || p.name);
                    }
                });
            }
            tableColumn = CustomTableService.newCompositeColumn(col, this.getLabel(col, defaultType), this.getFilter(col, defaultType)).withSortField(sortField).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
        }
        tableColumn.showLabel = col.showLabel;
        return tableColumn.withDefaultValue(this.getDefaultValue(col));
    }

    protected getValueMap(col: PropertyComponent): { [obj: string]: string } {
        if (col.name.indexOf('properties.') > -1) {
            const propNameType = this.getCustomPropertyNameAndType(col.name);
            const def = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(propNameType.type, propNameType.name);
            if (def && def.values) {
                let valueMap = {};
                def.values.forEach(dict => valueMap[dict.value] = dict.label);
                return valueMap;
            }
        }
        return null;
    }

    protected getCustomPropertyDescription(col: PropertyComponent): string {
        if (col.description) {
            return col.description
        } else if (col.name.indexOf('properties.') > -1) {
            const propNameType = this.getCustomPropertyNameAndType(col.name);
            const def = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(propNameType.type, propNameType.name);
            return def?.description || '';
        }
        return '';
    }

    getColumnComponentsArray(columns: (MetricDetailComponent | CompositePartComponent | PropertyComponent)[], defaultProperties: { [name: string]: PropertyInfo }): (MetricDetailComponent | CompositePartComponent | PropertyComponent)[] {
        if (!columns || !columns.length) {
            return this.getDefaultPropertyComponent(defaultProperties);
        } else {
            return columns;
        }
    }
}