import { HttpParams } from "@angular/common/http";
import { Component, Inject, ViewChild, forwardRef } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { OrganizationAddDialogComponent } from "../../dashboard-area/organization/organization-add-dialog/organization-add-dialog.component";
import { Metric } from "../../model";
import { MetricService } from "../../service/metric.service";
import { ThingDefinitionService } from "../../service/thing-definition.service";
import { DataExportMetricSelectorTableComponent } from "./data-export-metric-selector-table.component";
import { DataExportDataElement } from "./data-export-widget-schedule-page.component";

@Component({
    selector: 'data-export-widget-add-thing-definition-dialog',
    template: require('./data-export-widget-add-thing-definition-dialog.component.html')
})
export class DataExportWidgetAddThingDefinitionDialog {

    @ViewChild(DataExportMetricSelectorTableComponent) metricSelector: DataExportMetricSelectorTableComponent;

    metrics: Metric[];
    isThingSelected: boolean;
    selectedThingDefinitionId: string;
    selectedMetrics: Metric[];
    loadingMetrics: boolean;
    resources: DataExportDialogResource[];
    resourceLoaded: boolean;

    private currentExportMetrics: Metric[];

    constructor(
        @Inject(forwardRef(() => MetricService)) private metricService: MetricService,
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<OrganizationAddDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        @Inject(forwardRef(() => ThingDefinitionService)) private thingDefinitionService: ThingDefinitionService
    ) {
        this.isThingSelected = !!data.currentThing;
        const selectedElement: DataExportDataElement = data.selectedElement;
        const excludedThingDefIds = data.excludedThingDefIds || [];
        if (this.isThingSelected) {
            this.resources = [{ name: data.currentThing.thingDefinition.name, thingDefinitionId: data.currentThing.thingDefinitionId }];
            this.resourceLoaded = true;
            this.selectedThingDefinitionId = data.currentThing.thingDefinitionId;
            this.currentExportMetrics = selectedElement?.exportMetrics;
            this.loadMetrics(this.selectedThingDefinitionId);
        } else {
            this.loadResources(data.params).then(resources => {
                this.resources = resources.filter(res => !excludedThingDefIds.includes(res.thingDefinitionId));
                this.resourceLoaded = true;
                if (selectedElement) {
                    this.selectedThingDefinitionId = selectedElement.thingDefinitionId;
                    this.currentExportMetrics = selectedElement.exportMetrics;
                    this.loadMetrics(this.selectedThingDefinitionId);
                }
            });
        }
    }

    thingDefinitionChanged(thingDefinitionId: string): void {
        this.metrics = [];
        this.currentExportMetrics = null;
        this.selectedMetrics = null;
        if (thingDefinitionId) {
            this.loadMetrics(thingDefinitionId);
        }
    }

    private loadMetrics(thingDefinitionId: string): void {
        this.loadingMetrics = true;
        this.metricService.getMetricsByThingDefinitionId(thingDefinitionId).then(metrics => {
            if (this.currentExportMetrics?.length) {
                this.selectedMetrics = metrics.filter(m => this.currentExportMetrics.some(em => em.id == m.id));
            }
            this.metrics = metrics;
            this.loadingMetrics = false;
        });
    }

    addThingDefinition(): void {
        const thingDefId = this.selectedThingDefinitionId;
        const metrics = this.metricSelector.getSelected();
        const resource: DataExportDialogResource = this.resources.find(r => r.thingDefinitionId == thingDefId);
        const body: DataExportDataElement = {
            thingDefinitionId: thingDefId,
            thingDefinitionName: resource.name,
            exportMetrics: metrics,
            metricNamesLabel: metrics.map(m => m.label || m.name).join(', ')
        }
        this.dialogRef.close(body);
    }

    isFormValid(): boolean {
        const metrics = this.metricSelector?.getSelected();
        return this.selectedThingDefinitionId && metrics?.length > 0;
    }

    private loadResources(params: HttpParams): Promise<DataExportDialogResource[]> {
        return this.loadThingDefinitions(params);
    }

    private loadThingDefinitions(params: HttpParams): Promise<DataExportDialogResource[]> {
        return this.thingDefinitionService.getThingDefinitionsAssociatedToThings(params).then(tds => {
            return tds.map(td => { return { name: td.name, thingDefinitionId: td.id } });
        }).catch(err => { return Promise.resolve([]) });
    }

} export class DataExportDialogResource {
    name: string;
    thingDefinitionId: string;
}